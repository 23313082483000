import styles from './RadioButton.module.scss';

export default function RadioButton({
  selected = false,
  containerProps: { className, ...containerProps } = {},
}: {
  selected?: boolean;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  return (
    <div className={[
      styles.container,
      className,
      selected ? styles.selected : "",
    ].join(" ")} {...containerProps}>
      <div className={styles.innerCircle} />
    </div>
  )
}