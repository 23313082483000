import Button from '@components/Button/Button';
import styles from './ButtonDownloadReceipt.module.scss';
import { IconDownload } from '@assets/icons/icons';
import { useState } from 'react';
import { Oval } from 'react-loader-spinner';

export default function ButtonDownloadReceipt({
  label,
  href,
  containerProps,
}: {
  label: string;
  href: string;
  containerProps?: Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>;
}) {

  const [loading, setLoading] = useState(false);

  const handleDownload = () => {
    setLoading(true);
    const link = document.createElement('a');
    const baseURL = window.env.PUBLIC_API_URL;
    const url = href.replace("/api/v1", "");

    link.href = `${baseURL}${url}`;
    link.target = "_blank";
    link.click();
    setLoading(false);
  }

  return (
    <Button
      type="secondary"
      onClick={handleDownload}
      containerProps={{
        ...containerProps,
        className: [styles.container, containerProps?.className].join(" "),
      }}
    >
      {loading ? (
        <Oval
          height={20}
          width={20}
        />
      ) : <IconDownload />} <div>{label}</div>
    </Button>
  )
}