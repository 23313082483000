import styles from './PageContainer.module.scss';

export default function PageContainer({
  children,
  containerProps: { className, children: _containerPropsChildren, ...containerProps } = {},
}: {
  children: React.ReactNode;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>
      {children}
      {_containerPropsChildren}
    </div>
  )
}