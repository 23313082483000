import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import styles from './ItineraryRecapPage.module.scss';
import layoutStyles from '../../CurrentMission.module.scss';
import Paper from '@components/Layout/Paper';
import {Person, IconPaymentCB, IconCheckNotValid} from '@assets/icons/icons';
import { format } from 'date-fns';
import formatDuration from 'src/helpers/duration';
import Button from '@components/Button/Button';
import Tag from '@components/Tag/Tag';
import { Leaf } from '@icons/icons';
import { Oval } from 'react-loader-spinner';
import { paymentStatusLabels, statusLabels } from 'src/helpers/statusLabels';
import CurrentMissionVerticalSteps from '../../CurrentMissionVerticalSteps';
import VRentalSection from './VRentalSection/VRentalSection';
import useRecap from './useRecap';
import { useNavigate } from 'react-router-dom';
import useMissionContextRefreshInterval from 'src/Hooks/useMissionContextRefreshInterval';
import StringRichText from "@components/StringRichText/StringRichText.tsx";
import { useState } from 'react';
import ModalGoodWill from './Components/ModalGoodWill/ModalGoodWill';
import { isAppEnvProductionLike } from 'src/appEnv';
import RecapItinerary from './Components/RecapItinerary/RecapItinerary';
import { useTranslation } from 'react-i18next';
import HeaderV2 from 'src/layout/Header/V2/HeaderV2';
import { useCurrentMission } from 'src/Stores/CurrentMission';

export default function ItineraryRecapPage({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const { t } = useTranslation();

  const [displayModalGoodWill, setDisplayModalGoodWill] = useState(false);
  const navigate = useNavigate();
  const recapController = useRecap();

  const paymentPendingOrProcessing =
    recapController?.missionContext.steps?.recap?.paymentStatus?.id === 'pending' ||
    recapController?.missionContext.steps?.recap?.paymentStatus?.id === 'processing';

  useMissionContextRefreshInterval({
    refreshInterval: 2000,
    enabled: paymentPendingOrProcessing,
  });

  const { isCurrentStepReadOnly } = useCurrentMission();

  if (!recapController) {
    return null;
  }

  const {
    missionContext,
    comments,
    setComments,
    loading,
    stepAction,
    callApiAction,
    passengers,
    recapDate,
    isTaxiOnly,
    passengerCount,
    validUntilDate,
    optionIsToday,
    currentStep,
    recap,
  } = recapController;

  const sectionsWithoutArrival = recap.sections.filter((section) => section.type !== 'arrival');
  const arrivalSections = recap.sections.filter((section) => section.type === 'arrival');

  return (
    <PageContainer
      containerProps={{
        ...containerProps,
        className: [layoutStyles.container, styles.container, className].join(' '),
      }}
    >
      {displayModalGoodWill && (
        <ModalGoodWill
          onRequestClose={() => setDisplayModalGoodWill(false)}
          recap={recap}
        />
      )}

      <HeaderV2 />

      {loading && (
        <div className={styles.loadingFullScreen}>
          <Oval
            height={40}
            width={40}
            color="white"
            secondaryColor="white"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      <div
        data-testid="step-recap"
        className={[layoutStyles.content, styles.content].join(' ')}
      >
        <CurrentMissionVerticalSteps displayBackButton />

        <div className={styles.mainContentContainer}>
          <div className={styles.sectionHeader}>
            <div className={styles.recapTitleContainer}>
              <div className={styles.recapTitle}>
                {missionContext.steps?.recap?.status?.title || t('your_itinerary')}
              </div>
              {!missionContext.steps?.recap?.status?.isValid ? (
                <IconCheckNotValid height={30} width={30} />
              ) : (
                false
              )}
              {missionContext.steps?.recap?.status?.isValid === false && (
                <Tag
                  isLarge
                  label={missionContext.steps.recap?.status?.label}
                  color={statusLabels[missionContext.steps.recap.status.id].color}
                  containerProps={{ className: styles.tagPaymentStatus }}
                />
              )}
              {missionContext.steps?.recap?.paymentStatus && (
                <Tag
                  isLarge
                  label={missionContext.steps.recap.paymentStatus.label}
                  color={paymentStatusLabels[missionContext.steps.recap.paymentStatus.id].color}
                  containerProps={{ className: styles.tagPaymentStatus }}
                />
              )}
            </div>
            {missionContext.steps?.recap?.message && (
              <StringRichText containerProps={{ className: styles.infosEmail }}>
                {missionContext.steps.recap.message}
              </StringRichText>
            )}
          </div>

          <div className={styles.mainContentContainer}>
            <Paper className={styles.mainContent}>
              <div className={styles.recapDateTime}>
                <div>
                  <div className={styles.recapDate}>
                    {format(recapDate, 'PPPP')}
                  </div>
                  <div className={styles.recapTime}>
                    {t('duration_description')} :{" "}
                    {formatDuration({
                      unit: 'minutes',
                      value: recap.durationMinutes,
                    })}
                  </div>
                </div>
                {!isTaxiOnly && recap.validUntil && (
                  <div className={styles.validUntil}>
                    {optionIsToday ? (
                      <p className={styles.validUntil}>
                        {t('option_valid_until_hour')}{" "}
                        {validUntilDate.toLocaleTimeString(navigator.language, {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </p>
                    ) : (
                      <p className={styles.validUntil}>
                        {t('option_valid_until_day')}{" "}
                        {validUntilDate.toLocaleDateString(navigator.language, {
                          day: '2-digit',
                          month: 'short',
                        })}{" "}
                        {validUntilDate.toLocaleTimeString(navigator.language, {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </p>
                    )}
                  </div>
                )}
              </div>
              {passengers && (
                <div className={styles.passengersList}>
                  {isTaxiOnly && passengerCount && passengerCount > 1 ? (
                    <div className={styles.passengersCar}>
                      <Person /> x {passengerCount}
                    </div>
                  ) : (
                    <ul>
                      {passengers.map((passenger) => (
                        <li key={passenger.id}>
                          <Person />
                          {passenger.fields?.flat().find((fieldGroup) => fieldGroup.name === 'firstName')?.value}{' '}
                          {passenger.fields?.flat().find((fieldGroup) => fieldGroup.name === 'lastName')?.value}
                        </li>
                      ))}
                    </ul>
                  )}
                  <div className={styles.mainPassengerInfos}>
                    <p>
                      {passengers[0].fields?.flat().find((fieldGroup) => fieldGroup.name === 'email')?.value}
                    </p>
                    <p>
                      +{passengers[0].fields?.flat().find((fieldGroup) => fieldGroup.name === 'phone')?.value.split("+")[1]}
                    </p>
                  </div>
                </div>
              )}

              {sectionsWithoutArrival.length > 0 && (
                <RecapItinerary
                  isReadOnly={isCurrentStepReadOnly()}
                  sections={sectionsWithoutArrival}
                  trains={recap.trains}
                />
              )}

              {recap.vrentalsBySectionID &&
                Object.keys(recap.vrentalsBySectionID).map((sectionID) => (
                  <VRentalSection
                    key={sectionID}
                    rental={recap.vrentalsBySectionID[sectionID]}
                    containerProps={{
                      className: styles.vRentalSection,
                    }}
                    alert={recap.alerts && recap.alerts.length > 0 ? recap.alerts[0] : undefined}
                  />
                ))
              }

              {arrivalSections.length > 0 && (
                <RecapItinerary
                    sections={arrivalSections}
                />
              )}

              {recap.commentForms && (
                <div className={styles.sectionComments}>
                  {recap.commentForms.map((comment) => (
                    <div className={styles.comment} key={comment.id}>
                      <p>{comment.label}</p>
                      {comment.readOnly || isCurrentStepReadOnly() ? (
                        <div className={styles.commentReadOnly}>
                          {comment.value}
                        </div>
                      ) : (
                        <textarea
                          placeholder={''}
                          className={[
                            styles.commentInput,
                            comment.readOnly ? styles.readOnly : '',
                          ].join(' ')}
                          value={comments[`${comment.id}`] || comment.value || ''}
                          onChange={(e) => {
                            if (comment.readOnly) {
                              return;
                            }
                            if (comment.maxLength && e.target.value.length > comment.maxLength) {
                              return;
                            }
                            setComments({
                              ...comments,
                              [`${comment.id}`]: e.target.value,
                            });
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}

              <div className={styles.recapCostsContainer}>
                {recap.initialEnvelopeBalance && (
                  <div className={styles.recapCosts}>
                    <span>{t('envelope_balance')}</span>
                    <span>
                      {recap.initialEnvelopeBalance.formatted}{' '}
                      {recap.initialEnvelopeBalance.currency.symbol}
                    </span>
                  </div>
                )}

                <div className={styles.recapCosts}>
                  <span>{t('total_cost')}</span>
                  {recap.totalPrice === null ? "0,00" : recap.totalPrice?.formatted}{' '}
                  {recap.totalPrice?.currency.symbol}
                </div>

                {recap.coveredPrice && (
                  <div className={styles.recapCosts}>
                    <span>{t('covered_cost')}</span>
                    {recap.coveredPrice.formatted}{' '}
                    {recap.coveredPrice.currency.symbol}
                  </div>
                )}

                {recap.currentEnvelopeBalance && (
                  <div className={styles.recapCosts}>
                    <span>{t('remaining_envelope')}</span>
                    <span>
                      {recap.currentEnvelopeBalance.formatted}{' '}
                      {recap.currentEnvelopeBalance.currency.symbol}
                    </span>
                  </div>
                )}

                {recap.initialRemainingCosts && (
                  <div className={styles.recapCosts}>
                    <span>{t('remaining_cost')}</span>
                    {(() => {
                      return (
                        <>
                          {recap.initialRemainingCosts === null
                            ? "0,00"
                            : recap.initialRemainingCosts?.formatted}{' '}
                          {recap.totalPrice?.currency.symbol}
                        </>
                      );
                    })()}
                  </div>
                )}

                {recap.goodwill && (
                  <>
                    <div className={styles.recapCosts}>
                      <span>
                        {(typeof recap.selectedGoodwillType === 'object'
                          ? recap.selectedGoodwillType?.label
                          : undefined) ||
                          recap.goodwillTypes?.find(
                            (gw) => gw.id === recap.selectedGoodwillType
                          )?.label ||
                          t('commercial_gesture_amount')}
                      </span>
                      {recap.goodwill.formatted}{' '}
                      {recap.goodwill.currency.symbol}
                    </div>
                  </>
                )}

                {recap.remainingCosts && (
                  <div className={styles.recapRemainingCosts}>
                    <span>
                      {recap.initialRemainingCosts
                        ? t('new_remaining_cost')
                        : t('remaining_cost')}
                    </span>
                    {recap.remainingCosts === null
                      ? "0,00"
                      : recap.remainingCosts?.formatted}{' '}
                    {recap.totalPrice?.currency.symbol}
                  </div>
                )}

                <div className={styles.recapImpactCO2}>
                  <span>{t('co2_impact')}</span>
                  <div className={styles.recapImpactCO2Value}>
                    <Leaf />
                    <p>{recap.kgCO2e} kgCO2e</p>
                  </div>
                </div>
              </div>

              {!isCurrentStepReadOnly() && (
              <div className={styles.recapAction}>
                {missionContext?.steps.recap?.actions.confirmAction && (
                  <Button
                    testid="button-confirm-recap"
                    label={t('confirm_mission')}
                    type="primary"
                    containerProps={{
                      className: 'w-full mb-4',
                      disabled: loading,
                    }}
                    onClick={() => {
                      stepAction(currentStep, 'confirm');
                    }}
                  />
                )}

                {missionContext.steps?.recap?.actions?.sendPaymentLinkAction && (
                  <Button
                    type="primary"
                    testid='button-send-payment-link'
                    containerProps={{
                      className: 'w-full mb-4',
                      disabled: loading || isAppEnvProductionLike(),
                    }}
                    onClick={() => {
                      stepAction(currentStep, 'sendPaymentLinkAction');
                    }}
                  >
                    <IconPaymentCB />{' '}
                    {missionContext.steps?.recap?.paymentStatus
                      ? t('resend_payment_link')
                      : t('send_payment_link')}
                  </Button>
                )}

                {(missionContext.steps?.recap?.paymentStatus || missionContext.steps?.recap?.status?.isValid === false) && (
                  <Button
                    label={t('return_home')}
                    type="secondary"
                    containerProps={{
                      className: 'w-full mb-4',
                      disabled: loading,
                    }}
                    onClick={() => {
                      navigate('/');
                    }}
                  />
                )}

                {missionContext?.steps.recap?.actions?.startNewSearchAction && (
                  <Button
                    label={t('new_search')}
                    type="primary"
                    containerProps={{
                      className: 'w-full mb-4',
                      disabled: loading,
                    }}
                    onClick={() => {
                      if (!missionContext?.steps.recap?.actions?.startNewSearchAction) {
                        return;
                      }
                      callApiAction(missionContext.steps.recap?.actions?.startNewSearchAction);
                    }}
                  />
                )}

                {missionContext?.steps.recap?.actions?.alertCockpitAction && (
                  <Button
                    label={t('error_report')}
                    type="secondary"
                    containerProps={{
                      className: 'w-full mb-4',
                      disabled: loading,
                    }}
                    onClick={() => {
                      if (!missionContext?.steps.recap?.actions?.alertCockpitAction) {
                        return;
                      }
                      callApiAction(missionContext.steps.recap.actions?.alertCockpitAction);
                    }}
                  />
                )}

                <div className="flex flex-row gap-4 w-full">
                  {missionContext?.steps.recap?.actions?.cancelAction && (
                    <Button
                      label={t('delete_service')}
                      type="secondary"
                      containerProps={{
                        className: 'flex-1',
                        disabled: loading,
                      }}
                      onClick={() => {
                        stepAction(currentStep, 'cancel');
                      }}
                    />
                  )}

                  {missionContext?.steps.recap?.actions?.setGoodwillAction && (
                    <Button
                      label={t('commercial_gesture')}
                      type="secondary"
                      containerProps={{
                        className: 'flex-1',
                        disabled: loading,
                      }}
                      onClick={() => {
                        setDisplayModalGoodWill(true);
                      }}
                    />
                  )}
                </div>
              </div>
              )}
            </Paper>
          </div>
        </div>

        <div className={styles.rightPlaceholder} />
      </div>
    </PageContainer>
  );
}
