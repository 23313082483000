import { useMemo } from 'react';
import styles from './FooterLinks.module.scss';

export default function FooterLinks({
  links,
  containerProps,
}: {
  links?: {
    label: string;
    url: string;
  }[];
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const defaultLinks = useMemo(() => [
    {
      label: "Politique de confidentialité",
      url: "https://www.allianz.fr/"
    },
    {
      label: "Condition d’utilisation",
      url: "https://www.allianz.fr/"
    },
    {
      label: "Nous contacter",
      url: "https://www.allianz.fr/"
    }
  ], []);

  return (
    <div {...containerProps} className={[styles.container, containerProps?.className || ""].join(" ")}>
      {(links || defaultLinks).map((link, index) => (
        <a
          key={index}
          href={link.url}
          target="_blank"
          rel="noreferrer"
        >
          {link.label}
        </a>
      ))}
    </div>
  )
}