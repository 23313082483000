import styles from './InputContainer.module.scss';

export default function InputContainer({
  label,
  testid,
  errorMsg,
  disabled,
  children,
  containerProps: { className, ...containerProps } = {},
}: {
  label?: React.ReactNode;
  testid?: string;
  errorMsg?: string;
  disabled?: boolean;
  children: React.ReactNode;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  return (
    <div data-testid={testid} className={[styles.container, disabled ? styles.disabled : "", className].join(" ")} {...containerProps}>
      {(label || errorMsg) && (
        <div className={styles.labelContainer}>
          {label && <div className={styles.label}>{label}</div>}
          {errorMsg && <div data-testid="errorMsg" className={styles.errorMsg}>{errorMsg}</div>}
        </div>
      )}
      {children}

    </div>
  )
}