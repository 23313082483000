import { MapMarker, MapMarkerInactiv } from '@assets/icons/icons';
import CustomMarker, { MarkerProps } from '../CustomMarker';
import styles from './SolutionPointMarker.module.scss';
import InfoLayer from '../InfoLayer/InfoLayer';
import { SolutionPoint } from 'src/Pages/CurrentMission/Pages/ItinerarySolutions/ItineraryMap/utils';

export default function SolutionPointMarker({
  isActiv,
  solutionPoint,
  onClick,
  containerProps: { className, ...containerProps } = {},
  ...markerProps
}: {
  isActiv?: boolean;
  onClick?: () => void;
  solutionPoint: SolutionPoint;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
} & MarkerProps) {

  const infoLayerContent = (
    <div>
      {Object.keys(solutionPoint.countMainTransportations).map((countMainTransportationsKey, index) => (
        <div key={index} className={styles.infoLayerContentLine}>
          {solutionPoint.countMainTransportations[countMainTransportationsKey].count} {solutionPoint.countMainTransportations[countMainTransportationsKey].mainTransportationLabel}
        </div>
      )
      )}
    </div>
  )

  return (
    <CustomMarker
      {...markerProps}
    >
      <div className={[styles.container, isActiv ? styles.isActiv : "", className].join(" ")} {...containerProps} onClick={() => {
        onClick && onClick();
      }}>
        {isActiv && (
          <InfoLayer
            title={solutionPoint.infoLayer.label}
            containerProps={{
              className: styles.infoLayer
            }}
          >{infoLayerContent}</InfoLayer>
        )}
        <div className={[styles.label, isActiv ? styles.labelActiv : ""].join(" ")}>{solutionPoint.label}</div>
        {isActiv ? <MapMarker fill="red" stroke='red'/> : <MapMarkerInactiv stroke='red' />}
      </div>
    </CustomMarker>
  )
}