import React from 'react';
import styles from './PositionMarker.module.scss';

export default function PositionMarker({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>
      <div className={styles.circleInside} />
    </div>
  )
}