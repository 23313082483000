import { MapUnderWrapper } from '@components/GoogleMap/Map';
import styles from './VRentalMap.module.scss';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { RentalSolutionPoint, getPointsFromRentals } from './utils';
import { getBoundsFromPoints, placeTypeToLatLngLiteral } from '@components/GoogleMap/utils';
import OriginPointMarker from '@components/GoogleMap/OriginPointMarker/OriginPointMarker';
import { useEffect, useMemo, useState } from 'react';
import VRentalPointMarker from '@components/GoogleMap/VRentalPointMarker/VRentalPointMarker';
import { RentalType } from 'src/Stores/CurrentMissionTypes/MissionContextType';

export default function VRentalMap({
  rentals,
  containerProps: { className, ...containerProps } = {},
  onPointSelected,
}: {
  rentals: RentalType[];
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  onPointSelected?: (point: RentalSolutionPoint | null) => void;
}) {

  const { missionContext, mission } = useCurrentMission();
  const { selectedAgencyID } = missionContext?.steps?.searchVrental || {};
  const [pointAgencyIdSelected, setPointAgencyIdSelected] = useState<string | null | undefined>(selectedAgencyID);

  const origin = mission?.searchData?.origin;
  // const rentals = missionContext?.steps?.searchVrental?.rentals;
  const agencies = missionContext?.steps?.searchVrental?.agencies;

  const solutionsPoints = useMemo(() => {
    return getPointsFromRentals(rentals || [], agencies || []);
  }, [rentals, agencies]);

  const bounds = useMemo(() => {
    if (!origin) {
      return null;
    }
    return getBoundsFromPoints([
      ...solutionsPoints, placeTypeToLatLngLiteral(origin)
    ]);
  }, [solutionsPoints, origin]);

  useEffect(() => {
    if (!onPointSelected) {
      return;
    }
    const selectedPoint = !pointAgencyIdSelected ? null : (solutionsPoints.find((point) => point.agencyId === pointAgencyIdSelected) || null);
    onPointSelected(selectedPoint);
  }, [pointAgencyIdSelected, solutionsPoints, onPointSelected]);

  if (!mission?.searchData?.origin) {
    return null;
  }
  if (!missionContext || !missionContext.steps?.searchVrental) {
    return null;
  }

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>

      <div className={styles.map}>

        <MapUnderWrapper
          style={{ height: "100%", width: "100%", borderRadius: "10px" }}
          bounds={bounds}
        >
          {origin && (
          <OriginPointMarker
            position={{
              lat: origin.latitude,
              lng: origin.longitude
            }}
          />
          )}
          {solutionsPoints.map((solutionPoint) => (
            <VRentalPointMarker
              key={solutionPoint.agencyId}
              isActiv={solutionPoint.agencyId === pointAgencyIdSelected}
              onClick={() => {
                setPointAgencyIdSelected(solutionPoint.agencyId === pointAgencyIdSelected ? null : solutionPoint.agencyId);
              }}
              infoLayer={solutionPoint.infoLayer}
              countNbSolutionInRental={solutionPoint.nbOccurences}
              position={{ lat: solutionPoint.lat, lng: solutionPoint.lng }}
            />
          ))}
        </MapUnderWrapper>

      </div>

    </div>
  )
}