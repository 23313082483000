import { AllianzLogo } from '@assets/icons/icons';
import styles from './Header.module.scss';
import HeaderMission from './HeaderMission';
import CurrentHour from './CurrentHour';
import { useAuthStore } from 'src/Stores/AuthStore';
import { useRef, useState } from 'react';

export default function Header({
  type = 'user',
  fixed = true,
  fixedWithPlaceholder = true,
  containerProps: { className, ...containerProps } = {},
}: {
  type?: 'logged-out' | 'user' | 'mission' | 'mission-search';
  fixed?: boolean;
  fixedWithPlaceholder?: boolean;
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {

  const { logout } = useAuthStore();

  const timeOutRef = useRef<any>(null);
  const [clickCount, setClickCount] = useState(0);
  const onClickToTriggerError = () => {
    setClickCount(clickCount => clickCount + 1);
    if (clickCount > 5) {
      // eslint-disable-next-line
      // @ts-ignore
      callFunctionThatDoesNotExist();
    }
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
    }
    timeOutRef.current = setTimeout(() => {
      setClickCount(0);
    }, 1000);
  }
  const onClickLogout = () => {
    logout();
  }

  return (
    <>
      {fixedWithPlaceholder && (
        <div className={[styles.container, styles.placeholder].join(" ")} />
      )}
      <div className={[styles.container, fixed || fixedWithPlaceholder ? styles.containerFixed : "", className].join(" ")} {...containerProps}>
        <div className={styles.contentAbsoluteContainer} onClick={onClickToTriggerError}>
          {type === 'logged-out' && (
            <div className={styles.contentAbsoluteContentContainer}>
              <AllianzLogo height={30} />
            </div>
          )}

          {type === 'user' && (
            <div className={styles.contentAbsoluteContentContainer}>
              <CurrentHour />
              <div className={styles.findSolutionText}>
                Trouvons une solution de mobilité !
              </div>
              <a
                data-testid="logout-link"
                className={styles.logoutLink} onClick={onClickLogout}>
                Se déconnecter
              </a>
            </div>
          )}

          {type === 'mission' && (
            <HeaderMission />
          )}

          {type === 'mission-search' && (
            <HeaderMission
              view='search'
            />
          )}

        </div>
      </div>
    </>
  )
}