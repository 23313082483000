import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import styles from '../ItineraryRecapPage.module.scss';
import layoutStyles from '../../../../CurrentMission.selfcare.module.scss';
import { Oval } from 'react-loader-spinner'
import { useEffect, useState } from 'react';
import useRecap from '../../useRecap';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import { EmbeddedCheckoutProvider,EmbeddedCheckout } from '@stripe/react-stripe-js';
import {Stripe, loadStripe} from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';


export default function PaymentSelfCarePage({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { t } = useTranslation();
  const recapController = useRecap();
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    if (stripe) {
      return;
    }

    if (!recapController?.recap.stripePublicKey || !recapController?.recap.stripeClientSecret) {
      return;
    }
    loadStripe("pk_test_bcX3yUH2icEh89hwpkFLp5Xq").then(setStripe);
  }, [stripe, recapController?.recap.stripePublicKey, recapController?.recap.stripeClientSecret]);

  if (!recapController || !stripe) {
    return null;
  }

  const {
    loading,
    recap,

  } = recapController;


  return (
    <PageContainer
      containerProps={{
        ...containerProps,
        className: [layoutStyles.container, styles.container, className].join(" "),
      }}
    >

      {loading && (
        <div className={styles.loadingFullScreen}>
          <Oval
            height={40}
            width={40}
            color="white"
            secondaryColor="white"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      <HeaderSelfCare
        title={t('payment_page_title')}
        displayBackButton={true}
        displayMissionProgressBar
      />

      <div data-testid="step-recap" className={[styles.content].join(" ")}>

        <EmbeddedCheckoutProvider
          stripe={stripe}
          options={{
            clientSecret: recap.stripeClientSecret,
          }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>

      </div>

    </PageContainer>
  )
}