import { Link, useNavigate } from 'react-router-dom';
import { useCrudEntities } from '../ListEntitiesStore';
import styles from './ListEntities.module.scss';
import { Entity } from '../types';
import Button from '@components/Button/Button';
import { useTranslation } from 'react-i18next';

export default function ListEntities() {
  const { entities } = useCrudEntities();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>{t('admin')}</th>
          </tr>
        </thead>
        <tbody>
          {entities.map((entity: Entity) => (
            <tr key={entity.id}>
              <td>
                <Link to={`./${entity.id}`}>{entity.label}</Link>
              </td>
            </tr>
          ))}
        </tbody>

      </table>

      <div>
          <Button
            label={t('return_home')}
            onClick={() => {
              navigate("/");
            }}
            type='secondary'
            containerProps={{
              className: 'mt-4',
            }}
          />
        </div>
    </div>
  )
}