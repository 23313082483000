import { useTranslation } from 'react-i18next';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import styles from './ItineraryConfirmationPage.module.scss';
import layoutStyles from '../../CurrentMission.module.scss';
import Paper from '@components/Layout/Paper';
import { CheckValid, Person, IconCheckNotValid } from '@assets/icons/icons';
import { format } from 'date-fns';
import formatDuration from 'src/helpers/duration';
import Button from '@components/Button/Button';
import Tag from '@components/Tag/Tag';
import { Leaf } from '@icons/icons';
import { Oval } from 'react-loader-spinner';
import { statusLabels } from 'src/helpers/statusLabels';
import CurrentMissionVerticalSteps from '../../CurrentMissionVerticalSteps';
import VRentalSection from "./VRentalSection/VRentalSection.tsx";
import useConfirmationPageController from './useConfirmationPageController.ts';
import StringRichText from '@components/StringRichText/StringRichText.tsx';
import RecapItinerary from './Components/RecapItinerary/RecapItinerary.tsx';
import ResendConfirmationEmailButton from './Components/ResendConfirmationEmail/ResendConfirmationEmailButton.tsx';
import HeaderV2 from 'src/layout/Header/V2/HeaderV2.tsx';

export default function ItineraryConfirmationPage({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { t } = useTranslation();

  const confirmationPageController = useConfirmationPageController();
  if (!confirmationPageController) {
    return null;
  }

  const {
    navigate,
    missionContext,
    currentStep,
    passengers,
    confirmationStep,
    recapDate,
    isTaxiOnly,
    passengerCount,
    validUntilDate,
    optionIsToday,
    stepAction,
    callApiAction,
    loading,
  } = confirmationPageController;

  const sectionsWithoutArrival = confirmationStep.sections.filter(section => section.type !== 'arrival');
  const arrivalSections = confirmationStep.sections.filter(section => section.type === 'arrival');

  return (
    <PageContainer
      containerProps={{
        ...containerProps,
        className: [layoutStyles.container, styles.container, className].join(" "),
      }}
    >

      <HeaderV2 />

      {loading && (
        <div className={styles.loadingFullScreen}>
          <Oval
            height={40}
            width={40}
            color="white"
            secondaryColor="white"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      <div data-testid="step-confirmation" className={[layoutStyles.content, styles.content].join(" ")}>

        <CurrentMissionVerticalSteps displayBackButton />

        <div className={styles.mainContentContainer}>
          <div className={styles.sectionHeader}>

            <div className={styles.confirmTitleContainer}>
              <div className={styles.confirmTitle}>{confirmationStep?.status?.title || t('reservation_confirmed')}</div>
              {confirmationStep?.status?.isValid === true || confirmationStep?.status?.isValid === undefined ?
                <CheckValid height={30} width={30} /> :
                <IconCheckNotValid height={30} width={30} />
              }

              {missionContext.steps?.confirmation?.status?.id && statusLabels[missionContext.steps.confirmation.status.id] && (
                <Tag
                  label={missionContext.steps.confirmation.status.label}
                  color={statusLabels[missionContext.steps.confirmation.status.id].color}
                  containerProps={{ className: "ml-8" }}
                  isLarge
                />
              )}

            </div>
            {missionContext.steps?.confirmation?.message && (
              <StringRichText containerProps={{ className: styles.infosEmail }}>
                {missionContext.steps.confirmation.message}
              </StringRichText>
            )}
          </div>

          <div className={styles.mainContentContainer}>
            <Paper className={styles.mainContent}>
              <div className={styles.recapDateTime}>
                <div>
                  <div className={styles.recapDate}>
                    {format(recapDate, "PPPP")}
                  </div>
                  <div className={styles.recapTime}>
                    {t('duration_description')} : {formatDuration({
                      unit: 'minutes',
                      value: confirmationStep.durationMinutes,
                    })}
                  </div>
                </div>
                {!isTaxiOnly && confirmationStep.validUntil &&
                  <p className={styles.validUntil}>
                    {optionIsToday ? (
                      <p className={styles.validUntil}>
                        {t('option_valid_until_hour')}{" "}
                        {validUntilDate.toLocaleTimeString(navigator.language, {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </p>
                    ) : (
                      <p className={styles.validUntil}>
                        {t('option_valid_until_day')}{" "}
                        {validUntilDate.toLocaleDateString(navigator.language, {
                          day: "2-digit",
                          month: "short",
                        })}{" "}
                        {validUntilDate.toLocaleTimeString(navigator.language, {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </p>
                    )}
                  </p>
                }
              </div>
              {
                passengers && (
                  <div className={styles.passengersList}>
                    {isTaxiOnly && passengerCount && passengerCount > 1 ? (
                      <div className={styles.passengersCar}> <Person /> x {passengerCount}</div>
                    ) : (
                      <ul>
                        {passengers.map((passenger) => (
                          <li key={passenger.id}>
                            <Person />
                            {passenger.fields?.flat().find((fieldGroup) => fieldGroup.name === 'firstName')?.value}{' '}
                            {passenger.fields?.flat().find((fieldGroup) => fieldGroup.name === 'lastName')?.value}
                          </li>
                        ))}
                      </ul>
                    )}
                    <div className={styles.mainPassengerInfos}>
                      <p> {passengers[0].fields?.flat().find((fieldGroup) => fieldGroup.name === 'email')?.value}</p>
                      <p>+{passengers[0].fields?.flat().find((fieldGroup) => fieldGroup.name === 'phone')?.value.split("+")[1]}</p>
                    </div>
                  </div>
                )
              }

              {sectionsWithoutArrival.length > 0 && (
                <RecapItinerary sections={sectionsWithoutArrival} trains={confirmationStep.trains} />
              )}

              {confirmationStep.vrentalsBySectionID && Object.keys(confirmationStep.vrentalsBySectionID).map((sectionID) => (
                <VRentalSection
                  key={sectionID}
                  rental={confirmationStep.vrentalsBySectionID[sectionID]}
                  containerProps={{
                    className: styles.vRentalSection,
                  }}
                />
              ))}

              {arrivalSections.length > 0 && (
                <RecapItinerary sections={arrivalSections} />
              )}

              {(() => {

                let trainReference = null;
                (confirmationStep.sections || []).forEach(section => {
                  if (section.type === "train" && section.trainID && confirmationStep.trains && confirmationStep.trains[section.trainID]) {
                    trainReference = confirmationStep.trains[section.trainID].reference;
                  }
                });

                return trainReference ? (
                  <div className={styles.trainBookingReferenceContainer}>
                    <b>{t('train_booking_reference')} :</b> {trainReference}
                  </div>
                ) : null;
              })()}

              {confirmationStep.comments && confirmationStep.comments.length > 0 &&
                confirmationStep.comments.some((comment) => comment.value) && (
                <div className={styles.sectionComments}>
                  {confirmationStep.comments.map((comment) => (
                    <div className={styles.comment} key={comment.label}>
                      <p>{comment.label}</p>
                      <div className={styles.commentReadOnly}>
                        {comment.value}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div className={styles.recapCostsContainer}>

                {confirmationStep.initialEnvelopeBalance && (
                  <div className={styles.recapCosts}>
                    <span>{t('envelope_balance')}</span>
                    <span>{confirmationStep.initialEnvelopeBalance.formatted} {confirmationStep.initialEnvelopeBalance.currency.symbol}</span>
                  </div>
                )}

                <div className={styles.recapCosts}>
                  <span>{t('total_cost')}</span>
                  {confirmationStep.totalPrice === null ? "0,00" : confirmationStep.totalPrice?.formatted} {confirmationStep.totalPrice?.currency.symbol}
                </div>

                {confirmationStep.coveredPrice && (
                  <div className={styles.recapCosts}>
                    <span>{t('covered_cost')}</span>
                    {confirmationStep.coveredPrice.formatted} {confirmationStep.coveredPrice.currency.symbol}
                  </div>
                )}

                {confirmationStep.currentEnvelopeBalance && (
                  <div className={styles.recapCosts}>
                    <span>{t('remaining_envelope')}</span>
                    <span>{confirmationStep.currentEnvelopeBalance.formatted} {confirmationStep.currentEnvelopeBalance.currency.symbol}</span>
                  </div>
                )}

                {confirmationStep.initialRemainingCosts && (
                  <>
                    <div className={styles.recapCosts}>
                      <span>{t('remaining_cost')}</span>
                      {confirmationStep.initialRemainingCosts.formatted} {confirmationStep.initialRemainingCosts.currency.symbol}
                    </div>
                  </>
                )}

                {confirmationStep.goodwillPrice && (
                  <>
                    <div className={styles.recapCosts}>
                      <span>
                        {(typeof confirmationStep.selectedGoodwillType === 'object'
                          ? confirmationStep.selectedGoodwillType?.label
                          : undefined)}
                      </span>
                      {confirmationStep.goodwillPrice.formatted}{' '}
                      {confirmationStep.goodwillPrice.currency.symbol}
                    </div>
                  </>
                )}


                <div className={styles.recapRemainingCosts}>
                  <span>{confirmationStep.initialRemainingCosts ? t('new_remaining_cost') : t('remaining_cost')}</span>
                  {confirmationStep.remainingCosts === null ? "0,00" : confirmationStep.remainingCosts?.formatted} {confirmationStep.totalPrice?.currency.symbol}
                </div>

                <div className={styles.recapImpactCO2}>
                  <span>{t('co2_impact')}</span>
                  <div className={styles.recapImpactCO2Value}><Leaf /><p>{confirmationStep.kgCO2e} kgCO2e</p></div>
                </div>
              </div>


              <div className={styles.recapAction}>
                <Button
                  label={t('return_case')}
                  type="primary"
                  containerProps={{
                    className: 'w-full mb-4',
                    disabled: loading,
                  }}
                  onClick={() => {
                    navigate("./../../");
                  }}
                />

                {missionContext?.steps.confirmation?.resendConfirmationEmailAction && (
                  <ResendConfirmationEmailButton
                    buttonType="secondary"
                    containerProps={{
                      className: 'w-full mb-4',
                      disabled: loading,
                    }}
                  />
                )}

                {missionContext?.steps.confirmation?.startNewSearchAction && (
                  <Button
                    label={t('new_search')}
                    type="primary"
                    containerProps={{
                      className: 'w-full mb-4',
                      disabled: loading,
                    }}
                    onClick={() => {
                      if (!missionContext?.steps.confirmation?.startNewSearchAction) {
                        return;
                      }
                      callApiAction(missionContext.steps.confirmation.startNewSearchAction);
                    }}
                  />
                )}

                {missionContext?.steps.confirmation?.alertCockpitAction && (
                  <Button
                    label={t('alert_cockpit')}
                    type="secondary"
                    containerProps={{
                      className: 'w-full mb-4',
                      disabled: loading,
                    }}
                    onClick={() => {
                      if (!missionContext?.steps.confirmation?.alertCockpitAction) {
                        return;
                      }
                      callApiAction(missionContext.steps.confirmation.alertCockpitAction);
                    }}
                  />
                )}

                {currentStep === 'confirmation' && missionContext?.steps.confirmation?.cancelAction && (
                  <Button
                    label={t('cancel_mission')}
                    type="secondary"
                    containerProps={{
                      className: 'w-full',
                      disabled: loading,
                    }}
                    onClick={() => {
                      stepAction('cancel');
                    }}
                  />
                )}
              </div>
            </Paper>
          </div>

        </div>


        <div className={styles.rightPlaceholder} />


      </div>

    </PageContainer>
  );
}
