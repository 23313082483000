import styles from './ErrorPage.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import Button from '@components/Button/Button';
import { useRouteError } from 'react-router-dom';
import { CheckNotValid } from '@assets/icons/icons';
import { useTranslation } from 'react-i18next';

export default function ErrorPage() {
  const { t } = useTranslation();
  const error: any = useRouteError();
  console.error(error);

  return (
    <PageContainer
      containerProps={{
        className: [styles.container, 'selfcare'].join(" "),
      }}
    >

      <HeaderSelfCare
        displayWarrantiesButton={false}
      />

      <div className={styles.content}>
        <CheckNotValid />
        <div className={styles.title}>
          {t('errorTitle')}
        </div>

        <p>
          {t('errorDescription')}
        </p>

        <p>
          {t('errorHelp')}
        </p>

        <div className={styles.actions}>
          <Button
            label={t('backButton')}
            onClick={() => {
              window.history.back();
            }}
            type="primary"
            colorType='error'
            containerProps={{
              className: styles.actionButton,
            }}
          />
        </div>

      </div>

    </PageContainer>
  )
}