import React, { useCallback, useRef, useState } from 'react';
import styles from './TextEllipsisWithPopup.module.scss';
import PopupInfos from '../PopupInfos/PopupInfos';
import { createPortal } from 'react-dom';

function getElementPositionRelativeToBody(element: HTMLElement) {
  const rect = element.getBoundingClientRect();
  const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
  const scrollTop = window.scrollY || document.documentElement.scrollTop;

  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
    right: rect.right + scrollLeft,
    bottom: rect.bottom + scrollTop,
    width: rect.width,
    height: rect.height,
    x: rect.x,
    y: rect.y,
    toJSON: () => rect.toJSON(),
  };
}

export default function TextEllipsisWithPopup({
  children,
  popupMaxWidth,
  containerProps: { className, ...containerProps } = {},
}: {
  children: string;
  popupMaxWidth?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const [containerAbsolutePosition, setContainerAbsolutePosition] = useState<DOMRect>();
  const [needTooltip, setNeedTooltip] = useState(false);
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const container = useRef<HTMLDivElement>(null);

  console.log("containerAbsolutePosition", containerAbsolutePosition);

  const checkIfNeedTooltip = useCallback(() => {
    if (!container.current) {
      return;
    }

    // get position of container
    const boundingRect = getElementPositionRelativeToBody(container.current);
    console.log("boundingRect", boundingRect);
    setContainerAbsolutePosition(boundingRect);

    const containerElement = container.current;
    const containerWidth = containerElement.offsetWidth;
    const contentWidth = containerElement.scrollWidth;

    setNeedTooltip(contentWidth > containerWidth);
  }, [container]);

  return (
    <>
      <div
        ref={container}
        className={[styles.container, needTooltip ? styles.needTooltip : "", className].join(" ")}
        {...containerProps}
        onMouseEnter={() => {
          checkIfNeedTooltip();
          setDisplayTooltip(true);
        }}
        onTouchStart={() => {
          checkIfNeedTooltip();
          setDisplayTooltip(true);
        }}
        onTouchEnd={() => {
          setDisplayTooltip(false);
        }}
        onMouseLeave={() => {
          setDisplayTooltip(false);
        }}
      >
        {children}
      </div>

      {createPortal(
        <PopupInfos
          containerProps={{
            style: {
              zIndex: 1000,
              display: needTooltip && displayTooltip ? "block" : "none",
              ...(containerAbsolutePosition ? {
                top: containerAbsolutePosition.top + 30,
                left: containerAbsolutePosition.left,
              } : {}),
              ...(popupMaxWidth ? { maxWidth: popupMaxWidth } : {}),

            },
            onMouseEnter: () => {
              setDisplayTooltip(true);
            },
            onMouseLeave: () => {
              setDisplayTooltip(false);
            },
          }}

        >
          {children}
        </PopupInfos>,
        document.body
      )}
    </>
  )
}