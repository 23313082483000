import CustomMarker, { MarkerProps } from '../CustomMarker';
import styles from './OriginPointMarker.module.scss';

export default function OriginPointMarker({
  destination,
  containerProps: { className, ...containerProps } = {},
  ...markerProps
}: {
  destination?: boolean;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
} & MarkerProps) {

  return (
    <CustomMarker
      anchorPoint='center'
      {...markerProps}
    >
      <div className={[styles.container, destination ? styles.destination : "", className].join(" ")} {...containerProps} onClick={() => {
        const map = markerProps.map;
        if (!map) {
          return;
        }

        map.setZoom((map.getZoom() || 0) + 1);
      }}>
        <div className={styles.circleInside} />
      </div>
    </CustomMarker>
  )
}