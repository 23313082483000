import { useState } from "react";
import { PassengerType } from "./PassengerType";
import { CardType } from "src/Stores/CurrentMissionTypes/MissionContextType";
import { CardFieldType } from "./types";
import { getCardErrors } from "./helpers";



export default function usePassengersCardsForm({
  cardTypes,
  passengers,
}: {
  cardTypes: Array<CardType>;
  passengers: Array<PassengerType>;
}) {
  const [passengersCards, setPassengersCards] = useState<Array<Array<CardFieldType>>>(passengers.map((passenger) => {
    const cards: Array<{
      cardId: number;
      cardNumber: string;
    }> = [];
    if (!passenger.cards) {
      return cards;
    }
    for (const cardId in passenger.cards) {
      const card = passenger.cards[cardId];
      cards.push({
        cardId: parseInt(cardId, 10),
        cardNumber: card.number,
      });
    }

    return cards;
  }));

  // Return true if there is an error
  const checkErrors = () => {
    let hasError = false;
    passengersCards.forEach((passengerCards, passengerIndex) => {
      passengerCards.forEach((card, cardIndex) => {
        const cardType = cardTypes.find((cardType) => cardType.id === card.cardId);
        if (!cardType) {
          return false;
        }
        const errors = getCardErrors(card, cardType);
        if (errors.length !== card.errors?.length) {
          updatePassengerCard(passengerIndex, cardIndex, { ...card, errors });
        }
        hasError = hasError || errors.length > 0;
      });
    });

    return hasError;
  }

  const updatePassengerCard = (passengerIndex: number, cardIndex: number, card: CardFieldType) => {

    //if (card.errors?.length) {
      const cardType = cardTypes.find((cardType) => cardType.id === card.cardId);
      if (cardType) {
        card.errors = getCardErrors(card, cardType);
      }
    //}

    const newPassengersCards = [...passengersCards];
    newPassengersCards[passengerIndex] = newPassengersCards[passengerIndex] || [];
    newPassengersCards[passengerIndex][cardIndex] = card;
    setPassengersCards(newPassengersCards);
  };

  const addNewPassengerCard = (passengerIndex: number, card?: CardFieldType) => {
    const newPassengersCards = [...passengersCards];
    newPassengersCards[passengerIndex] = newPassengersCards[passengerIndex] || [];
    newPassengersCards[passengerIndex].push(card || {});
    setPassengersCards(newPassengersCards);
  }

  const removePassengerCard = (passengerIndex: number, cardIndex: number) => {
    const newPassengersCards = [...passengersCards];
    newPassengersCards[passengerIndex] = newPassengersCards[passengerIndex] || [];
    newPassengersCards[passengerIndex].splice(cardIndex, 1);
    setPassengersCards(newPassengersCards);
  }

  const removePassenger = (passengerIndex: number) => {
    const newPassengersCards = [...passengersCards];
    newPassengersCards.splice(passengerIndex, 1);
    setPassengersCards(newPassengersCards);
  }

  const getPassengerCards = (passengerIndex: number) => {
    if (!passengersCards[passengerIndex]) {
      return [];
    }
    return passengersCards[passengerIndex].map((card, cardIndex) => {
      const cardType = cardTypes.find((cardType) => cardType.id === card.cardId);

      return {
        card,
        cardType,
        updatePassengerCard: (card: CardFieldType) => updatePassengerCard(passengerIndex, cardIndex, card),
        setPassengerCardId: (cardId: number) => updatePassengerCard(passengerIndex, cardIndex, { ...card, cardId }),
        setPassengerCardNumber: (cardNumber: string) => updatePassengerCard(passengerIndex, cardIndex, { ...card, cardNumber }),
        removePassengerCard: () => removePassengerCard(passengerIndex, cardIndex),
      }
    });
  }

  return {
    passengersCards,
    cardTypes,
    getPassengerCards,
    updatePassengerCard,
    addNewPassengerCard,
    removePassengerCard,
    removePassenger,

    checkErrors,
  }
}


