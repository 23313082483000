import styles from './styles.module.scss';
import stylesForm from '../../../Form.module.scss';
import Select from '@components/Select/Select';
import InputText from '@components/Inputs/InputText/InputText';
import { CardType } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import { Add } from '@assets/icons/icons';
import usePassengersCardsForm from './usePassengersCardsForm';
import { CardErrorType } from './types';
import { useTranslation } from 'react-i18next';

export default function PassengerCards({
  readOnly,
  passengersCardsForm,
  passengerIndex,
}: {
  readOnly?: boolean;
  passengersCardsForm: ReturnType<typeof usePassengersCardsForm>;
  passengerIndex: number;
}) {

  const { t } = useTranslation();

  const { cardTypes, getPassengerCards, addNewPassengerCard } = passengersCardsForm;
  const passengerCards = getPassengerCards(passengerIndex);

  const cardTypeFiltered = cardTypes.filter(cardType => {
    if (passengerCards.length === 0) {
      return true;
    }
    return !passengerCards.find(item => item.card.cardId === cardType.id);
  });

  return (
    <>
      {passengerCards.map(({
        card: {cardId, cardNumber, errors  },
        removePassengerCard,
        setPassengerCardId,
        setPassengerCardNumber,

      }, index) => (

        <PassengerCard
          key={`${passengerIndex}_${index}`}
          readOnly={readOnly}
          cardIndex={index}
          passengerIndex={passengerIndex}
          cardId={cardId}
          cardNumber={cardNumber}
          errors={errors || []}
          onChangeCardId={(newCardId) => {
            if (newCardId === undefined || newCardId === null) {
              removePassengerCard();
              return;
            }
            setPassengerCardId(newCardId);
          }}
          onChangeCardNumber={(newCardNumber) => {
            setPassengerCardNumber(newCardNumber);
          }}
          cardTypes={cardTypes.filter(cardType => {
            if (passengerCards.length === 0) {
              return true;
            }
            return !passengerCards.find((item, indexFilter) => {
              if (indexFilter === index) {
                return false;
              }
              return item.card.cardId === cardType.id;
            });
          })}
        />

      ))}


      {!readOnly && passengerCards.length < 1 && cardTypeFiltered.length > 0 && (
      <div className={stylesForm.formRow}>
        <div className={styles.addNewCard} onClick={() => {
          addNewPassengerCard(passengerIndex, {
            cardId: cardTypeFiltered[0].id,
            cardNumber: '',
          });
        }}>
          <div className={styles.addNewCardIcon}>
            <Add />
          </div>
          <div className={styles.addNewCardText}>{t('add_card_text')}</div>
        </div>
      </div>
      )}
    </>
  )
}

function PassengerCard({
  cardId,
  cardNumber,
  onChangeCardId,
  onChangeCardNumber,
  cardTypes,
  errors,
  readOnly,
}: {
  passengerIndex: number;
  cardId?: number;
  cardNumber?: string;
  cardIndex: number;
  onChangeCardId: (cardId: number | null | undefined) => any;
  onChangeCardNumber?: (cardNumber: string) => any;
  cardTypes: Array<CardType>;
  errors: Array<CardErrorType>;
  readOnly?: boolean;
}) {

  const { t } = useTranslation();

  const cardType = cardTypes.find(cardType => cardType.id === cardId);

  if (!cardType) {
    return null;
  }

  return (
    <>
      <div className={stylesForm.formRow}>
        <Select
          containerProps={{
            className: styles.fullWidth,
          }}
          disabled={readOnly}
          allowRemove
          placeholder={t('select_card_placeholder')}
          label={t('select_card_label')}
          options={cardTypes.map(cardType => cardType.label)}
          selectedIndex={cardTypes.findIndex(cardType => cardType.id === cardId)}
          onChangeIndex={(index) => {
            if (index === null) {
              onChangeCardId(null);
              return;
            }
            const currentCard = cardTypes[index];
            onChangeCardId(currentCard.id);
          }}
        />
      </div>

      <div className={`${stylesForm.formRow}`}>
        <InputText
          inputProps={{
            disabled: readOnly,
            type: "text",
            placeholder: t('input_card_number_placeholder'),
            // defaultValue: cardNumber || '',
            value: cardNumber || "",
            onChange: (e) => {
              onChangeCardNumber && onChangeCardNumber(e.target.value);
            }
          }}
          disabled={readOnly}
          containerProps={{
            className: styles.fullWidth,
          }}
          label={t('input_card_number_label')}
          errorMsg={errors.length > 0 ? errors[0].message : ""}
        />
      </div>
    </>
  )
}


// function _InputCardNumber({
//   cardType,
//   passengerIndex,
//   cardIndex,
//   cardId,
//   useFormValue,
//   cardNumber,
//   onChangeCardNumber,
// }: {
//   cardType: CardType;
//   passengerIndex: number;
//   cardIndex: number;
//   cardId?: number;
//   useFormValue: UseFormReturn<any>;
//   cardNumber?: string;
//   onChangeCardNumber?: (cardNumber: string) => any;
// }) {

//   const { control, formState: { errors }} = useFormValue;

//   useEffect(() => {
//     return () => {
//       useFormValue.reset();
//     }
//   }, [useFormValue])



//   return (
//     <div className={`${stylesForm.formRow}`}>

//       <Controller
//         name={`cardNumber_${passengerIndex}_${cardIndex}`}
//         control={control}
//         rules={{
//           required: {
//             value: true,
//             message: t('input_card_number_error')
//           },
//           ...getValidationCards(cardType),
//         }}
//         render={({ field }) => {

//           return (
//             <InputText
//               inputProps={{
//                 name: `cardNumber_${passengerIndex}_${cardIndex}`,
//                 type: "text",
//                 placeholder: t('input_card_number_placeholder'),
//                 // defaultValue: cardNumber || '',
//                 value: field.value || "",
//                 onChange: (e) => {
//                   field.onChange(e.target.value);
//                   onChangeCardNumber && onChangeCardNumber(e.target.value);
//                 }
//               }}
//               containerProps={{
//                 className: styles.fullWidth,
//               }}
//               label={t('input_card_number_label')}
//               errorMsg={(errors && errors[`cardNumber_${passengerIndex}_${cardIndex}`]?.message) as string || ''}
//             />
//           )
//         }}
//       />
//     </div>
//   )
// }