import styles from './Itinerary.module.scss';
import { Arrow, LeafSelfCare } from '@assets/icons/icons';
import { Fragment } from 'react';
import { ItineraryComponentProps } from '../types';
import {formatDistance, getTransportationIcon} from '../helpers';
import formatDuration from 'src/helpers/duration';
import TextEllipsisWithPopup from '@components/TextEllipsisWithPopup/TextEllipsisWithPopup';
import useItinerary from '../useItinerary';
import PriceIncludedOrRemaining from '@components/PriceIncludedOrRemaining/PriceIncludedOrRemaining';

export default function Itinerary(itineraryProps: ItineraryComponentProps) {

  const {
    selected = false,
    durationInMinutes,

    title = "",
    title2,
    transportations = [],
    co2Emission,

    sections = [],

    onSelect = () => void 0,
    onSelectParams,
    containerProps: { className, ...containerProps } = {},
    remainingCosts,

    readOnly = false,
  } = itineraryProps;

  const {
    isTaxiSelected,
    selectedAllSectionsIndex,
    onClickTaxiIndex,
  } = useItinerary(itineraryProps)

  const onSelectContainer = () => {
    if (readOnly) {
      return;
    }
    onSelect({
      selected: !selected,
      selectedAllSectionsIndex,
      onSelectParams,
    });
  }

  return (
    <div className={[styles.container, selected ? styles.containerSelected : "", className].join(" ")} onClick={onSelectContainer} {...containerProps}>
      <div className={styles.infosHead}>
        <TextEllipsisWithPopup
          containerProps={{
            className: [styles.title, title2 ? styles.titleWithTitle2 : ""].join(" ")
          }}
        >{title}</TextEllipsisWithPopup>
        {title2 && (
          <TextEllipsisWithPopup
            containerProps={{
              className: styles.title2
            }}
          >{title2}</TextEllipsisWithPopup>
        )}
      </div>

      <div className={styles.infosContent}>

        <div className={styles.itineraryIconsTransportation}>
          {transportations.map((transportation, i) => (
            <Fragment key={i}>
              {i !== 0 && (
                <div className={styles.stepSeparator}>
                  <Arrow width={12} height={12} />
                </div>
              )}
              {transportation === 'taxi' && (
                <div className={`${styles.step} ${styles.stepTaxi} ${isTaxiSelected(i) ? styles.stepTaxiSelected : ""}`} onClick={(e) => onClickTaxiIndex(i, e)}>
                  {getTransportationIcon(transportation)}
                  <div className={styles.label}>Taxi</div>
                </div>
              )}
              {transportation !== 'taxi' && (
              <div className={styles.step}>
                {(function () {
                  const icon = getTransportationIcon(transportation);
                  let label: string | null = null;
                  switch (transportation) {
                    case "cab":
                      label = "Taxi";
                      break;
                    case "car":
                    case "vrental":
                      label = "Location";
                      break;
                    case "train":
                      label = "Train";
                      break;
                    case "walk":
                      label = formatDistance(sections[i].distanceInMeters);
                      break;
                    case "arrival":
                      label = formatDistance(sections[i].distanceInMeters);
                      break;
                    default:
                      return null;
                  }
                  return (
                    <Fragment>
                      {icon}
                      <div className={styles.label}>{label}</div>
                    </Fragment>
                  );
                })()}
              </div>
              )}
            </Fragment>
          ))}
        </div>

        <div className={styles.itineraryDetails}>
          <div className={styles.itineraryDetailsContent}>
            <div className={styles.duration}>
              <span className={styles.durationValue}>{formatDuration({
                unit: "minutes",
                value: durationInMinutes,
              })}</span>
            </div>
            {co2Emission && (
              <div className={styles.co2Infos}>
                <div className={[styles.co2Icons, co2Emission.color === "warning" ? styles.co2IconsWarning : ""].join(" ")}>
                  {co2Emission.level >= 1 && <LeafSelfCare width={12} height={12} />}
                  {co2Emission.level >= 2 && <LeafSelfCare width={12} height={12} />}
                  {co2Emission.level >= 3 && <LeafSelfCare width={12} height={12} />}
                </div>
              </div>
            )}
          </div>



        </div>

        <div className={styles.itineraryPrice}>
          <PriceIncludedOrRemaining
            remainingCosts={remainingCosts}
          />
        </div>

      </div>

    </div>
  );
}