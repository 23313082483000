import DragAndDropList from "./DragAndDropList";
import styles from "./RankedList.module.scss";
import { SortableItem } from "./SortableItem";

export default function RankedList({
  options,
  order,
  onOrderChanged,

  label,
  testid,
  errorMsg,
  disabled,
  containerProps,
}: {
  options: Array<{
    id: string;
    label: string;
  }>;
  order: Array<string>;
  onOrderChanged: (newOrder: Array<string>) => any;

  label?: string;
  testid?: string;
  errorMsg?: string;
  disabled?: boolean;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const optionsOrdered = (order || []).map((id) => options.find((option) => option.id === id)).filter((option) => option) as Array<{
    id: string;
    label: string;
  }>;
  const remainingOptions = options.filter((option) => !order?.includes(option.id));

  return (
    <div
      data-testid={testid}
      {...containerProps}
    >
      {(label || errorMsg) && (
        <div className={styles.labelContainer}>
          {label && <div className={styles.label}>{label}</div>}
          {errorMsg && <div className={styles.errorMsg}>{errorMsg}</div>}
        </div>
      )}
      {!disabled && (<DragAndDropList
        items={[
          ...optionsOrdered,
          ...remainingOptions
        ]}
        onChangeOrder={(newOrder) => {
          onOrderChanged(newOrder.map((item) => item.id));
        }}
      />)}

      {disabled && (
        [
          ...optionsOrdered,
          ...remainingOptions
        ].map((option) => (
          <SortableItem
            disabled
            key={option.id}
            id={option.id}
            label={option.label}
          />
        ))
      )}
    </div>
  )
}