import styles from './ImportModal.module.scss';

import stylesForm from "@components/Form/Form.module.scss";
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import { useMemo, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { useDropzone } from 'react-dropzone'
import { ApiAction } from 'src/models/ApiAction';
import { getAuthenticatedApiInstance } from 'src/Api/api';
import AlertBox from '@components/AlertBox/AlertBox';


const baseStyle = {
  width: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

export default function ModalFileImport({
  action,
  onRequestClose,
  containerProps: { className, ...containerProps } = {},
}: {
  action: ApiAction;
  onRequestClose?: () => any;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [importSuccess, setImportSuccess] = useState(false);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    },
    maxFiles: 1,
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const importFile = async () => {

    if (acceptedFiles.length === 0) {
      return;
    }

    setLoading(true);

    // Ensure the file is of type File or Blob
    const file = new File([acceptedFiles[0]], acceptedFiles[0].name, {
      type: acceptedFiles[0].type,
      lastModified: acceptedFiles[0].lastModified,
    });

    const formData = new FormData();
    formData.append("import", file);

    try {
      await getAuthenticatedApiInstance().request({
        url: action.href,
        method: action.method,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setLoading(false);
      setImportSuccess(true);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal

      onRequestClose={loading ? (() => void 0) : onRequestClose}
      title={t("import_file")}
    >

      <div className={[stylesForm.form, styles.modalContainer, importSuccess ? styles.success : "", className].join(" ")} {...containerProps}>


        {!importSuccess && (
          <div className={stylesForm.formRow}>
              <div className={[styles.importFileContainer].join(" ")}>

                <section className="w-full">
                  <div {...getRootProps({

                    style: style as React.CSSProperties
                  })}>
                    <input {...getInputProps()} />
                    {acceptedFiles.length > 0 ? (
                      <p>{acceptedFiles[0].name}</p>
                    ) : (
                      <p>{t("drop_file_here")}</p>
                    )}
                  </div>
                </section>

            </div>
          </div>
        )}

        {importSuccess && (
          <div className={'w-full'}>
            <AlertBox
              displayIcon
              type="success"
              text={t("import_success")}
              containerProps={{
                className: "w-full mb-4",
              }}
            />
          </div>
        )}

        {!importSuccess && (
          <>
            <Button
              containerProps={{
                className: stylesForm.buttonSubmit,
                disabled: loading || acceptedFiles.length === 0,
              }}
              onClick={() => {
                importFile();
              }}
            >
              {loading ? (
                <Oval
                  height={20}
                  width={20}
                  color="white"
                  secondaryColor="white"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                "import"
              )}
            </Button>
            <Button
              label={t("cancel")}
              type="secondary"
              containerProps={{
                className: stylesForm.buttonCancel,
                disabled: loading,
              }}
              onClick={() => onRequestClose && onRequestClose()}
            />
          </>
        )}

        {importSuccess && (
          <Button
            label={t("close")}
            type="secondary"
            containerProps={{
              className: stylesForm.buttonCancel,
              disabled: loading,
            }}
            onClick={() => onRequestClose && onRequestClose()}
          />
        )}
      </div>

    </Modal>
  );
}