import styles from './AutocompleteInput.module.scss';

export default function AutocompleteSuggestions({
  suggestionsContainerRef,
  suggestionsIsOpen,
  isLoadingSuggestions,
  suggestions,
  selectedSuggestionIndex,
  setSelectedSuggestionIndex,
  selectSuggestion,
  getTextFromSuggestion,
  containerProps: { className, ...containerProps } = {},
}: {
  suggestionsContainerRef: React.RefObject<HTMLDivElement>;
  suggestionsIsOpen: boolean;
  isLoadingSuggestions: boolean;
  suggestions: any[];
  selectedSuggestionIndex: number | null;
  setSelectedSuggestionIndex: (index: number | null) => void;
  selectSuggestion: (suggestion: any) => void;
  getTextFromSuggestion: (suggestion: any) => string;
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {

  return (
    <div
      ref={suggestionsContainerRef}
      className={[styles.suggestions, suggestionsIsOpen ? styles.suggestionsOpen : "", className].join(" ")}
      {...containerProps}
    >
      {isLoadingSuggestions ? (
        <div className={styles.suggestion}>
          Chargement...
        </div>
      ) : suggestions.map((suggestion, i) => (
        <div
          key={i}
          className={[styles.suggestion, selectedSuggestionIndex === i ? styles.selected : ""].join(" ")}
          onClick={() => {
            setTimeout(() => selectSuggestion(suggestion), 0);
          }}
          onMouseMove={() => {
            setSelectedSuggestionIndex(i);
          }}
        >
          {getTextFromSuggestion(suggestion)}
        </div>
      ))}
    </div>
  )
}