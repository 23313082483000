import { useState } from "react";

export function useSort<SORT_TYPES extends string, T>({
  defaultSortType,
  items,
  getSortValue,
}: {
  defaultSortType?: SORT_TYPES;
  items: T[];
  getSortValue: (item: T, sortType: SORT_TYPES) => number | Date;
}) {

  const [sortType, setSortType] = useState<SORT_TYPES | null>(defaultSortType || null);

  return {
    sortedItems: items?.sort((a, b) => {
      if (sortType) {
        const aValue = getSortValue(a, sortType);
        const bValue = getSortValue(b, sortType);
        if (typeof aValue === "number" && typeof bValue === "number") {
          return aValue - bValue;
        }
        if (aValue instanceof Date && bValue instanceof Date) {
          return aValue.getTime() - bValue.getTime();
        }
      }
      return 0;
    }),

    sortType,
    setSortType,
  };
}