import { Oval } from 'react-loader-spinner';
import styles from './FullScreenLoader.module.scss';

export default function FullScreenLoader() {
  return (
    <div className={styles.loadingFullScreen}>
      <Oval
        height={40}
        width={40}
        color="white"
        secondaryColor="white"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  )
}