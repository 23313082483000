import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styles from './SearchFileResults.module.scss';
import { FileType } from 'src/models/File';
import { useTranslation } from 'react-i18next';

function TableTd({
  children,
  isLoading,
}: {
  children?: React.ReactNode;
  isLoading?: boolean;
}) {
  return (
    <td>
      <span>{children}</span>
      {isLoading && (<div className={styles.skeletonContainer}>
        <Skeleton
          width={'100%'}
          height={10}
          containerClassName={styles.skeleton}
        />
      </div>)}
    </td>
  )
}

export default function SearchFileResults({
  isLoading,
  results = [],
  onClickResult,
  containerProps: { className, ...containerProps } = {},
}: {
  isLoading?: boolean;
  results?: Array<FileType>;
  onClickResult?: (result: FileType) => any;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const { t } = useTranslation();

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>
      <table border={0} style={{}}>
        <thead>
          <tr>
            <th>{t('folder_number')}</th>
            <th>{t('beneficiary_name')}</th>
            <th>{t('mobile_phone_number')}</th>
            <th>{t('immatriculation')}</th>
            <th>{t('active_missions')}</th>
            <th>{t('archived_missions')}</th>
          </tr>
        </thead>

        {(!results || results.length === 0) && (
          <tbody className={styles.noResults}>
            <tr>
              {isLoading ? (
                <td colSpan={6}>
                  <Skeleton />
                </td>
              ) : (
                <td colSpan={6}>
                  {t('no_results')}
                </td>
              )}
            </tr>
          </tbody>
        )}

        {results && results.length > 0 && (
        <tbody>
          {results.map((result, i) => (
            <tr key={i} onClick={() => onClickResult && onClickResult(result)}>
              <TableTd isLoading={isLoading}>{result.id}</TableTd>
              <TableTd isLoading={isLoading}>{result.clientFirstname} {result.clientLastname}</TableTd>
              <TableTd isLoading={isLoading}>{
                result.clientPhone.split(" ").slice(1).join("")
              }</TableTd>
              <TableTd isLoading={isLoading}>{result.licensePlate}</TableTd>
              <TableTd isLoading={isLoading}>{result.inProgressMissionsCount}</TableTd>
              <TableTd isLoading={isLoading}>{result.passedMissionsCount}</TableTd>
            </tr>
          ))}
        </tbody>
        )}

      </table>
    </div>
  )
}