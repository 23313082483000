import { Controller, useForm } from 'react-hook-form';
import styles from './CrudEditCreateForm.module.scss';
import { CrudMetadata, Entity, EntityItem } from '../types';
import InputText from '@components/Inputs/InputText/InputText';
import Button from '@components/Button/Button';
import { NumericFormat } from 'react-number-format';
import Checkbox from '@components/Checkbox/Checkbox';
import { ApiAction } from 'src/models/ApiAction';
import { publicApi } from 'src/Api/api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '@components/Select/Select';
import Textarea from '@components/Inputs/InputText/TextArea';
import RankedList from '@components/RankedList/RankedList';

export default function CrudEditCreateForm({
  formType,
  entityItem,
  metadata,
  onSaved,
}: {
  formType: 'edit' | 'create';
  entity: Entity;
  entityItem?: EntityItem;
  metadata: CrudMetadata;
  onSaved?: () => void | Promise<void>;
}) {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const fields = (formType === 'edit' ? metadata.updateView?.fields : metadata.createView?.fields) || [];

  const defaultData = fields.reduce<any>((acc, field) => {
    acc[field.id] = entityItem ? entityItem[field.id] : undefined;

    if (field.defaultValue) {
      acc[field.id] = field.defaultValue
    }
    if (entityItem && metadata.fields[field.id].type === 'datetime' && entityItem[field.id]) {
      const date = new Date(entityItem[field.id]);
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

      acc[field.id] = formattedDate;
    }

    if (entityItem && (metadata.fields[field.id].type === 'ranked-list' || metadata.fields[field.id].type === 'multi-select')) {
      acc[field.id] = entityItem[field.id]?.map((o: any) => o.id) || [];
    }
    return acc;
  }, {});

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: defaultData,
  })

  const onSubmit = async (data: any) => {
    let action: ApiAction | undefined;
    if (formType === 'edit') {
      action = entityItem?.actions?.update;
    } else {
      action = metadata.createView?.action;
    }
    if (!action) {
      console.error('No action defined');
      return;
    }

    const apiData = { ...data };

    for (const field of fields) {
      if (metadata.fields[field.id].type === 'datetime' && apiData[field.id]) {
        apiData[field.id] = new Date(data[field.id]).toISOString();
      }
    }

    try {
      setLoading(true);
      await publicApi.request({
        method: action.method,
        url: action.href,
        data: {
          data: apiData
        }
      })

      onSaved && await onSaved();

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    console.log(data);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      {fields.map(formField => {
        const field = metadata.fields[formField.id];
        switch (field.type) {
          case 'textarea':
            return (
              <Controller
                key={field.id}
                name={field.id}
                control={control}
                rules={{
                  required: { value: formField.required || false, message: t('field_required_error_message') }
                }}
                render={({ field: useFormField }) => (
                  <Textarea
                    readOnly={formField.readOnly}
                    disabled={formField.readOnly || loading}
                    key={field.id}
                    label={field.label}
                    errorMsg={errors[field.id]?.message as string || ''}
                    containerProps={{
                      className: styles.input,
                    }}
                    textareaProps={{
                      placeholder: field.label,
                      onChange: (e) => {
                        useFormField.onChange(e.target.value);
                      },
                      value: useFormField.value || '',
                    }}
                  />
                )}
              />
            );
          case 'string':
            return (
              <Controller
                key={field.id}
                name={field.id}
                control={control}
                rules={{
                  required: { value: formField.required || false, message: t('field_required_error_message') }
                }}
                render={({ field: useFormField }) => (
                  <InputText
                    readOnly={formField.readOnly}
                    disabled={formField.readOnly || loading}
                    key={field.id}
                    label={field.label}
                    errorMsg={errors[field.id]?.message as string || ''}
                    containerProps={{
                      className: styles.input,
                    }}
                    inputProps={{
                      type: "text",
                      placeholder: field.label,
                      onChange: (e) => {
                        useFormField.onChange(e.target.value);
                      },
                      value: useFormField.value || '',
                    }}
                  />
                )}
              />
            );
          case 'number':
            return (
              <Controller
                key={field.id}
                name={field.id}
                control={control}
                rules={{
                  required: { value: formField.required || false, message: t('field_required_error_message') }
                }}
                render={({ field: useFormField }) => (
                  <InputText
                    key={field.id}
                    label={field.label}
                    containerProps={{
                      className: styles.input,
                    }}
                    inputComponent={(<NumericFormat
                      disabled={formField.readOnly || loading}
                      readOnly={formField.readOnly}
                      value={`${useFormField.value}`}
                      onChange={(e) => {
                        useFormField.onChange(parseFloat(e.target.value) || undefined);
                      }}
                    />)}
                  />
                )}
              />
            );
          case 'boolean':
            return (
              <Controller
                key={field.id}
                name={field.id}
                control={control}
                render={({ field: useFormField }) => (
                  <Checkbox
                    disabled={formField.readOnly || loading}
                    label={field.label}
                    checked={useFormField.value}
                    onChange={(checked) => {
                      useFormField.onChange(checked);
                    }}
                  />
                )}
              />
            );
          case 'ranked-list':
            return (
              <Controller
                key={field.id}
                name={field.id}
                control={control}
                rules={{
                  required: { value: formField.required || false, message: t('field_required_error_message') }
                }}
                render={({ field: useFormField }) => {

                  return (
                    <RankedList
                      key={field.id}
                      label={field.label}
                      disabled={formField.readOnly}
                      errorMsg={errors[field.id]?.message as string || ''}
                      containerProps={{
                        className: styles.input,
                      }}
                      options={field.options?.map(option => ({
                        id: option.id,
                        label: option.label
                      })) || []}
                      order={useFormField.value || []}
                      onOrderChanged={(value) => {
                        useFormField.onChange(value);
                      }}
                    />
                  )
                }}
              />

            )

          case 'multi-select':
            return (
              <Controller
                key={field.id}
                name={field.id}
                control={control}
                rules={{
                  required: { value: formField.required || false, message: t('field_required_error_message') }
                }}
                render={({ field: useFormField }) => (
                  <Select
                    key={field.id}
                    label={field.label}
                    placeholder={""}
                    disabled={formField.readOnly}
                    errorMsg={errors[field.id]?.message as string || ''}
                    containerProps={{
                      className: styles.input,
                    }}
                    allowMultiple
                    displayedValue={useFormField.value.map((idSelected: string) => field.options?.find((opt) => opt.id === idSelected)?.label).filter((val: any) => !!val).join(', ')}
                    optionsV2={field.options?.map(option => ({
                      label: option.label,
                      value: option.id,
                      searchValue: option.label
                    }))}
                    selectedIndexes={field.options?.map(option => useFormField.value?.includes(option.id)).map((value, index) => value ? index : undefined).filter(index => index !== undefined) as number[]}
                    onChangeIndexes={(selectedIndexes) => {
                      useFormField.onChange(selectedIndexes.map(index => field.options?.[index].id));
                    }}
                  />
                )}
              />
            );

          case 'select':
          case 'select-img':
            return (
              <Controller
                key={field.id}
                name={field.id}
                control={control}
                rules={{
                  required: { value: formField.required || false, message: t('field_required_error_message') }
                }}
                render={({ field: useFormField }) => (
                  <Select
                    key={field.id}
                    label={field.label}
                    disabled={formField.readOnly}
                    errorMsg={errors[field.id]?.message as string || ''}
                    containerProps={{
                      className: styles.input,

                    }}
                    optionsV2={field.options?.map(option => ({
                      label: option.label,
                      value: option.id
                    }))}
                    selectedIndex={field.options?.findIndex(option => option.id === useFormField.value)}
                    onChangeIndex={(selectedIndex) => {
                      if (selectedIndex === null) {
                        useFormField.onChange(null);
                        return;
                      }
                      useFormField.onChange(field.options?.[selectedIndex].id);
                    }}
                  />
                )}
              />
            );

          case 'datetime':
            return (
              <Controller
                key={field.id}
                name={field.id}
                control={control}
                rules={{
                  required: { value: formField.required || false, message: t('field_required_error_message') }
                }}
                render={({ field: useFormField }) => {

                  // Sorry for that... but sometimes the actual value is below the minDate
                  // in such case we take the actual value as min date
                  const min = field.minDate ? minDate(field.minDate, useFormField.value) : undefined;

                  return (
                    <InputText
                      key={field.id}
                      label={field.label}
                      errorMsg={errors[field.id]?.message as string || ''}
                      containerProps={{
                        className: styles.input,
                      }}
                      inputProps={{
                        type: "datetime-local",
                        placeholder: field.label,
                        min: min?.slice(0, 16),
                        max: field.maxDate?.slice(0, 16),
                        onChange: (e) => {
                          useFormField.onChange(e.target.value || undefined);
                        },
                        value: useFormField.value || '',
                      }}
                    />
                  )
                }}
              />
            )
          default:
            return null;
        }
      })}
      <Button
        label={t('button.save')}
        containerProps={{
          type: "submit",
          className: styles.buttonSave,
          disabled: loading
        }}
      />
    </form>
  )
}

const minDate = (dateA: string, dateB: string) => dateA < dateB ? dateA : dateB;
