import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export const useMobilePreview = create<{
  modalProps: any;
  openInPreview: (url: string) => void;
  close: () => void;
}>()(
  devtools(
    (set) => ({
      modalProps: null,
      openInPreview: (url: string) => set({
        modalProps: {
          url,
        }
      }),

      close: () => {
        set({
          modalProps: null,
        })
      },

    })
  )
);
