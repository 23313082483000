import { useForm } from 'react-hook-form';
import { CrudMetadata, Entity } from '../types';
import withCrudMetadata from '../withCrudMetadata';
import styles from './EditCrudPage.module.scss';

const EditCrudPage = withCrudMetadata(({
  entity,
}: {
  entity: Entity;
  metadata: CrudMetadata;
}) => {

  const {
    handleSubmit
  } = useForm();

  const onSubmit = (data: any) => {
    console.log(data);
  }

  return (
    <div className={styles.container}>
      <h1>Edit {entity.label}</h1>

      <form onSubmit={handleSubmit(onSubmit)}>

      </form>
    </div>
  )
});

export default EditCrudPage;