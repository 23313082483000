import * as ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";


import "./index.scss";
import "./assets/fonts/WixMadeforDisplay/stylesheet.css";
import "./assets/fonts/Allianz/stylesheet.css";
import "./assets/fonts/icons/style.css";
import 'mapbox-gl/dist/mapbox-gl.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Locales/i18n';
import './Locales/date-fns-locales-setdefaults';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { I18nextProvider } from "react-i18next";
import i18n from "./Locales/i18n";
import App from "./App";

let sentryEnv = 'production';
switch (window.location.hostname) {
  case 'localhost':
  case '127.0.0.1':
  case 'local.int.awp.thetreep.com':
    sentryEnv = 'development';
    break;
  case 'int.awp.thetreep.com':
    sentryEnv = 'int';
    break;
  case 'staging.awp.thetreep.com':
    sentryEnv = 'staging';
    break;
  case 'uat.awp.thetreep.com':
    sentryEnv = 'uat';
    break;
  case 'preprod.agent.maas.mondial-assistance.com':
    sentryEnv = 'preprod';
    break;
  default:
    sentryEnv = 'production';
    break;
}

if (sentryEnv !== 'development') {
  Sentry.init({
    environment: sentryEnv,
    dsn: "https://c1a5b1e1be45fda479d025108ac1a5a9@sentry.thetreep.com/8",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost"],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>
);