import styles from './TableTd.module.scss';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import React from 'react';



export default function TableTd({
  children,
  isLoading,
  rowSpan,
  className,
  containerClassName,
  testid,
}: {
  children?: React.ReactNode;
  isLoading?: boolean;
  rowSpan?: number;
  className?: string;
  containerClassName?: string;
  testid?: string;
}) {
  return (
    <td rowSpan={rowSpan} className={`${className} ${styles.td}`} data-testid={testid}>
      <div
        className={containerClassName}
        style={{ visibility: isLoading ? 'hidden': 'visible'}}>{children}</div>
      {isLoading && (<div className={styles.skeletonContainer}>
        <Skeleton
          width={'100%'}
          height={10}
          containerClassName={styles.skeleton}
        />
      </div>)}
    </td>
  )
}