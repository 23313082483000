import { useRef, useState } from "react";
import Button, { ButtonPropsType } from "@components/Button/Button";
import { useOnClickOutside } from "usehooks-ts";

export default function MultiActionsButton({
  actions,
  buttonProps
}: {
  actions: Array<{
    label: string;
    onClick: () => any;
  }>;
  buttonProps?: ButtonPropsType
}) {

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const closeDropdown = () => setIsOpen(false);

  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });


  return (
    <div ref={ref} className="relative inline-block text-left">
      {/* Main button */}
      <Button
        {...buttonProps}
        onClick={toggleDropdown}
      >
        Actions
        <svg
          className="w-5 h-5 ml-2 -mr-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </Button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute right-0 z-10 w-44 mt-2 bg-white border border-gray-200 rounded-md shadow-lg">
          <ul className="py-1">
            {actions.map((action, i) => (
              <li key={i}>
                <button
                  onClick={() => {
                    closeDropdown();
                    action.onClick();
                  }}
                  className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                >
                  {action.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}