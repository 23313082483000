import { PassengerInformationType } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import styles from './PassengerForm.module.scss';
import PassengerInput from '../PassengerInput/PassengerInput';
import { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function PassengerForm({
  loading,
  stepIsReadOnly,
  watch,
  control,
  errors,
  indexPassenger,
  passenger,
}: {
  loading: boolean;
  stepIsReadOnly: boolean;
  watch: UseFormWatch<any>;
  control: Control<any, any>;
  errors: FieldErrors<any>;
  indexPassenger: number;
  passenger: PassengerInformationType;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { t } = useTranslation();

  return (
    <div data-testid="passenger-form" key={passenger.id} className={styles.passengerFormContainer}>
      <div className={styles.passengerFormTitle}>
        {passenger.title}
        {indexPassenger === 0 && ` > ${t('beneficiary')}` }
      </div>

      <div className={styles.passengerForm}>
        {passenger.subtitle ? (
            <div className={`${styles.passengerFormSubtitle} w-full text-left`}>
              {passenger.subtitle}
            </div>
        ) : null }

        {(passenger.fields || []).map((fields, fieldLineIndex) => (
          <div key={fieldLineIndex} className={styles.passengerFormRow}>
            {(fields || []).map((fieldGroup) => {
              return (
                <PassengerInput
                  control={control}
                  errors={errors}
                  formField={fieldGroup}
                  passenger={passenger}
                  readOnly={stepIsReadOnly}
                  loading={loading}
                  watch={watch}
                  key={fieldGroup.name}
                />
              );
            })}
          </div>
        ))}
      </div>
    </div>
  )
}
