import React, { useState, useEffect, useMemo } from 'react';
import Supercluster from 'supercluster';
import { MarkerProps, PropsAreCustomMarkerCompatible } from './CustomMarker';
import ClusterPointMarker from './ClusterPointMarker/ClusterPointMarker';


export const MarkerClusterer = ({
  map,
  children,
}: {
  map?: google.maps.Map | undefined;
  children: Iterable<React.ReactNode>;
}) => {


  const [mapBounds, setMapBounds] = useState<google.maps.LatLngBounds>(map?.getBounds() || new google.maps.LatLngBounds());
  const [cluster, setCluster] = useState<Supercluster>();
  const [zoom, setZoom] = useState<number>(map?.getZoom() || 0);


  useEffect(() => {
    if (!map) return;

    const cluster = new Supercluster();

    const markersProps = React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) return null;
      if (!PropsAreCustomMarkerCompatible(child.props)) return null;
      return {
        key: child.key,
        markerProps: child.props,
      };
    })?.filter(m => m) as Array<{ key: string, markerProps: MarkerProps }>;

    cluster.load(markersProps.map(m => ({
      type: 'Feature',
      properties: m,
      geometry: {
        type: 'Point',
        coordinates: [m.markerProps.position.lng, m.markerProps.position.lat],
      },
    })));

    setCluster(cluster);

  }, [children, zoom, mapBounds, map]);

  // Zoom and map bounds listeners
  useEffect(() => {
    if (!map) return;

    const listener = map.addListener('zoom_changed', () => {
      setZoom(map.getZoom() || 0);
    });
    const listener2 = map.addListener('bounds_changed', () => {
      setMapBounds(map.getBounds() || new google.maps.LatLngBounds());
    });

    return () => {
      if (listener) {
        google.maps.event.removeListener(listener);
      }
      if (listener2) {
        google.maps.event.removeListener(listener2);
      }
    };
  }, [map]);


  const clusters = useMemo(() => {
    if (!cluster) return [];

    return cluster.getClusters([
      mapBounds.getSouthWest().lng(),
      mapBounds.getSouthWest().lat(),
      mapBounds.getNorthEast().lng(),
      mapBounds.getNorthEast().lat(),
    ], zoom);
  }, [cluster, mapBounds, zoom]);

  const childrenKeysNotInClusters = useMemo(() => {
    return clusters.filter(c => !c.properties.cluster).map(c => {
      return c.properties.key;
    });
  }, [clusters]);

  if (!map) return null;

  return (
    <>
      {clusters.map((cluster) => {
        if (cluster.properties.cluster) {
          return (
            <ClusterPointMarker
              key={cluster.properties.cluster_id}
              point_count={cluster.properties.point_count}
              map={map}
              position={{
                lat: cluster.geometry.coordinates[1],
                lng: cluster.geometry.coordinates[0],
              }}
            />
          )
        } else {
          return null;
        }
      })}

      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) return null;
        if (!child.key) return null;
        if (!childrenKeysNotInClusters.includes(child.key)) return null;

        return React.cloneElement(child as React.ReactElement<any>, {
          map,
        });
      })}
    </>
  );
};
