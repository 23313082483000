import RadioButton from '@components/RadioButton/RadioButton';
import { Chevron } from '@assets/icons/icons';
import styles from './SelectableContent.module.scss';
import { useLayoutEffect, useRef, useState } from 'react';

export default function SelectableContent({
  selected = false,
  readOnly = false,
  onSelect = () => void 0,

  content,
  expandedContent,

  containerProps: { className, ...containerProps } = {},
}: {
  selected?: boolean;
  readOnly?: boolean;
  onSelect?: () => any;

  content: React.ReactNode;
  expandedContent?: React.ReactNode;

  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const [maxHeight, setMaxHeight] = useState(0);
  const expandedContentRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);

  const onSelectWithControl = () => {
    if (!readOnly) {
      onSelect();
    }
  }

  useLayoutEffect(() => {
    setMaxHeight(expandedContentRef.current?.scrollHeight || 0);
  }, [expandedContent]);

  return (
    <div className={[styles.container, `${readOnly ? styles.readOnly : ""}`, className].join(" ")} {...containerProps} onClick={() => {
        onSelectWithControl();
    }}>

      <div className={`${styles.primaryContent}`}>
        <RadioButton
          selected={selected}
          containerProps={{
            onClick: (e) => {
              e.stopPropagation();
              e.preventDefault();
              onSelectWithControl();
            },
            className: `${styles.radioButton}`,
          }}
        />

        <div className={styles.primaryContentContainer}>
          {content}
        </div>


        {expandedContent && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setExpanded(!expanded)
          }}
          className={[styles.develop, expanded ? styles.developActiv : ""].join(" ")}
        >
          <Chevron />
        </div>
        )}

      </div>


      {expandedContent && (
      <div
        ref={expandedContentRef}
        className={[styles.expandedContainer, expanded ? styles.expandedContainerExpanded : ""].join(" ")}
        style={{
          maxHeight: expanded ? (maxHeight+"px") : "0px",
        }}
      >
        <div className={styles.expandedSeparator} />
        <div className={styles.expandedContent}>
          {expandedContent}
        </div>
      </div>
      )}

    </div>
  )
}