import { useMemo, useState } from "react";
import { FilterValueMapping, FiltersType } from "./types";
import { filterItemsWithValues } from "./filterItemsWithValues";
import itemsToFilterItems from "./itemsToFilterItems";
import { ModalProps } from "./types";
import getTags from "./getTags";

export type UseFilterReturnType = {
  idsFiltered: { [key: string]: boolean };
  resetFilters: (newFilters?: FilterValueMapping) => void;
  onClickButtonFilter: () => void;
  countNbFiltersApplied: number;
  modalProps: ModalProps;
  filters: FiltersType;
  appliedFilterValues: FilterValueMapping;
}

type FilterTypeToValueMapping = {
  switch?: boolean;
  multicheckboxes?: {
    [key: string]: boolean;
  };
  multiselect?: {
    [key: string]: boolean;
  };
}

export default function useFilter({
  items,
  filters,
}: {
  items: any[];
  filters: FiltersType;
}): UseFilterReturnType {

  const defaultFilterValues: FilterValueMapping = useMemo(() => {
    return Object.keys(filters).reduce((acc, filterKey) => {
      const filter = filters[filterKey as keyof typeof filters];
      if (filter.disabled) {
        return acc;
      }
      return {
        ...acc,
        [filterKey]: filter.type === 'switch' ? filter.defaultSelectedValue : filter.defaultSelectedValues,
      }
    }, {});
  }, [filters]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterFormValues, setFilterFormValues] = useState<FilterValueMapping>(defaultFilterValues);
  const [appliedFilterValues, setAppliedFilterValues] = useState<FilterValueMapping>(defaultFilterValues);
  const filterItems = useMemo(() => itemsToFilterItems(items, filters), [filters, items]);

  // all item id that match the filter
  const idsFiltered: { [key: string]: boolean } = useMemo(() => {
    return filterItemsWithValues(filterItems, filters, appliedFilterValues);
  }, [filterItems, filters, appliedFilterValues]);

  const filtersWithValueOnChange = Object.keys(filters).reduce((acc, filterKey) => {
    const filter = filters[filterKey as keyof typeof filters];
    if (filter.disabled) {
      return acc;
    }
    const filterValue = filterFormValues[filterKey as keyof typeof filterFormValues];

    switch (filter.type) {
      case 'switch':
        return {
          ...acc,
          [filterKey]: {
            ...filter,
            value: filterValue,
            onChange: (value: boolean) => {
              setFilterFormValues(filterFormValues => ({
                ...filterFormValues,
                [filterKey]: value,
              }));
            }
          },
        }
      default:
        return {
          ...acc,
          [filterKey]: {
            ...filter,
            values: filterValue,
            onChange: (value: {
              [key: string]: boolean;
            }) => {
              setFilterFormValues(filterFormValues => ({
                ...filterFormValues,
                [filterKey]: value,
              }));
            }
          },
        }
    }
  }, {});

  const applyFilterButtonDisabled = useMemo(() => {
    // use required to check if all required filters are filled
    return Object.keys(filters).some((filterKey) => {
      const filter = filters[filterKey as keyof typeof filters];

      if (filter.type === 'switch') {
        return false;
      }

      if (filter.required) {
        if (filter.type === "multicheckboxes" || filter.type === "multiselect") {
          const filterValue = filterFormValues[filterKey] as FilterTypeToValueMapping['multicheckboxes'];
          if (!filterValue) {
            return true;
          }
          return !Object.keys(filterValue).some((key) => filterValue[key]);
        }
        return false;
      }
      return false;
    });
  }, [filterFormValues, filters]);

  const resetFilters = (newFilterValues?: FilterValueMapping) => {
    setFilterFormValues({
      ...defaultFilterValues,
      ...newFilterValues,
    });
    setAppliedFilterValues({
      ...defaultFilterValues,
      ...newFilterValues,
    });
  }

  return {
    idsFiltered,

    resetFilters,

    onClickButtonFilter: () => {
      setFilterFormValues(appliedFilterValues);
      setIsModalOpen(true);
    },

    countNbFiltersApplied: filterFormValues ? Object.keys(filterFormValues).map((filterKey) => {
      const filterValue = filterFormValues[filterKey as keyof typeof filterFormValues];
      if (typeof filterValue === 'boolean') {
        return filterValue ? 1 : 0;
      }
      return Object.keys(filterValue || {}).filter((key) => filterValue[key]).length;
    }).reduce((acc, value) => acc + value, 0) : 0,

    filters: filtersWithValueOnChange,
    appliedFilterValues,

    modalProps: {
      isOpen: isModalOpen,
      applyFilterButtonDisabled,
      tags: getTags({
        filters: filtersWithValueOnChange,
        filterValues: filterFormValues,
      }),
      filters: filtersWithValueOnChange,
      onClickApply: () => {
        setAppliedFilterValues(filterFormValues);
        setIsModalOpen(false);
      },
      onRequestClose: () => setIsModalOpen(false),
    }
  }
}