import { useTranslation } from 'react-i18next';
import Paper from '@components/Layout/Paper';
import styles from './TrainChoiceItem.module.scss';
import TravelPath from '@components/TravelPath/TravelPath';
import Tag from '@components/Tag/Tag';
import { format } from 'date-fns';
import formatDuration from 'src/helpers/duration';
import { Leaf } from '@icons/icons';
import Skeleton from 'react-loading-skeleton';
import { isSelfCare } from 'src/Stores/AppContext';
import PriceIncludedOrRemaining from '@components/PriceIncludedOrRemaining/PriceIncludedOrRemaining';
import { TrainProposalType } from 'src/Stores/CurrentMissionTypes/Types';

export default function TrainChoiceItem({
  loading = false,
  isReadOnly = false,
  selectedCabinClassIndex = null,
  onClick = () => void 0,
  onClickPrice = () => void 0,
  proposal,
  cabinClassesIndexToHide,
  containerProps: { className, ...containerProps } = {},
}: {
  loading?: boolean;
  isReadOnly?: boolean;
  selectedCabinClassIndex?: number | null;
  onClick?: (cabinClassIndex: number) => any;
  onClickPrice?: (cabinClassIndex: number) => any;
  proposal: TrainProposalType;
  cabinClassesIndexToHide: Array<number>;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const { t } = useTranslation();
  const fromDateTime = new Date(proposal.origin.datetime);
  const toDateTime = new Date(proposal.destination.datetime);
  const diff = toDateTime.getTime() - fromDateTime.getTime();
  const durationFormmatted = formatDuration({
    value: diff / 1000 / 60,
    unit: "minutes",
  })

  const connectionsCount = proposal.connectionsCount;

  return (
    <Paper
      className={[styles.container, isSelfCare() ? styles.containerSelfCare : "", className].join(" ")}
      {...containerProps}
      onClick={() => {
        if (loading || !onClick) return;
        onClick(0);
      }}
    >

      {loading && (
        <div className={styles.skeletonContainer}>
          <Skeleton
            width={'100%'}
            height={'100%'}
            className={styles.skeleton}
          />
        </div>
      )}

      {!isSelfCare() && (<div className={styles.carrierImgContainer}>
        {!!proposal.carrier?.logoUrl && (
          <img src={proposal.carrier.logoUrl} width={40} />
        )}
      </div>)}

      <TravelPath
        travelInfo={{
          left: durationFormmatted,
          right: (
            <div className={styles.travelInfoContainer}>
              {connectionsCount === 0 ? t('direct') : t('connections_count', { count: connectionsCount })}
              <Tag
                label={`${proposal.kgCO2e} kgCO2e`}
                color={"#CFE9EE"}
                icon={<Leaf width={8} height={8} />}
                containerProps={{
                  className: styles.tagCO2
                }}
              />
            </div>
          )
        }}
        from={{
          labelLeft: format(new Date(proposal.origin.datetime), "HH:mm"),
          labelRight: proposal.origin.name,
        }}
        to={{
          labelLeft: format(new Date(proposal.destination.datetime), "HH:mm"),
          labelRight: proposal.destination.name,
        }}
        containerProps={{
          className: styles.travelPath,
        }}
      />

      {!isSelfCare() && (<div className={styles.prices}>
        {proposal.cabinClasses.map((cabinClass, i) => (cabinClass && cabinClassesIndexToHide.indexOf(i) === -1) ? (
          <div key={i} className={[
              styles.price,
              selectedCabinClassIndex === i ? styles.priceSelected : "",
              isReadOnly ? styles.priceDisabled : ""
            ].join(" ")}
                onClick={() => {
                  !isReadOnly && onClickPrice(i);
                }}
              >
                {cabinClass.offers[0]?.price.formatted} {cabinClass.offers[0]?.price.currency.symbol}
              </div>
        ) :
        <div key={i} className={[
              styles.price,
              styles.priceDisabled,
              selectedCabinClassIndex === i ? styles.priceSelected : ""
            ].join(" ")}
          ></div>)}

      </div>)}

      {isSelfCare() && proposal.cabinClasses[0]?.offers[0] && (
        <PriceIncludedOrRemaining
          remainingCosts={proposal.cabinClasses[0]?.offers[0]?.remainingCosts}
          containerProps={{
            style: {
              width: '25%'
            }
          }}
        />
      )}

    </Paper>
  )
}