import FullScreenLoader from "@components/FullScreenLoader/FullScreenLoader";
import { useCrudMetadata } from "src/Pages/Admin/CRUD/CrudMetadataStore";
import { CrudMetadata, Entity } from "./types";


interface WithCrudMetadataProps {
  entity: Entity;
  metadata: CrudMetadata;
}

// Update the HOC to use generics
export default function withCrudMetadata<P extends WithCrudMetadataProps>(
  Component: React.ComponentType<P>
) {
  return (props: Omit<P, 'metadata' | 'entity'>) => {
    const { metadata, entity } = useCrudMetadata();
    if (!metadata || !entity) {
      return <FullScreenLoader />;
    }
    return <Component {...(props as P)} metadata={metadata} entity={entity} />;
  }
}