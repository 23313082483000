import ModalAlert from "@components/ModalConfirm/ModalAlert";
import ModalConfirm from "@components/ModalConfirm/ModalConfirm";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAppContext } from "src/Stores/AppContext";
import { useConfirmAlert } from "src/Stores/ConfirmAlertStore";

export default function AppSelfCareContainer() {

  const { modalProps } = useConfirmAlert();
  const { setIsSelfCare } = useAppContext();

  useEffect(() => {
    document.body.classList.add("selfcare");
    setIsSelfCare(true);
    return () => {
      document.body.classList.remove("selfcare");
      setIsSelfCare(false);
    }
  }, [setIsSelfCare]);

  return (
    <>
      {modalProps && modalProps.type === "confirm" && (
        <ModalConfirm
          confirmText={modalProps.confirmText || "Oui"}
          cancelText={modalProps.cancelText || "Non"}
          {...modalProps}
        />
      )}

      {modalProps && modalProps.type === "alert" && (
        <ModalAlert
          {...modalProps}
        />
      )}
      {/* <div className={styles.selfCareContainer}>
        <div className={styles.selfCareContentContainer}> */}
          <Outlet />
        {/* </div>
      </div> */}
    </>
  )
}