import { Outlet, useParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { publicApi } from 'src/Api/api';
import FullScreenLoader from '@components/FullScreenLoader/FullScreenLoader';
import { useCrudMetadata } from 'src/Pages/Admin/CRUD/CrudMetadataStore';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import styles from './CrudStyles.module.scss'
import HeaderV2 from 'src/layout/Header/V2/HeaderV2';
import { CrudMetadata, Entity } from './types';
import { useCrudEntities } from './ListEntitiesStore';
import { ApiAction } from 'src/models/ApiAction';

export default function CrudContainer() {

  const { entity: entityId } = useParams<{entity: string }>();
  const { metadata, setMetadata, setEntity } = useCrudMetadata();
  const { entities, setEntities } = useCrudEntities();

  const fetchMetadata = useCallback(async (getMetadataAction: ApiAction): Promise<CrudMetadata | null> => {

    if (!entityId) {
      return null;
    }

    const metadata = await publicApi.request({
      method: getMetadataAction.method,
      url: getMetadataAction.href,
    });
    if (metadata.data) {
      setMetadata({
        ...(metadata.data as CrudMetadata),
        idField: (() => {
          switch (entityId) {
            case 'legalEntities':
              return 'code';
            default:
              return "";
          }
        })()
      })
      return metadata.data as CrudMetadata;
    }
    return null;
  }, [entityId, setMetadata]);

  const fetchEntities = useCallback(async (): Promise<any> => {
    // admin/entities
    const entities = await publicApi.get(`/admin/entities`);
    if (entities.data) {
      setEntities(entities.data.entities as Entity[]);
    }

    return entities.data.entities;
  }, [setEntities]);

  useEffect(() => {
    setMetadata(null);
    fetchEntities().then((entities) => {
      if (entityId) {
        const entityData = entities.find((e: any) => e.id === entityId) as Entity | undefined;
        if (entityData) {
          setEntity(entityData);
          fetchMetadata(entityData.actions.getMetadata);
        }
      }

    })
  }, [fetchMetadata, fetchEntities, entityId, setEntity, setMetadata]);

  if (!entities || (entityId && !metadata)) {
    return <FullScreenLoader />;
  }

  return (
    <PageContainer
      containerProps={{
        className: [styles.container, styles.sticky].join(" "),
      }}
    >

      <HeaderV2 />

      <div className={styles.content}>

        <div className={styles.header}>

          <Outlet />

        </div>
      </div>
    </PageContainer>
  )
}