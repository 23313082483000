export const luhn = (number: string) => {
    const sanitizedNumber = number.replace(/\D/g, '');
    let sum = 0;
    let doubleUp = false;
  
    for (let i = sanitizedNumber.length - 1; i >= 0; i--) {
      let digit = parseInt(sanitizedNumber.charAt(i), 10);
      if (doubleUp) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }
      sum += digit;
      doubleUp = !doubleUp;
    }
  
    return sum % 10 === 0;
  };
  