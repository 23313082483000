import { Outlet, useNavigate } from 'react-router-dom';
import styles from './CrudEntityContainer.module.scss'
import Button from '@components/Button/Button';
import { useTranslation } from 'react-i18next';

export default function CrudEntityContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={styles.content}>

      <div className={styles.header}>
        <Button
          label={t('back_button')}
          onClick={() => {
            navigate("../");
          }}
        />
        <Button
          label={t('return_home')}
          onClick={() => {
            navigate("/");
          }}
          type='secondary'
        />
      </div>

      <Outlet />
    </div>
  )
}