import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

export const useKeyValueStore = create<{
  values: {[key: string]: any};
  setKeyValue: (key: string, value: any) => void;
}>()(
  devtools(
    persist(
      (set, get) => ({
        values: {},
        setKeyValue: (key: string, value: any) => set({
          values: {
            ...get().values,
            [key]: value,
          }
        }),

      }),
      {
        name: 'keyvalue-storage', // name of the item in storage (must be unique)
      }
    )
  )

);