import { MapUnderWrapper } from '@components/GoogleMap/Map';
import styles from './ItineraryMap.module.scss';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { SolutionPoint, getPointsFromSolutions } from './utils';
import { getBoundsFromPoints, placeTypeToLatLngLiteral } from '@components/GoogleMap/utils';
import OriginPointMarker from '@components/GoogleMap/OriginPointMarker/OriginPointMarker';
import SolutionPointMarker from '@components/GoogleMap/SolutionPointMarker/SolutionPointMarker';
import { useMemo, useState } from 'react';
import { DashLine } from '@components/GoogleMap/DashLine';
import { MarkerClusterer } from '@components/GoogleMap/MarkerClusterer';
import { SolutionType } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import Circle from '@components/GoogleMap/Circle/Circle';


export default function ItineraryMap({
  searchRadius,
  solutions = [],
  containerProps: { className, ...containerProps } = {},
  onPointSelected,
}: {
  searchRadius?: number;
  solutions: SolutionType[];
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  onPointSelected?: (point: SolutionPoint | null) => void;
}) {

  const [pointIndexSelected, setPointIndexSelected] = useState<number | null>(null); // [0, solutionsPoints.length
  const { missionContext, mission } = useCurrentMission();

  const origin = mission?.searchData?.origin;
  const destination = mission?.searchData?.destination;

  const solutionsPoints = useMemo(() => {
    return getPointsFromSolutions(solutions || []);
  }, [solutions]);

  const bounds = useMemo(() => {

    if (!origin) {
      return null;
    }

    return getBoundsFromPoints([
      ...solutionsPoints, placeTypeToLatLngLiteral(origin)
    ])

  }, [solutionsPoints, origin]);

  if (!mission?.searchData?.origin) {
    return null;
  }
  if (!missionContext || !missionContext.steps?.solutions) {
    return null;
  }

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>

      <div className={styles.map}>

        <MapUnderWrapper
          style={{ height: "100%", width: "100%", borderRadius: "10px" }}
          bounds={bounds}
        >
          {origin && (
            <OriginPointMarker
              position={{
                lat: origin.latitude,
                lng: origin.longitude
              }}
            />
          )}

          {origin && searchRadius && (
            <Circle
              center={{
                lat: origin.latitude,
                lng: origin.longitude
              }}
              radius={searchRadius}
            />
          )}

          {destination && (
            <OriginPointMarker
              destination
              position={{
                lat: destination.latitude,
                lng: destination.longitude
              }}
            />
          )}

          {origin && destination && (
            <DashLine
              origin={{
                lat: origin.latitude,
                lng: origin.longitude
              }}
              destination={{
                lat: destination.latitude,
                lng: destination.longitude
              }}
            />
          )}

          <MarkerClusterer>
            {solutionsPoints.map((solutionPoint, index) => (
              <SolutionPointMarker
                key={`${solutionPoint.lat}-${solutionPoint.lng}-${index}`}
                isActiv={pointIndexSelected === index}
                onClick={() => {
                  setPointIndexSelected(pointIndexSelected === index ? null : index);
                  onPointSelected && onPointSelected(pointIndexSelected === index ? null : solutionPoint);
                }}
                solutionPoint={solutionPoint}
                position={{ lat: solutionPoint.lat, lng: solutionPoint.lng }}
              />
            ))}
          </MarkerClusterer>
        </MapUnderWrapper>

      </div>

    </div>
  )
}