import React, { useState } from "react";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "./SortableItem";

interface Item {
  id: string;
  label: string;
}

interface DragAndDropListProps {
  items: Item[];
  onChangeOrder: (newOrder: Item[]) => void;
}

const DragAndDropList: React.FC<DragAndDropListProps> = ({ items, onChangeOrder }) => {
  const [currentItems, setCurrentItems] = useState(items);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor)
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = currentItems.findIndex(item => item.id === active.id);
      const newIndex = currentItems.findIndex(item => item.id === over.id);

      const newOrder = arrayMove(currentItems, oldIndex, newIndex);
      setCurrentItems(newOrder);
      onChangeOrder(newOrder);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={currentItems} strategy={verticalListSortingStrategy}>
        <div>
          {currentItems.map(item => (
            <SortableItem key={item.id} id={item.id} label={item.label} />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default DragAndDropList;