import { InputHTMLAttributes, useState } from 'react';
import styles from './InputText.module.scss';
import { IconEye } from '@assets/icons/icons';

export default function InputText({
  label,
  testid,
  errorMsg,
  inputProps,
  readOnly,
  containerProps: { className, ...containerProps } = {},
  variant = "default",
  disabled,
  inputComponent,
}: {
  label?: React.ReactNode;
  testid?: string;
  errorMsg?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  readOnly?: boolean;
  variant?: "default" | "outlined";
  disabled?: boolean;
  inputComponent?: React.ReactNode;
}) {

  const [ showPassword, setShowPassword ] = useState<boolean>(false);

  return (
    <div data-testid={testid} className={[styles.container, disabled ? styles.disabled : "", styles[`container_${variant}`], className].join(" ")} {...containerProps}>
      {(label || errorMsg) && (
        <div className={styles.labelContainer}>
          {label && <div className={styles.label}>{label}</div>}
          {errorMsg && <div data-testid='errorMsg' className={styles.errorMsg}>{errorMsg}</div>}
        </div>
      )}
      <div className={styles.inputContainer}>
        {inputComponent ? inputComponent : (
          <input className={styles.input} {...{
            ...inputProps,
            type: inputProps?.type === "password" && showPassword ? "text" : inputProps?.type,
            readOnly: readOnly || disabled || inputProps?.readOnly || false,
          }}  />
        )}

        {inputProps?.type === "password" && (
          <div
            className={styles.iconBtnRight}
            onClick={() => setShowPassword(!showPassword)}
          >
            <IconEye
              style={{
                opacity: showPassword ? 1 : 0.5,
              }}
            />
          </div>
        )}
      </div>

    </div>
  )
}