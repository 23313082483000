import { Oval } from 'react-loader-spinner';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import styles from './ResultsTrainSelectionPage.module.scss';
import layoutStyles from '../../../CurrentMission.selfcare.module.scss';

import React, { useState } from 'react';
import SelectedTrain from '../SelectedTrain/SelectedTrain';
import TrainChoiceItem from '../TrainChoiceItem/TrainChoiceItem';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { format } from 'date-fns';
import FilterButton from '@components/FilterButton/FilterButton';
import useFilterTrainOffers from 'src/Pages/_Components/Filter/FilterV3/FilterTrainOffers/useFilterTrainOffers';
import FilterModal from 'src/Pages/_Components/Filter/FilterV3/FilterModal/FilterModal';
import { Calendar } from '@assets/icons/icons';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import { useSort } from 'src/Pages/_Components/Filter/Sort/useSort';
import Button from '@components/Button/Button';
import { TrainProposalType } from 'src/Stores/CurrentMissionTypes/Types';
import { useTranslation } from 'react-i18next';

export default function ResultsTrainSelectionPageSelfCare({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const { mission, missionContext, callAction } = useCurrentMission();
  const searchTrainStep = missionContext?.steps.searchTrain;
  const stepIsReadOnly = false;

  const {
    sortType,
    setSortType,
    sortedItems: searchTrainProposals,
  } = useSort<"fromDate" | "arrivalDate" | "price", TrainProposalType>({
    items: searchTrainStep?.proposals || [],
    getSortValue(item, sortType) {
      if (sortType === "price") {
        return item.cabinClasses[0]?.offers[0]?.price.amount || 0;
      }
      if (sortType === "arrivalDate") {
        return new Date(item.destination.datetime);
      }
      if (sortType === "fromDate") {
        return new Date(item.origin.datetime);
      }
      return 0;
    }
  })

  const {
    idsFiltered,
    countNbFiltersApplied,
    onClickButtonFilter,
    modalProps,
    resetFilters,
  } = useFilterTrainOffers(searchTrainProposals, {
    disabledFilters: [
      'flexibilities'
    ],
    filterStoreKey: mission?.id
  });

  const [selectedProposalCabinClass, setSelectedProposalCabinClass] = useState<{
    proposalIndex: number;
    cabinClassIndex: number;
    offerIndex: number;
  } | null>(null);

  if (!searchTrainStep || !missionContext?.steps?.searchTrain) {
    return null;
  }

  const dateTime = new Date(searchTrainStep.date);

  const isOfferSelected = selectedProposalCabinClass !== null;

  const fetchNext = async () => {
    if (!searchTrainStep.getNextTrainsAction) {
      return;
    }
    setLoading(true);
    try {
      await callAction(searchTrainStep.getNextTrainsAction);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }


  let title = t('train_selection_title');
  if (isOfferSelected) {
    const proposal = searchTrainProposals[selectedProposalCabinClass.proposalIndex];
    title = `Train ${proposal.origin.name} - ${proposal.destination.name}`;
  }

  return (

    <>

      <FilterModal
        {...modalProps}
        sortTypes={[
          {
            key: "fromDate",
            label: t('label_from_date'),
          },
          {
            key: "arrivalDate",
            label: t('label_arrival_date'),
          },
          {
            key: "price",
            label: t('label_price'),
          },
        ]}
        displayTags={false}
        sortTypeSelected={sortType}
        onChangeSort={sortKey => {
          setSortType(sortKey as "fromDate" | "arrivalDate" | "price" | null);
          setSelectedProposalCabinClass(null);
        }}
      />

      <PageContainer
        containerProps={{
          ...containerProps,
          className: [layoutStyles.container, styles.container, className].join(" "),
        }}
      >

        <HeaderSelfCare
          displayMissionProgressBar
          title={title}
          displayBackButton
          onBackButtonClick={isOfferSelected ? () => {
            setSelectedProposalCabinClass(null);
          }: undefined}
        />

        <div data-testid="step-searchTrain" className={[styles.content].join(" ")}>

          {isOfferSelected && (
            <SelectedTrain
              isLoading={loading}
              readOnly={stepIsReadOnly}
              offerIndex={selectedProposalCabinClass.offerIndex}
              cabinClassIndex={selectedProposalCabinClass.cabinClassIndex}
              displayAllCabinClassOffers={true}
              proposal={{
                ...searchTrainProposals[selectedProposalCabinClass.proposalIndex],
                cabinClasses: searchTrainProposals[selectedProposalCabinClass.proposalIndex].cabinClasses.map((cabinClass) => {
                  if (!cabinClass) {
                    return null;
                  }
                  return {
                    ...cabinClass,
                    offers: cabinClass.offers.filter((offer) => {
                      const offerId = offer.id;
                      return idsFiltered[offerId];
                    }),
                  }
                }).filter((cabinClass) => cabinClass !== null)
              }}
              onClickOffer={(offerIndex, cabinClassIndex) => {
                setSelectedProposalCabinClass({
                  ...selectedProposalCabinClass,
                  cabinClassIndex,
                  offerIndex,
                });
              }}
            />
          )}

          {!isOfferSelected && (
            <>
            <div className={styles.trainListHeader}>
              <div className={styles.date}>{format(dateTime, "PPPP")}</div>
              <FilterButton
                countNbFiltersApplied={countNbFiltersApplied}
                onClick={onClickButtonFilter}
                containerProps={{
                  className: styles.filterButton,
                }}
                layout='outlined'
              />
            </div>

            <div className={styles.trainList}>
              {!searchTrainProposals.some((proposal) => {
                return proposal.cabinClasses.some((cabinClass) => {
                  return cabinClass?.offers.some((offer) => {
                    const offerId = offer.id;
                    return idsFiltered[offerId];
                  });
                });
              }) && (
                <>
                  <div className={styles.noResults}>
                    {t('no_results_explanation')}
                  </div>
                  <Button
                    type="secondary"
                    label={t('reset_filters_button_label')}
                    containerProps={{
                      className: styles.resetFiltersButton,
                    }}
                    onClick={() => {
                      resetFilters();
                    }}
                  />
                </>
              )}


              {searchTrainProposals.map((proposal, i) => {

                const hasAnyOfferThatMatchFilter = proposal.cabinClasses.some((cabinClass) => {
                  return cabinClass?.offers.some((offer) => {
                    const offerId = offer.id;
                    return idsFiltered[offerId];
                  });
                });

                if (!hasAnyOfferThatMatchFilter) {
                  return (
                    null
                  );
                }

                let headerDate = null;
                if (searchTrainProposals[i - 1]) {
                  const previousDate = new Date(searchTrainProposals[i - 1].origin.datetime);
                  const currentDate = new Date(proposal.origin.datetime);

                  // If date are not the same day, display the date
                  if (currentDate.getDate() !== previousDate.getDate()) {
                    headerDate = (
                      <div className={`${styles.trainListHeader} ${styles.nextDay}`}>
                        <div className={`${styles.date} ${styles.dateHighlight}`}>
                          <Calendar height={18} width={18} />
                          {format(currentDate, "PPPP")}
                        </div>
                      </div>
                    )
                  }
                }

                return (
                  <React.Fragment key={i}>
                    {headerDate}
                    <TrainChoiceItem
                      containerProps={{
                        className: styles.trainChoiceItem,
                      }}
                      selectedCabinClassIndex={null}
                      onClick={(cabinClassIndex) => {
                        setSelectedProposalCabinClass({
                          proposalIndex: i,
                          cabinClassIndex,
                          offerIndex: 0,
                        });
                      }}
                      proposal={{
                        ...proposal,
                        cabinClasses: proposal.cabinClasses.map((cabinClass) => {
                          if (!cabinClass) {
                            return null;
                          }
                          return {
                            ...cabinClass,
                            offers: cabinClass.offers.filter((offer) => {
                              const offerId = offer.id;
                              return idsFiltered[offerId];
                            }),
                          }
                        }).filter((cabinClass) => cabinClass !== null)
                      }}
                      cabinClassesIndexToHide={proposal.cabinClasses.map((cabinClass, i) => {
                        const hasAnyOfferThatMatchFilter = cabinClass?.offers.some((offer) => {
                          const offerId = offer.id;
                          return idsFiltered[offerId];
                        });
                        return hasAnyOfferThatMatchFilter ? i : null;
                      }).filter((i) => i !== null) as Array<number>}
                    />
                  </React.Fragment>
                )
              })}

              {searchTrainStep.getNextTrainsAction && (
                <div onClick={async () => {
                  if (loading) {
                    return;
                  }
                  await fetchNext();
                  setSortType(null);
                }} className={styles.displayMoreButton}>
                  <span>{t('display_more_button_label')}</span>
                  {loading && (<Oval
                    height={20}
                    width={20}
                    color="black"
                    secondaryColor="white"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />)}

                </div>
              )}
            </div>
          </>
          )}



        </div>
      </PageContainer>

    </>
  )
}