import { CustomTranslations, IntlProvider } from "@ory/elements";
import { RouterProvider } from "react-router-dom";
import router from "./routes/router";
import { customTranslations } from "./Providers/ory/customTranslations";
import i18n from "./Locales/i18n";

import "@ory/elements/assets/normalize.css"
import "@ory/elements/assets/fa-brands.min.css"
import "@ory/elements/assets/fa-solid.min.css"
import "@ory/elements/assets/fontawesome.min.css"
import "@ory/elements/assets/inter-font.css"
import "@ory/elements/assets/jetbrains-mono-font.css"
import "@ory/elements/style.css"

export default function App() {
  const language: any = i18n.language;

  return (
    <IntlProvider<CustomTranslations>
        locale={language.split("-")[0]}
        defaultLocale="fr"
        customTranslations={customTranslations}>
      <RouterProvider router={router} />
    </IntlProvider>
  )
}