// import { MapUnderWrapper } from '@components/GoogleMap/Map';
import { MapUnderWrapper, MapWrapper } from "@components/GoogleMap/Map";
import OriginPointMarker from "@components/GoogleMap/OriginPointMarker/OriginPointMarker";
import { useGeolocation } from "@uidotdev/usehooks";
// import styles from './Geoloc.module.scss';
import { useEffect, useState } from 'react';
import { useTimeout } from "usehooks-ts";

const TIMEOUT = 5000;
export default function Geoloc({
  onError,
  onLocationChange,
  containerProps: { className, ...containerProps } = {},
}: {
  onError?: (error: GeolocationPositionError) => void;
  onLocationChange?: (position: { latitude: number; longitude: number; }) => void;
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {

  const [enablePoint, setEnablePoint] = useState(false);
  const geolocation = useGeolocation({
    enableHighAccuracy: true,
    timeout: TIMEOUT,
  })

  useEffect(() => {
    if (geolocation.loading) {
      return;
    }

    if (geolocation.error) {
      onError?.(geolocation.error);
      return;
    }
  }, [geolocation, onError]);

  useTimeout(() => {
    if (!geolocation.latitude || !geolocation.longitude) {
      onError?.({
        code: 3,
        message: "Timeout",
        PERMISSION_DENIED: 1,
        POSITION_UNAVAILABLE: 2,
        TIMEOUT: 3,
      });
    }
  }, TIMEOUT);

  useTimeout(() => {
    setEnablePoint(true);
  }, 300);

  useEffect(() => {
    if (geolocation.latitude && geolocation.longitude) {
      onLocationChange?.({
        latitude: geolocation.latitude,
        longitude: geolocation.longitude,
      });
    }
  }, [geolocation, onLocationChange]);

  if (geolocation.loading) {
    return null;
  }

  if (geolocation.error) {
    return null;
  }

  if (!geolocation.latitude || !geolocation.longitude) {
    return null;
  }

  return (
    <div className={[className].join(" ")} {...containerProps}>
      <MapWrapper>
        <MapUnderWrapper
          center={{
            lat: geolocation.latitude,
            lng: geolocation.longitude
          }}
          zoom={15}
          style={{ height: "100%", width: "100%" }}
        >
          {enablePoint && (<OriginPointMarker
            position={{
              lat: geolocation.latitude,
              lng: geolocation.longitude
            }}
          />)}
        </MapUnderWrapper>
      </MapWrapper>
    </div>
  )
}