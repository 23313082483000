import { CheckNotValid, CheckValid, IconEuroSign } from '@assets/icons/icons';
import styles from './WarrantiesList.module.scss';
import React from 'react';
import StringWithBr from '@components/StringWithBr/StringWithBr';
import { isSelfCare } from 'src/Stores/AppContext';
import { GuaranteeDetailsServiceType } from 'src/Stores/CurrentMissionTypes/GuaranteeDetailsServiceType';
import { useTranslation } from 'react-i18next';


export type WarrantiesListTableProps = {
  warranties: GuaranteeDetailsServiceType[];
  displayHeader?: boolean;
  layout?: "default" | "envelope" | "wide" | "selfcare"
  valueType?: "base" | "remaining";
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}

export default function WarrantiesList({
  warranties,
  layout = "default",
  displayHeader = false,
  valueType = "base",
  containerProps: { className, ...containerProps } = {},
}: WarrantiesListTableProps) {

  const { t } = useTranslation();

  return (
    <table className={[styles.container, styles[`layout_${layout}`], className].join(" ")} {...containerProps}>

      {displayHeader && (
        <thead>
          <tr className={styles.header}>
            <th className={styles.col}>
              {t('prestation')}
            </th>
            <th className={styles.icon} />
            {layout !== "envelope" && layout !== "selfcare" && (
            <th className={styles.col}>
              {valueType === "remaining" && t('rest')}
              {valueType === "base" && t('base')}
            </th>
            )}
            {layout === 'wide' && (
              <th className={styles.col}>
              {valueType === "remaining" && t('base')}
              {valueType === "base" && t('rest')}
              </th>
            )}
          </tr>
        </thead>
      )}

      <tbody>
      {warranties.map((warranty, index) => (
        <tr className={styles.row} key={index}>
          <td className={styles.col}>
            <div className={styles.iconLabelContainer}>
              <img src={warranty.icon} height="18" width="18"/>
              <div className={styles.labelContainer}>
                <div>{warranty.label}</div>
                {isSelfCare() && warranty.included && (
                  <div className={styles.underConditions}>
                    Sous conditions
                  </div>
                )}
              </div>
            </div>
          </td>

          <td className={styles.icon}>
            {warranty.included ? (
              <CheckValid />
              ) : (
              isSelfCare() ? <IconEuroSign /> : <CheckNotValid />
            )}
          </td>

          {layout !== "envelope" && layout !== "selfcare" && (
          <td className={styles.col}>
            {valueType === "base" && <StringWithBr text={warranty.initialDescriptions} />}
            {valueType === "remaining" && <StringWithBr text={warranty.remainingDescriptions} />}
          </td>
          )}

          {layout === 'wide' && (
            <td className={styles.col}>
              {valueType === "base" && <StringWithBr text={warranty.remainingDescriptions} />}
              {valueType === "remaining" && <StringWithBr text={warranty.initialDescriptions} />}
            </td>
          )}

        </tr>
      ))}
      </tbody>
    </table>
  )
}