

export type PlaceType = {
  id: string;
  latitude: number;
  longitude: number;
  name: string;
  city?: string;
};

export type SuggestionPlaceType = {
  id: string;
  name: string;
  sessionToken?: string;
  isCurrentLocation?: boolean;
  latitude?: number;
  longitude?: number;
};

export function isSuggestionPlaceType(place: any): place is SuggestionPlaceType {
  return (place as SuggestionPlaceType).id !== undefined && (place as SuggestionPlaceType).name !== undefined;
}

export function isSuggestionsPlaceCurrentLocation(place: any): place is SuggestionPlaceType & { isCurrentLocation: true } {
  return place.isCurrentLocation !== undefined && place.isCurrentLocation;
}