import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

export type MarkerProps = {
  map?: google.maps.Map | undefined;
  position: google.maps.LatLngLiteral;
}

export function PropsAreCustomMarkerCompatible(props: any) {
  return props.position && props.position.lat && props.position.lng;
}

const CustomMarker = ({
  map,
  position,
  anchorPoint = 'bottom',
  children
}: MarkerProps & {
  anchorPoint?: 'bottom' | 'top' | 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right' | 'center';
  children: React.ReactNode;
}) => {

  const [_markerStyleChanged, setMarkerStyleChanged] = useState<number>(0);
  const markerRef = useRef<any>();

  const positionMemo = useMemo(() => {
    return {
      lat: position.lat,
      lng: position.lng,
    }
  }, [position.lat, position.lng]);

  useEffect(() => {

    if (!markerRef.current) {
      markerRef.current = document.createElement('div');
    }

    if (!map || !markerRef.current) return;

    const overlay = new google.maps.OverlayView();

    overlay.onAdd = function() {

      if (markerRef?.current?.style) {
        markerRef.current.style.position = 'absolute';
      }

      overlay.draw = function() {
        if (!markerRef.current) {
          return;
        }
        const point = this.getProjection().fromLatLngToDivPixel(positionMemo);
        if (point) {
          switch (anchorPoint) {
            case 'bottom':
              markerRef.current.style.left = (point.x - markerRef.current.offsetWidth/2) + 'px';
              markerRef.current.style.top = (point.y - markerRef.current.offsetHeight) + 'px';
              break;
            case 'top':
              markerRef.current.style.left = (point.x - markerRef.current.offsetWidth/2) + 'px';
              markerRef.current.style.top = (point.y) + 'px';
              break;
            case 'bottom-left':
              markerRef.current.style.left = (point.x - markerRef.current.offsetWidth) + 'px';
              markerRef.current.style.top = (point.y - markerRef.current.offsetHeight) + 'px';
              break;
            case 'bottom-right':
              markerRef.current.style.left = (point.x) + 'px';
              markerRef.current.style.top = (point.y - markerRef.current.offsetHeight) + 'px';
              break;
            case 'top-left':
              markerRef.current.style.left = (point.x - markerRef.current.offsetWidth) + 'px';
              markerRef.current.style.top = (point.y) + 'px';
              break;
            case 'top-right':
              markerRef.current.style.left = (point.x) + 'px';
              markerRef.current.style.top = (point.y) + 'px';
              break;
            case 'center':
              markerRef.current.style.left = (point.x - markerRef.current.offsetWidth/2) + 'px';
              markerRef.current.style.top = (point.y - markerRef.current.offsetHeight/2) + 'px';
              break;
          }
          setMarkerStyleChanged(markerStyleChanged => markerStyleChanged + 1);
        }
      };


      const panes = this.getPanes();
      panes?.overlayMouseTarget.appendChild(markerRef.current);

      setMarkerStyleChanged(markerStyleChanged => markerStyleChanged + 1);
    };

    overlay.setMap(map);

    return () => {
      if (markerRef.current) {
        markerRef.current = undefined;
      }
    };
  }, [map, positionMemo, anchorPoint]);

  if (!markerRef.current) return null;

  return createPortal(children, markerRef.current);
};

export default CustomMarker;