import React from 'react';
import styles from './Steps.module.scss';
import { Cab, Car, CheckValid, IconEuroSign, IconLight, Train, Warranty } from '@assets/icons/icons';
import { useTranslation } from 'react-i18next';

export default function StepOffers({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {
  const { t } = useTranslation();

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>

      <div className={styles.text}>
        {t('discover_transport_offers')}
      </div>

      <div className={styles.offersExample}>
        <div className={styles.text}>
          {t('additional_costs_warning')}
        </div>

        <div className={styles.offerExample}>
          <Train />
          <div className={styles.offerInfos}>
            <div className={styles.offerName}>{t('train_offer')}</div>
            <div>{t('conditional_offer')}</div>
          </div>
          <CheckValid />
        </div>

        <div className={styles.separator} />

        <div className={styles.offerExample}>
          <Car />
          <div className={styles.offerInfos}>
            <div className={styles.offerName}>{t('rental_car_offer')}</div>
            <div>{t('conditional_offer')}</div>
          </div>
          <CheckValid />
        </div>

        <div className={styles.separator} />

        <div className={styles.offerExample}>
          <Cab />
          <div className={styles.offerInfos}>
            <div className={styles.offerName}>{t('taxi_offer')}</div>
            <div>{t('conditional_offer')}</div>
          </div>
          <IconEuroSign />
        </div>
      </div>

      <div className={styles.offerInfosAdvantages}>
        <IconLight />
        <div className={styles.offerInfosAdvantagesText}>
          {t('consult_advantages_instruction')}
        </div>
        <Warranty />
      </div>

    </div>
  )
}