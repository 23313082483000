import styles from './Welcome.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import selcare_welcome_person from '@assets/img/selfcare_welcome_person.svg';
import selcare_welcome_phone from '@assets/img/selfcare_welcome_phone.svg';
import Button from '@components/Button/Button';
import { useNavigate } from 'react-router-dom';
import OnboardingButton from '../Components/OnBoarding/OnboardingButton';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { Warranty } from '@assets/icons/icons';
import { useTranslation } from 'react-i18next';

export default function WelcomeSelfCare() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mission } = useCurrentMission();

  if (!mission) {
    throw new Error("Mission not found")
  }

  return (
    <PageContainer
      containerProps={{
        className: [styles.container, 'selfcare'].join(" "),
      }}
    >
      <HeaderSelfCare
        displayMissionProgressBar
        forceProgressBarPercent={10}
      />
      <div className={styles.content}>
        <div className={styles.title}>
          {t('welcome_title', { firstName: mission?.clientFirstname, lastName: mission?.clientLastname })}
        </div>
        <p>
          {t('vehicle_status', { licensePlate: mission?.licensePlate })}
        </p>
        <p>
          <b>{t('find_solution')}</b>
        </p>
        <div className={styles.imgInfoContainer}>
          <img src={selcare_welcome_person} alt={t('illustration_breakdown_alt')} />
          <img src={selcare_welcome_phone} alt={t('illustration_phone_alt')} />
        </div>
        <div className={styles.actions}>
          <Button
            label={t('start_button')}
            onClick={() => navigate("mission")}
            type="primary"
            containerProps={{
              className: styles.actionButton,
            }}
          />
          <Button
            onClick={() => navigate("warranties")}
            type="secondary"
            containerProps={{
              className: styles.actionButton,
            }}
          >
            <Warranty />
            <div className={styles.actionButtonLabel}>{t('warranties_button')}</div>
          </Button>
          <OnboardingButton
            label={t('tutorial_button')}
            type="secondary"
            containerProps={{
              className: styles.actionButton,
            }}
          />
        </div>
      </div>
    </PageContainer>
  )
}