import { TextareaHTMLAttributes } from 'react';
import styles from './InputText.module.scss';

export default function Textarea({
  label,
  testid,
  errorMsg,
  textareaProps,
  readOnly,
  containerProps: { className, ...containerProps } = {},
  variant = "default",
  disabled,
  textareaComponent,
}: {
  label?: React.ReactNode;
  testid?: string;
  errorMsg?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  textareaProps?: TextareaHTMLAttributes<HTMLTextAreaElement>;
  readOnly?: boolean;
  variant?: "default" | "outlined";
  disabled?: boolean;
  textareaComponent?: React.ReactNode;
}) {

  return (
    <div data-testid={testid} className={[styles.container, disabled ? styles.disabled : "", styles[`container_${variant}`], className].join(" ")} {...containerProps}>
      {(label || errorMsg) && (
        <div className={styles.labelContainer}>
          {label && <div className={styles.label}>{label}</div>}
          {errorMsg && <div data-testid='errorMsg' className={styles.errorMsg}>{errorMsg}</div>}
        </div>
      )}
      <div className={styles.textareaContainer}>
        {textareaComponent ? textareaComponent : (
          <textarea className={styles.textarea} {...{
            ...textareaProps,
            readOnly: readOnly || disabled || textareaProps?.readOnly || false,
            rows: textareaProps?.rows || 4,  // You can add a default row count
          }} />
        )}
      </div>
    </div>
  )
}