import { useCurrentMission } from 'src/Stores/CurrentMission';
// SELFCARE
import ItinerarySolutions from './Pages/ItinerarySolutions/ItinerarySolutions.selfcare';
import SearchItinerary from './Pages/SearchItinerary/SearchItinerary.selfcare';
import ResultsTrainSelectionPageSelfCare from './Pages/ResultsTrainSelection/SelfCare/ResultsTrainSelectionPage';
import PassengersPage from './Pages/TravelerForm/SelfCare/Passengers';
import ItineraryRecapPage from './Pages/ItineraryRecap/SelfCare/ItineraryRecapPage';
import ItineraryConfirmationPage from './Pages/ItineraryRecap/SelfCare/ItineraryConfirmationPage';
import ResultsVRentalSelection from './Pages/ResultsVRentalSelection/SelfCare/ResultsVRentalSelection';

export default function CurrentMission() {

  const { mission, missionContext } = useCurrentMission();

  if (!mission || !missionContext) {
    return null;
  }

  switch (missionContext.currentStep) {
    case 'search':
      return <SearchItinerary />;
    case 'solutions':
      return (
        <ItinerarySolutions />
      );
    case 'searchTrain':
      return <ResultsTrainSelectionPageSelfCare />;
    case 'searchVrental':
      return (
        <ResultsVRentalSelection />
      );
    case 'travelerForm':
      return (
        <PassengersPage />
      );
    case 'recap':
      return (
        <ItineraryRecapPage />
      );
    case 'confirmation':
    default:
      return (
        <ItineraryConfirmationPage />
      )
  }

}