import { useRef, useState } from "react";
import ExpandableTitle from "./ExpandableTitle";
import ExpandableContent from "./ExpandableContent";


export default function useExpandableTextHelpers({
  title,
  separator = true,
  allClickable = false,
  titleContainerProps,
  contentContainerProps,
  content,
}: {
  title: string;
  allClickable?: boolean;
  separator?: boolean;
  titleContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  contentContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  content: React.ReactNode;
}) {

  const expandedContentRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);

  const titleComponent = (
    <ExpandableTitle
      title={title}
      allClickable={allClickable}
      expanded={expanded}
      toggleExpanded={() => setExpanded(!expanded)}
      containerProps={titleContainerProps}
    />
  )

  const contentComponent = (
    <ExpandableContent
      expanded={expanded}
      expandedContentRef={expandedContentRef}
      separator={separator}
      containerProps={contentContainerProps}
    >{content}</ExpandableContent>
  )

  return {
    expanded,
    titleComponent,
    contentComponent,
    toggleExpanded: () => setExpanded(!expanded),
  };
}