import { IconSearch } from "@assets/icons/icons";
import styles from "./AutocompleteModal.module.scss";
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import { AutocompleteReturnType } from "../useAutocomplete";
import AutocompleteModalSuggestions from "./AutocompleteModalSuggestions";
import { isSuggestionsPlaceCurrentLocation } from "src/models/Place";
import Geoloc from "./Geoloc/Geoloc";
import { useState } from "react";

export default function AutocompleteModal<SuggestionType>({
  placeholder,
  onRequestClose,
  getTextFromSuggestion,
  autocomplete
}: {
  onRequestClose?: () => any;
  placeholder?: string;
  getTextFromSuggestion: (suggestion: SuggestionType) => string;
  autocomplete: AutocompleteReturnType<SuggestionType>;
}) {

  const [currentLocation, setCurrentLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);

  const {
    suggestionsContainerRef,
    value,
    suggestionsIsOpen,
    suggestions,
    selectedSuggestionIndex,
    isLoadingSuggestions,
    onKeyDown,
    onKeyUp,
    onChange,
    onFocus,
    onBlur,
    selectSuggestion,
    setSelectedSuggestionIndex,
    hasCustomFirstSuggestion,
  } = autocomplete;

  const suggestionSelected = selectedSuggestionIndex !== null ? suggestions[selectedSuggestionIndex] : undefined;
  const suggestionSelectedIsCurrentLocation = suggestionSelected && isSuggestionsPlaceCurrentLocation(suggestionSelected);

  const resetSuggestionSelected = () => {
    setSelectedSuggestionIndex(null);
    selectSuggestion(null);
  }

  const onClickSubmit = () => {
    if (suggestionSelectedIsCurrentLocation && currentLocation) {
      suggestionSelected.latitude = currentLocation.latitude;
      suggestionSelected.longitude = currentLocation.longitude;
      selectSuggestion(suggestionSelected);
      onRequestClose && onRequestClose();
      return;
    }
  }

  return (
    <Modal
      disableBodyScroll
      onRequestClose={onRequestClose}
      title="Recherche de localisation"
    >
      <div
        className={[
          styles.modalContainer,
        ].join(" ")}
      >

        <div className={styles.searchAndMapContainer}>
          <div className={styles.inputContainer}>
            <input
              autoFocus
              className={styles.input}
              {...{
                value,
                onChange,
                onKeyDown,
                onKeyUp,
                onFocus,
                onBlur,
                placeholder: placeholder || "Entrez ici votre position",
              }}
              disabled={suggestionSelectedIsCurrentLocation}
            />
            <div className={styles.iconContainer} onClick={suggestionSelectedIsCurrentLocation ? resetSuggestionSelected : () => void 0}>
              {suggestionSelectedIsCurrentLocation ? "X" : <IconSearch />}
            </div>
          </div>

          {suggestionSelectedIsCurrentLocation && (
            <Geoloc
              containerProps={{
                className: styles.mapContainer,
              }}
              onLocationChange={(location) => {
                if (location.latitude !== currentLocation?.latitude || location.longitude !== currentLocation?.longitude) {
                  setCurrentLocation(location);
                }
              }}
              onError={(error) => {
                switch (error.code) {
                  case error.PERMISSION_DENIED:
                    window.alert("Vous avez refusé la géolocalisation");
                    break;
                  case error.POSITION_UNAVAILABLE:
                    window.alert("La géolocalisation est indisponible");
                    break;
                  case error.TIMEOUT:
                    window.alert("La géolocalisation a pris trop de temps");
                    break;
                  default:
                    window.alert("Une erreur inconnue est survenue");
                    break;
                }
                resetSuggestionSelected();
              }}

            />
          )}

          {!suggestionSelectedIsCurrentLocation && (
          <AutocompleteModalSuggestions<SuggestionType>
            hasCustomFirstSuggestion={hasCustomFirstSuggestion}
            suggestionsContainerRef={suggestionsContainerRef}
            suggestionsIsOpen={suggestionsIsOpen}
            isLoadingSuggestions={isLoadingSuggestions}
            suggestions={suggestions}
            selectedSuggestionIndex={selectedSuggestionIndex}
            setSelectedSuggestionIndex={setSelectedSuggestionIndex}
            selectSuggestion={selectSuggestion}
            getTextFromSuggestion={getTextFromSuggestion}
            onClickSuggestion={(suggestion) => {
              if (isSuggestionsPlaceCurrentLocation(suggestion)) {
                return;
              }
              onRequestClose && onRequestClose();
            }}
          />
          )}

        </div>

        <div className={styles.bottomBtnContainer}>
          <Button
            label="Valider"
            containerProps={{
              className: 'w-full'
            }}
            onClick={onClickSubmit}
          />
        </div>
      </div>
    </Modal>
  );
}
