import { useTranslation } from 'react-i18next';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import styles from './CreateCasePage.module.scss';
import InputText from '@components/Inputs/InputText/InputText';
import Button from '@components/Button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import stylesForm from '../_Components/Form.module.scss';
import { Controller, useForm } from "react-hook-form";
import { authenticatedApi } from 'src/Api/api';
import PhoneNumberInput from '@components/PhoneNumberInput/PhoneNumberInput';
import usePhoneNumberFormat from '@components/PhoneNumberInput/usePhoneNumberFormat';
import HeaderV2 from 'src/layout/Header/V2/HeaderV2';

type FormData = {
  fileId: string;
  clientLastname: string;
  clientFirstname: string;
  clientPhone: string;
  licensePlate: string;
};

export default function CreateCasePage({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: {
      errors,
    },
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      fileId: state?.caseId || "",
      clientLastname: "",
      clientFirstname: "",
      clientPhone: state?.phoneNumber || "fr +33",
      licensePlate: state?.licensePlate || "",
    }
  });

  const phoneNumber = watch("clientPhone");
  const { numberOfDigits: phoneNumberCountDigits, regexp: phoneNumberRegexp } = usePhoneNumberFormat(phoneNumber);

  const onSubmit = async (data: FormData) => {
    if (!data.fileId) {
      return false;
    }

    const submitAction: {
      href: string;
      method: string;
    } = {
      href: `/api/v1/cases/${data.fileId}/init`,
      method: "POST",
    }

    const response = await authenticatedApi.request({
      url: submitAction.href,
      method: submitAction.method,
      data: {
        clientFirstname: data.clientFirstname,
        clientLastname: data.clientLastname,
        clientPhone: data.clientPhone,
        licensePlate: data.licensePlate,
      }
    });

    if (response?.status === 200) {
      navigate("/cases/" + response.data.case.id, {
        state: {
          getCaseRequestResponse: response.data
        }
      });
    }

    return false;
  }

  const firstNameLastNameRegex = new RegExp("^[A-Za-zÀ-ÖØ-öø-ÿ- ]+$");

  return (
    <PageContainer
      containerProps={{
        ...containerProps,
        className: [styles.container, className].join(" "),
      }}
    >

      <HeaderV2 />

      <div className={styles.content}>
        <div className={styles.formContainer} style={{ minWidth: "500px" }}>
          <div className={styles.formTitle}>{t('create_case')}</div>

          <div className={[styles.formDescription, styles.formDescWarning].join(" ")}></div>

          <form className={styles.form} onSubmit={handleSubmit(onSubmit)} data-testid='form-create-case'>

            <div className={`${stylesForm.formRow} ${styles.formRow}`}>
              <InputText
                testid='input-file-id'
                label={t('file_number')}
                errorMsg={errors.fileId?.message}
                inputProps={{
                  type: "text",
                  placeholder: t('enter_file_number'),
                  ...register("fileId", {
                    required: t('file_number_required'),
                    maxLength: {
                      value: 11,
                      message: t('file_number_max_length')
                    },
                    minLength: {
                      value: 11,
                      message: t('file_number_max_length')
                    }
                  })
                }}
                containerProps={{
                  className: "w-full",
                }}
              />
            </div>

            <div className={`${stylesForm.formRow} ${styles.formRow}`}>
              <InputText
                testid='input-lastname'
                label={t('lastname')}
                errorMsg={errors.clientLastname?.message}
                inputProps={{
                  type: "text",
                  placeholder: t('enter_lastname'),
                  ...register("clientLastname", {
                    required: t('lastname_required'),
                    pattern: {
                      value: firstNameLastNameRegex,
                      message: t('lastname_no_numbers')
                    },
                    onChange: (e) => {
                      if (!firstNameLastNameRegex.test(e.target.value)) {
                        e.target.value = e.target.value.slice(0, -1);
                      }
                    }
                  })
                }}
                containerProps={{
                  className: "w-full",
                }}
              />
            </div>

            <div className={`${stylesForm.formRow} ${styles.formRow}`}>
              <InputText
                testid='input-firstname'
                label={t('firstname')}
                errorMsg={errors.clientFirstname?.message}
                inputProps={{
                  type: "text",
                  placeholder: t('enter_firstname'),
                  ...register("clientFirstname", {
                    required: t('firstname_required'),
                    pattern: {
                      value: firstNameLastNameRegex,
                      message: t('firstname_no_numbers')
                    },
                    onChange: (e) => {
                      if (!firstNameLastNameRegex.test(e.target.value)) {
                        e.target.value = e.target.value.slice(0, -1);
                      }
                    }
                  })
                }}
                containerProps={{
                  className: "w-full",
                }}
              />
            </div>

            <Controller
              name="clientPhone"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('phone_number_required')
                },
                pattern: (phoneNumberRegexp) ? {
                  value: new RegExp(phoneNumberRegexp),
                  message: phoneNumberCountDigits ? t('phone_number_digit_count', { count: phoneNumberCountDigits }) : t('phone_number_invalid_pattern')
                } : undefined,
              }}
              render={({ field }) => (
                <>
                  <div className={`${stylesForm.formRow} ${styles.formRow}`}>
                    <PhoneNumberInput
                      testid='input-phone-number'
                      label={t('phone_number')}
                      errorMsg={errors.clientPhone?.message}
                      containerProps={{
                        className: "w-full",
                      }}
                      value={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                    />
                  </div>
                </>
              )}
            />

            <div className={`${stylesForm.formRow} ${styles.formRow}`}>
              <InputText
                testid='input-license-plate'
                label={(
                  <div>
                    {t('license_plate')}
                  </div>
                )}
                errorMsg={errors.licensePlate?.message}
                inputProps={{
                  type: "text",
                  placeholder: t('enter_license_plate'),
                  ...register("licensePlate", {
                    required: t('license_plate_required'),
                    onChange: (e) => {
                      e.target.value =  e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
                    }
                  })
                }}
                containerProps={{
                  className: "w-full",
                }}
              />
            </div>

            <Button
              label={t('submit')}
              testid='button-submit-create-case'
              containerProps={{
                className: styles.button,
                type: "submit",
              }}
            />
            <Button
              label={t('cancel')}
              type='secondary'
              testid='button-cancel-create-case'
              containerProps={{
                className: `${styles.button} ${styles.buttonCancel}`,
                type: "button",
              }}
              onClick={() => {
                navigate("/");
              }}
            />
          </form>

        </div>
      </div>
    </PageContainer>
  )
}