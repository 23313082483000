import { SolutionType } from "src/Stores/CurrentMissionTypes/MissionContextType";

export type SolutionPoint = {
  lat: number;
  lng: number;
  nbOccurences: number;
  solutionsIds: Array<string>;
  countMainTransportations: {
    [key: string]: {
      mainTransportationLabel: string;
      count: number;
    };
  };
  label: string;
  infoLayer: {
    label: string;
    content: React.ReactNode;
  };
};

export function getPointsFromSolutions(solutions: Array<SolutionType>): Array<SolutionPoint> {

  const points: { [key: string]: SolutionPoint } = {};

  for (const solution of solutions) {
    const key = `${solution.mainOrigin.latitude},${solution.mainOrigin.longitude}`;
    if (!points[key]) {
      points[key] = {
        lat: solution.mainOrigin.latitude,
        lng: solution.mainOrigin.longitude,
        nbOccurences: 0,
        countMainTransportations: {},
        label: "",
        infoLayer: {
          label: solution.mainOrigin.name,
          content: ""
        },
        solutionsIds: []
      };
    }

    points[key].nbOccurences += (solution.itemsCount || 0);
    points[key].label = `${points[key].nbOccurences}`;
    points[key].solutionsIds.push(solution.id);

    if (solution.mainTransportType.id) {
      const countMainTransportation = points[key].countMainTransportations[solution.mainTransportType.id] || {
        count: 0,
      };
      points[key].countMainTransportations[solution.mainTransportType.id] = {
        ...countMainTransportation,
        mainTransportationLabel: solution.mainTransportType.label,
        count: countMainTransportation.count + (solution.itemsCount || 0)
      };
    }
  }

  return Object.values(points);
}