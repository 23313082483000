import styles from './InfoLayer.module.scss';

export default function InfoLayer({
  title,
  children,
  containerProps: { className, ...containerProps } = {},
}: {
  title?: string;
  children?: React.ReactNode;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>
      {title && (
      <div className={styles.header}>
        {title}
      </div>
      )}
      <div className={styles.contentContainer}>
        {children}
      </div>
    </div>
  )
}