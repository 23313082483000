import rawCountries from './rawCountries.js';

export function getPhoneNumberFormat(rawValue: string) {
  const [selectedCountryCode] = rawValue ? rawValue.split(" ") : [null, null];

  const selectedCountry = rawCountries.find((country) => {
    return country[2] === selectedCountryCode;
  }) || null;

  const mask: string|null = (selectedCountry && selectedCountry.length > 4) ? selectedCountry[4] as string : null;

  let regexp = "^(.+) (.+) ([0-9]+)$";
  let numberOfDigits: number | null = null;
  if (mask) {
    numberOfDigits = mask.split(".").length - 1;
    regexp = `^(.+) (.+) ([0-9]{${numberOfDigits}})$`
  }

  return {
    mask,
    numberOfDigits,
    regexp,
  }
}

export default function usePhoneNumberFormat(rawValue: string) {
  return getPhoneNumberFormat(rawValue);
}
