import { create } from 'zustand';
import { CrudMetadata, Entity } from './types';

export const useCrudMetadata = create<{
  entity: Entity | undefined;
  metadata: CrudMetadata | null;
  setEntity: (entity: Entity) => void;
  setMetadata: (metadata: CrudMetadata | null) => void;
}>((set) => ({
  entity: undefined,
  metadata: null,
  setEntity: (entity) => set({ entity }),
  setMetadata: (metadata) => set({ metadata }),
}));

