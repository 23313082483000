import { useEffect, useMemo, useRef } from "react";

export default function Circle({
  map,
  center,
  radius,
}: {
  map?: google.maps.Map;
  center: google.maps.LatLngLiteral;
  radius: number;
}) {
  const circleRef = useRef<google.maps.Circle | null>(null);

  const positionMemo = useMemo(() => {
    return {
      lat: center.lat,
      lng: center.lng,
    }
  }, [center.lat, center.lng]);

  useEffect(() => {
    if (!map) {
      return;
    }

    if (!circleRef.current) {
      circleRef.current = new google.maps.Circle({
        strokeColor: "#007ab3",
        strokeOpacity: 0,
        strokeWeight: 1,
        fillColor: "#007ab3",
        fillOpacity: 0.2,
        map: null,
        center: positionMemo,
        radius: radius,
      });
    }

    circleRef.current.setCenter(positionMemo);
    circleRef.current.setRadius(radius);
    circleRef.current.setMap(map);

  }, [positionMemo, radius, map]);

  return null;
}