import { useTranslation } from 'react-i18next';
import styles from './EditTaxiButton.module.scss';
import { ApiAction } from 'src/models/ApiAction';
import { useState } from 'react';
import ModalEditTaxi from './ModalEditTaxi/ModalEditTaxi';

export default function EditTaxiButton({
  rescheduleTaxiLimits,
  rescheduleTaxiAction,
  currentScheduledAt,
}: {
  rescheduleTaxiLimits?: {
    start: string;
    end: string;
  };
  rescheduleTaxiAction: ApiAction;
  currentScheduledAt: string;
}) {

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <a
        href=""
        onClick={(e) => {
          e.preventDefault();
          setIsModalOpen(true);
          return false;
        }}
        className={styles.delete}
      >
        {t('edit_taxi_departure_time')}
      </a>
      {isModalOpen && (
        <ModalEditTaxi
          onRequestClose={() => setIsModalOpen(false)}
          rescheduleTaxiLimits={rescheduleTaxiLimits}
          rescheduleTaxiAction={rescheduleTaxiAction}
          currentScheduledAt={currentScheduledAt}
          onConfirm={() => {
            setIsModalOpen(false);
          }}
        />
      )}
    </>
  )
}