import { RegisterOptions } from "react-hook-form";
import { CardType } from "src/Stores/CurrentMissionTypes/MissionContextType";
import { luhn } from "src/helpers/algorithms";
import { CardErrorType, CardFieldType } from "./types";


// console.log("LUHN TEST", "29090108219020931", luhn("29090108219020931"));
// console.log("LUHN TEST", "29090108219020932", luhn("29090108219020932"));


export function getCardErrors(card: CardFieldType, cardType: CardType): Array<CardErrorType> {
  const errors: Array<{
    type: string;
    message: string;
  }> = [];

  if (!card.cardNumber) {
    // errors.push({
    //   type: 'required',
    //   message: 'Le numéro de carte est obligatoire',
    // });
    return errors;
  }

  if (cardType.validationPrefix && !card.cardNumber.startsWith(cardType.validationPrefix)) {
    errors.push({
      type: 'startsWithPrefix',
      message: 'Numéro de carte invalide',
    });
  }
  if (cardType.validationLength && card.cardNumber.length !== cardType.validationLength) {
    errors.push({
      type: 'validLength',
      message: 'Numéro de carte invalide',
    });
  }
  if (cardType.validationAlgorithm === 'luhn' && !luhn(card.cardNumber)) {
    errors.push({
      type: 'isLuhnValid',
      message: 'Numéro de carte invalide',
    });
  }
  if (cardType.validationRegex && !new RegExp(cardType.validationRegex).test(card.cardNumber)) {
    errors.push({
      type: 'pattern',
      message: 'Numéro de carte invalide',
    });
  }

  return errors;
}

export function getValidationCards(cardType: CardType): RegisterOptions {

    const cardInfos = cardType;
    const {
      validationAlgorithm,
      validationLength,
      validationPrefix,
      validationRegex
    } = cardInfos;

    const rules: RegisterOptions = { validate: {} };

    if (validationPrefix){
      rules.validate = {
        ...rules.validate,
        startsWithPrefix: (value: string) =>
          value.startsWith(validationPrefix) || !value.trim() || 'Numéro de carte invalide',
      }
    }

    if (validationRegex){
      rules.pattern = {
        value: new RegExp(validationRegex),
        message: 'Numéro de carte invalide',
      }
    }

    if (validationLength > 0){
      rules.validate = {...rules.validate,
        validLength: (value: string) =>
          value.length === validationLength || !value.trim() || 'Numéro de carte invalide',
      }
    }

    if (validationAlgorithm === 'luhn' ){
      rules.validate = {
        ...rules.validate,
        isLuhnValid: (value: string) =>
          luhn(value) || 'Numéro de carte invalide',
      }
    }

    return rules;
  }