import { useMemo, useState } from 'react';
import { ItineraryStepProps } from './ItineraryStep';
import { PriceType } from 'src/models/Price';
import { TransportTypes } from 'src/Stores/CurrentMissionTypes/Types';


export type ConnectingTaxiType = {
  indexInSections: number;
  section: ItineraryStepProps;
  option: ItineraryStepProps['options'][0];
  price: PriceType;
};

export default function useConnectingTaxis({
  sections,
  mainTransportType,
  defaultSelected,
}: {
  sections: Array<ItineraryStepProps>,
  mainTransportType: TransportTypes,
  defaultSelected?: boolean,
}) {

  const [selectedConnectingTaxiIndexInSection, setSelectedConnectingTaxiInSection] = useState<number[]>(
    defaultSelected
      ? sections.map((_, i) => i)
      : []
  );

  const connectingTaxis: ConnectingTaxiType[] = useMemo(() => {

    const taxiSections = sections.filter((section) => section.type === "taxi");
    const connectingTaxis: ConnectingTaxiType[] = [];
    if (mainTransportType === "taxi" || taxiSections.length === 0) {
      return connectingTaxis;
    }
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      if (section.type === "taxi") {
        const option = section.options[0];
        if (option) {
          connectingTaxis.push({
            indexInSections: i,
            section,
            option,
            price: option.price,
          });
        }
      }
    }
    return connectingTaxis;
  }, [mainTransportType, sections]);

  const selectedAllSectionsIndex = useMemo(() => {
    return sections.map((section, i) => {
      if (mainTransportType === "taxi") {
        return i;
      }
      if (section.type !== "taxi") {
        return i;
      }
      return selectedConnectingTaxiIndexInSection.includes(i) ? i : null;
    }).filter((i): i is number => i !== null)
  }, [mainTransportType, sections, selectedConnectingTaxiIndexInSection]);

  return {
    connectingTaxis,
    selectedAllSectionsIndex,
    isTaxiSelected: (indexInSections: number) => {
      if (mainTransportType === "taxi") {
        return true;
      }
      return selectedConnectingTaxiIndexInSection.includes(indexInSections);
    },
    toggleSelectTaxiIndex: (indexInSections: number) => {
      if (mainTransportType === "taxi") {
        return;
      }
      setSelectedConnectingTaxiInSection(selectedConnectingTaxiIndexInSection => {
        if (selectedConnectingTaxiIndexInSection.includes(indexInSections)) {
          return selectedConnectingTaxiIndexInSection.filter(i => i !== indexInSections);
        } else {
          return [...selectedConnectingTaxiIndexInSection, indexInSections];
        }
      });
    }
  }
}
