import { MapMarker, MapMarkerInactiv } from '@assets/icons/icons';
import CustomMarker, { MarkerProps } from '../CustomMarker';
import styles from './VRentalPointMarker.module.scss';
import InfoLayer from '../InfoLayer/InfoLayer';

export default function VRentalPointMarker({
  isActiv,
  infoLayer,
  countNbSolutionInRental,
  onClick,
  containerProps: { className, ...containerProps } = {},
  ...markerProps
}: {
  isActiv?: boolean;
  infoLayer?: {
    label: string;
  };
  onClick?: () => void;
  countNbSolutionInRental: number;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
} & MarkerProps) {

  const infoLayerContent = (
    <div>
      <div className={styles.infoLayerContentLine}>
        {countNbSolutionInRental} véhicule{countNbSolutionInRental > 1 ? 's' : ''} disponible{countNbSolutionInRental > 1 ? 's' : ''}
      </div>
    </div>
  )

  return (
    <CustomMarker
      {...markerProps}
    >
      <div className={[styles.container, isActiv ? styles.isActiv : "", className].join(" ")} {...containerProps} onClick={() => {
        onClick && onClick();
      }}>
        {isActiv && (
          <InfoLayer
            containerProps={{
              className: styles.infoLayer
            }}
            title={infoLayer?.label}
          >{infoLayerContent}</InfoLayer>
        )}
        <div className={[styles.label, isActiv ? styles.labelActiv : ""].join(" ")}>{countNbSolutionInRental}</div>
        {isActiv ? <MapMarker fill="#003781" /> : <MapMarkerInactiv />}
      </div>
    </CustomMarker>
  )
}