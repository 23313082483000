import { Chevron, Luggage, Person, SmallLuggage } from '@assets/icons/icons';
import styles from './HeaderMission.module.scss';
import { useRef, useState } from 'react';
import WarrantiesListForm from 'src/Pages/_Components/WarrantiesList/WarrantiesList';
import CurrentHour from './CurrentHour';
import ContractForm from 'src/Pages/_Components/ContractForm/ContractForm';
import Button from '@components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import FromToInfos from './FromToInfos';

export default function HeaderMission({
  view = 'default',
  containerProps: { className, ...containerProps } = {},
}: {
  view?: 'default' | 'search';
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { mission } = useCurrentMission();
  const infosSearch = mission?.searchData;

  return (
    <>
      {isOpen && <div onClick={() => setIsOpen(false)} className={styles.overlay} />}
      <div ref={ref} className={[styles.container, className].join(" ")} {...containerProps}>
        <div className={styles.mainContent}>

          <CurrentHour
            userName={mission ? `${mission.clientFirstname ? (mission.clientFirstname.substring(0, 1) + ". ") : ""}${mission.clientLastname}` : ""}
            containerProps={{
              className: styles.currentHour
            }}
          />

          <FromToInfos />

          {view === 'search' && (
            <>

            <Button
              testid='button-quit-mission'
              label="Quitter"
              type='secondary'
              containerProps={{
                className: styles.button,
              }}
              onClick={() => {
                navigate("/");
              }}
            />
            </>
          )}

          {view === 'default' && infosSearch && (
            <div className={styles.optionsPreview}>
              <div className={styles.option}>
                <Person />
                <div className={styles.optionLabel}>x {infosSearch.passengerCount}</div>
              </div>
              <div className={styles.option}>
                <Luggage />
                <div className={styles.optionLabel}>{infosSearch.bigBaggageCount}</div>
              </div>
              <div className={styles.option}>
                <SmallLuggage />
                <div className={styles.optionLabel}>{infosSearch.smallBaggageCount}</div>
              </div>
            </div>
          )}

          {view === 'default' && (
          <div className={styles.chevron} onClick={() => {
            setIsOpen(!isOpen);
          }}>
            <Chevron
              className={[styles.chevronIcon, isOpen ? styles.chevronIconOpened : ""].join(" ")}
            />
          </div>
          )}
        </div>

        <div className={[styles.expandableContent, isOpen ? styles.expandableContentOpened : ""].join(" ")}>

          <WarrantiesListForm
            containerProps={{
              className: styles.warrantiesList,
            }}
          />

          <div className={styles.contractFormContainer}>
            <ContractForm
              containerProps={{
                className: styles.contractForm,
              }}
            />

            <div className={styles.buttonsContainer}>
              {/* <Button
                label="Modifier la recherche"
                type="secondary"
                containerProps={{
                  className: styles.button,
                  disabled: missionContext?.currentStep === "search"
                }}
                onClick={() => {
                  // TODO changer par une modal designée
                  if (window.confirm("Êtes-vous sûr de vouloir modifier la recherche ?")) {
                    setMissionContext({
                      ...missionContext,
                      currentStep: "search",
                    });
                  }
                }}
              /> */}
              <Button
                label="Quitter"
                type="secondary"
                containerProps={{
                  className: styles.button,
                }}
                onClick={() => {
                  // TODO changer par une modal designée
                  if (window.confirm("Êtes-vous sûr de vouloir quitter la mission ?")) {
                    navigate("/");
                  }
                }}
              />
            </div>

          </div>

        </div>
      </div>
    </>
  )
}