import { FilterTypeMultiCheckboxes, FilterTypeMultiValues, FilterTypeSwitch } from "../types";
import Switch from "@components/Switch/Switch";
import Checkbox from "@components/Checkbox/Checkbox";
import Select from "@components/Select/Select";
import styles from "./FilterModal.module.scss";
import stylesForm from "@components/Form/Form.module.scss";


export default function Filter({
  filter,
}: {
  filter: FilterTypeMultiCheckboxes | FilterTypeMultiValues | FilterTypeSwitch
}) {


  if (!filter) {
    return null;
  }

  switch (filter.type) {
    case 'switch':
      return (
        <div className={stylesForm.formRow}>
          <div className={[stylesForm.formCol].join(" ")}>
            <span className={stylesForm.formLabelNormal}>
              {filter.label}
            </span>
          </div>
          <div className={[stylesForm.formCol].join(" ")}>
            <Switch
              checked={filter.value || false}
              onChange={(checked) => {
                filter.onChange && filter.onChange(checked);
              }}
            />
          </div>
        </div>
      );

    case 'multiselect':
      return (
        <div className={stylesForm.formRow}>
          <div className={[stylesForm.formCol, styles.fullWidth].join(" ")}>
            <Select
              containerProps={{
                className: styles.fullWidth,
              }}
              placeholder={filter.label}
              allowMultiple
              selectedIndexes={filter.options.map((option, index) => {
                if (!filter.values) {
                  return null;
                }
                return (filter.values && filter.values[option.value]) ? index : null;
              }).filter((index) => index !== null) as number[]}
              onChangeIndexes={(selectedIndexes) => {
                const newValues: {
                  [key: string]: boolean;
                } = {};
                selectedIndexes.forEach((index) => {
                  newValues[filter.options[index].value] = true;
                });
                filter.onChange && filter.onChange(newValues);
              }}
              optionsV2={filter.options.map((option) => ({
                value: option.value,
                label: option.label,
                searchValue: option.label,
              }))}
            />
          </div>
        </div>
      );

    case 'multicheckboxes':
      return (
        <div className={stylesForm.formRow}>
          <div className={[stylesForm.formCol, styles.checkboxesContainer].join(" ")}>
            {filter.options.map((option) => (
              <Checkbox
                containerProps={{
                  className: styles.checkbox,
                }}
                key={option.value}
                label={option.label}
                checked={filter.values && filter.values[option.value]}
                onChange={(checked) => {
                  filter.onChange && filter.onChange({
                    ...filter.values,
                    [option.value]: checked,
                  });
                }}
              />
            ))}
          </div>
        </div>
      );
    default:
      return null;
  }
}