import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styles from './SortableItem.module.scss';
import { IconDragHandle } from "@assets/icons/icons";

interface SortableItemProps {
  id: string;
  label: string;
  disabled?: boolean;
}

export const SortableItem: React.FC<SortableItemProps> = ({
  id,
  label,
  disabled,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  // Lock horizontal movement by setting transform.x to 0
  const lockedTransform = transform
    ? {
        ...transform,
        x: 0, // Prevent horizontal movement
      }
    : null;

  const style = {
    transform: CSS.Transform.toString(lockedTransform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={`${styles.sortableItem} ${disabled? styles.disabled : ''}`}
    >
      {!disabled && (<IconDragHandle />)}
      {label}
    </div>
  );
};