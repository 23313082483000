import styles from './BeneficiaryInformations.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import Button from '@components/Button/Button';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import InputText from '@components/Inputs/InputText/InputText';
import { Controller, useForm } from 'react-hook-form';
import {
  isValidIBAN,
  isValidBIC,
} from 'ibantools';
import { RefundFlowType } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import { ScreenType } from '../../ScreensTypes';
import { useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { displayPrice } from 'src/helpers/price';
import { useTranslation } from 'react-i18next';

type FormData = {
  lastname: string;
  firstname: string;
  iban: string;
  bic: string;
};

export default function AddBeneficiaryInformationsStepReceiptSelfCare({
  refundFlow,
  goBack,
}: {
  refundFlow: RefundFlowType;
  navigate: (screen: ScreenType) => void;
  goBack: () => void;
}) {

  const { mission, callAction } = useCurrentMission();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: {
      errors
    }

  } = useForm<FormData>({
    defaultValues: {
      lastname: mission?.clientLastname || "",
      firstname: mission?.clientFirstname || "",
      iban: "",
      bic: "",
    }
  });

  const submit = async (data: FormData) => {
    const actionData = {
      ...data,
      country: data.iban.slice(0, 2),
    };
    if (!refundFlow?.actions?.setBankingInformationAction) {
      return;
    }

    setLoading(true);
    await callAction(refundFlow.actions.setBankingInformationAction, actionData).finally(() => {
      setLoading(false);
    });
  };


  return (
    <PageContainer
      containerProps={{
        className: [styles.container, 'selfcare'].join(" "),
      }}
    >

      <HeaderSelfCare
        title={t('title.refundInformation')}
        displayBackButton
        onBackButtonClick={() => goBack()}
      />


      <form className={styles.content} onSubmit={handleSubmit(submit)}>
        <div className={`flex flex-col justify-center w-full`} style={{ marginTop: "40px" }}>

          <div className={`flex flex-row justify-between align-center`} style={{ marginTop: "8px" }}>
            <div className={styles.labelTitle}>{t('label.service')}</div>
            <div className={styles.valueTitle}>{refundFlow.benefits?.find(b => b.value === refundFlow.selectedBenefit)?.name || ""}</div>
          </div>

          <div className={`flex flex-row justify-between align-center`} style={{ marginTop: "8px" }}>
            <div className={styles.labelTitle}>{t('label.amount')}</div>
            <div className={styles.valueTitle}>{displayPrice(refundFlow.receiptAmount)}</div>
          </div>

          <div className={`flex flex-row justify-between align-center ${styles.labelValueBold}`} style={{ marginTop: "16px" }}>
            <div className={`${styles.labelTitle} ${styles.bold}`}>{t('label.refundedAmount')}</div>
            <div className={`${styles.valueTitle} ${styles.bold}`}>{displayPrice(refundFlow.coveredAmount)}</div>
          </div>

          <div className={`flex flex-row justify-between align-center`} style={{ marginTop: "8px" }}>
            <div className={`${styles.labelTitle} `}>{t('label.remainingCharge')}</div>
            <div className={`${styles.valueTitle} `}>{displayPrice(refundFlow.remainingCosts)}</div>
          </div>

          <div className={`flex flex-row justify-between align-center ${styles.lineConditions}`} style={{ marginTop: "8px" }}>
            {t('condition.refund')}
          </div>
        </div>

        <div className={styles.h2}>{t('section.enterBankInfo')}</div>

        <Controller
          control={control}
          name="lastname"
          rules={{
            required: {
              value: true,
              message: t('error.holderLastNameRequired')
            }
          }}
          render={({ field }) => (
            <InputText
              label={t('label.holderLastName')}
              errorMsg={errors.lastname?.message}
              readOnly={loading}
              disabled={loading}
              inputProps={field}
              containerProps={{
                className: 'w-full',
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="firstname"
          rules={{
            required: {
              value: true,
              message: t('error.holderFirstNameRequired')
            }
          }}
          render={({ field }) => (
            <InputText
              label={t('label.holderFirstName')}
              errorMsg={errors.firstname?.message}
              readOnly={loading}
              disabled={loading}
              inputProps={field}
              containerProps={{
                className: 'w-full',
              }}

            />
          )}
        />

        <Controller
          control={control}
          name="iban"
          rules={{
            required: {
              value: true,
              message: t('error.IBANRequired')
            },
            validate: (value) => {
              if (!isValidIBAN(value)) {
                console.log("IBAN invalide, ex: FR7630006000011234567890189")
                return t('error.IBANInvalid');
              }
              return true;
            }
          }}
          render={({ field }) => (
            <InputText
              label={t('label.IBAN')}
              errorMsg={errors.iban?.message}
              readOnly={loading}
              disabled={loading}
              inputProps={{
                ...field,
                onChange: (e) => {
                  field.onChange(e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, ''));
                }
              }}
              containerProps={{
                className: 'w-full',
              }}
            />
          )}
        />


        <Controller
          control={control}
          name="bic"
          rules={{
            required: {
              value: false,
              message: "le bic est obligatoire"
            },
            validate: (value) => {
              if (!value) {
                return true;
              }
              if (!isValidBIC(value)) {
                return t('error.BICInvalid');
              }
              return true;
            }
          }}
          render={({ field }) => (
            <InputText
              errorMsg={errors.bic?.message}
              readOnly={loading}
              disabled={loading}
              inputProps={{
                ...field,
                placeholder: t('placeholder.BIC'),
                onChange: (e) => {
                  field.onChange(e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, ''));
                }
              }}
              containerProps={{
                className: 'w-full',
              }}
            />
          )}
        />

        <div className={styles.actions}>
          <Button
            label={
              loading ? (
                <Oval
                  color="#fff"
                  width={20}
                  height={20}
                />
              ) : t('button.save')
            }

            onClick={handleSubmit(submit)}
            type="primary"

            containerProps={{
              className: styles.actionButton,
              disabled: loading || !!errors.lastname || !!errors.firstname || !!errors.iban || !!errors.bic
            }}
          />
        </div>

      </form>

    </PageContainer>
  )
}