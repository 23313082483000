import stylesForm from '../../Form.module.scss';
import styles from './styles.module.scss';
import Switch from '@components/Switch/Switch';
import Button from '@components/Button/Button';
import Modal from '@components/Modal/Modal';
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';

type FormData = {
  has_manual_licence: boolean;
  has_bank_card: boolean;
  has_old_licence: boolean;
};

export default function ModalDriver({
  readOnly,
  defaultValues,
  onChange,
  onRequestClose,
  containerProps: { className, ...containerProps } = {},
}: {
  readOnly?: boolean;
  defaultValues: FormData;
  onChange?: (driver: FormData) => any;
  onRequestClose?: () => any;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const { control, handleSubmit } = useForm<FormData>({
    defaultValues
  });
  const { t } = useTranslation();

  const onSubmit = (data: FormData) => {
    onChange && onChange(data);
    onRequestClose && onRequestClose();
  };

  return (
    <Modal
      onRequestClose={onRequestClose}
      title={t('modalDriverTitle')}
    >
      <div className={[stylesForm.form, styles.optionsModalContainer, className].join(" ")} {...containerProps}>

        <div className={stylesForm.formRow}>
          <div className={[stylesForm.formCol].join(" ")}>
            <span className={stylesForm.formLabelNormal}>
              {t('hasOldLicence')}
            </span>
          </div>
          <div className={[stylesForm.formCol].join(" ")}>

          <Controller
              name="has_old_licence"
              control={control}
              defaultValue={true}
              render={({ field }) => (
                <Switch
                  disabled={readOnly}
                  checked={field.value}
                  onChange={(checked) => field.onChange(checked)}
                />
              )}
            />

          </div>
        </div>


        <div className={stylesForm.formRow}>
          <div className={[stylesForm.formCol].join(" ")}>
            <span className={stylesForm.formLabelNormal}>
              {t('hasManualLicence')}
            </span>
          </div>
          <div className={[stylesForm.formCol].join(" ")}>
          <Controller
              name="has_manual_licence"
              control={control}
              defaultValue={true}
              render={({ field }) => (
                <Switch
                  disabled={readOnly}
                  checked={field.value}
                  onChange={(checked) => field.onChange(checked)}
                />
              )}
            />
          </div>
        </div>


        <div className={stylesForm.formRow}>
          <div className={[stylesForm.formCol].join(" ")}>
            <span className={stylesForm.formLabelNormal}>
              {t('hasBankCard')}
            </span>
          </div>
          <div className={[stylesForm.formCol].join(" ")}>
          <Controller
              name="has_bank_card"
              control={control}
              defaultValue={true}
              render={({ field }) => (
                <Switch
                  disabled={readOnly}
                  checked={field.value}
                  onChange={(checked) => field.onChange(checked)}
                />
              )}
            />
          </div>
        </div>

        {readOnly ? (
          <div className={styles.bottomBtnContainer}>
            <Button
              label={t('close')}
              type="secondary"
              containerProps={{
                className: stylesForm.buttonCancel,
              }}
              onClick={() => onRequestClose && onRequestClose()}
            />
          </div>
        ) : (
        <div className={styles.bottomBtnContainer}>
          <Button
            label={t('confirm')}
            containerProps={{
              className: stylesForm.buttonSubmit,
            }}
            onClick={handleSubmit(onSubmit)}
          />

          <Button
            label={t('cancelBtnLabel')}
            type="secondary"
            containerProps={{
              className: stylesForm.buttonCancel,
            }}
            onClick={() => onRequestClose && onRequestClose()}
          />
        </div>
        )}

      </div>
    </Modal>
  )
}