import { useState } from 'react';
import Button, { ButtonPropsType } from '@components/Button/Button';
import OnboardingModal from './OnboardingModal';

export default function OnboardingButton({
  ...buttonProps
}: ButtonPropsType) {

  const [isOpened, setIsOpened] = useState(false)

  return (
    <>

    {isOpened && <OnboardingModal onRequestClose={() => setIsOpened(false)} />}

    <Button
      {...buttonProps}
      onClick={(e) => {
        setIsOpened(true);
        buttonProps.onClick && buttonProps.onClick(e);
      }}
    />
    </>
  )
}