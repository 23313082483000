import InputText from '@components/Inputs/InputText/InputText';
import styles from '../Passengers.module.scss';
import { CardType, FormField } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { luhn } from 'src/helpers/algorithms';
import { isSelfCare } from 'src/Stores/AppContext';
import { useTranslation } from 'react-i18next';

const getValidationCards = (card: Omit<CardType, 'id'>, t: any) => {

  if (!card) return {};

  const {validationAlgorithm, validationLength,validationPrefix, validationRegex} = card;
  const rules : any = {pattern:{}, validate:{}};

  if (validationPrefix){
    rules.validate = {...rules.validate,
      startsWithPrefix: (value: string) =>
        value.startsWith(validationPrefix) || !value.trim() || t('invalid_card_number'),
    }
  }
  if (validationRegex) {
    rules.pattern = {
      value: validationRegex,
      message: t('invalid_card_number'),
    }
  }

  if (validationLength > 0) {
    rules.validate = {...rules.validate,
      validLength: (value: string) =>
        value.length === validationLength || !value.trim() || t('invalid_card_number'),
    }
  }
  if (validationAlgorithm === 'luhn' ) {
    rules.validate = {...rules.validate,
      isLuhnValid: (value: string) =>
        luhn(value) || t('invalid_card_number'),
    }
  }

  return rules;
}

export default function Card({
  inputName: inputNamePassenger,
  field,
  disabled,
  loading,
  errors,
  control,
}: {
  inputName: string;
  field: FormField & { type: 'cards' };
  disabled?: boolean;
  loading?: boolean;
  errors: FieldErrors;
  control: Control<any>;
}) {
  const { t } = useTranslation();

  return (
    <div className={`${styles.passengerCardFormRowContainer} ${isSelfCare() ? 'w-full': ''}`}>
      {Object.keys(field.cards).map((cardKey) => {

        const fieldCard = field.cards[cardKey];
        const inputName = `${inputNamePassenger}_${cardKey}`;
        const errorMsg = errors[inputName]?.message as string || undefined;

        return (
          <div className={`${styles.passengerFormRow} ${isSelfCare() ? 'w-full': ''}`} key={cardKey}>
            <div className={`${styles.passengerFormCol} ${isSelfCare() ? 'w-full': ''}`}>
              <InputText
                readOnly
                label={field.label}
                disabled
                inputProps={{
                  value: fieldCard.label,
                }}
                containerProps={{
                  className: 'w-full'
                }}
              />
            </div>
            <div className={`${styles.passengerFormCol} ${isSelfCare() ? 'mt-3': ''}`}>
              <Controller
                name={inputName}
                control={control}
                rules={{
                  ...getValidationCards(fieldCard, t),
                  required: {
                    value: field.required,
                    message: t("card_number_required")
                  },
                }}
                render={({ field: renderField }) => (
                  <InputText
                    disabled={disabled || loading}
                    label={field.secondaryLabel}
                    errorMsg={errorMsg}
                    containerProps={{
                      className: 'w-full'
                    }}
                    inputProps={{
                      readOnly: loading || disabled,
                      type: "text",
                      placeholder: field.placeholder,
                      defaultValue:
                        field.cards[
                          Object.keys(field.cards)[0]
                        ].value,
                      onChange: (e) => {
                        renderField.onChange(e.target.value);
                      },
                    }}
                  />
                )}
              />
            </div>
          </div>
        )
      })}

    </div>
  )
}