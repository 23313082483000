import React from 'react';
import styles from './PriceIncludedOrRemaining.module.scss';
import { PriceType } from 'src/models/Price';

export default function PriceIncludedOrRemaining({
  remainingCosts,
  containerProps: { className, ...containerProps } = {},
}: {

  remainingCosts?: PriceType;
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, "children">;
}) {

  return (
    <div className={[styles.itineraryPrice, className].join(" ")} {...containerProps}>
      {remainingCosts ? (
        <div className={styles.priceWarning}>
          <div>Reste à charge total</div>
          <div className={styles.price}>{remainingCosts.formatted + " " + remainingCosts.currency.symbol}</div>
        </div>
      ) : (
        <div className={styles.priceInfo}>Inclus</div>
      )}
    </div>
  )
}