import { FilterItem, FilterValueMapping, FiltersType } from "./types";


export function filterItemsWithValues(items: FilterItem[], filters: FiltersType, filterFormValues: FilterValueMapping) {
  const idsFiltered: { [key: string]: boolean } = {};
  items.forEach((item) => {
    idsFiltered[item.filterId] = true;
    for (const filterKey of Object.keys(filters)) {
      const filter = filters[filterKey as keyof typeof filters];
      if (filter.disabled) {
        continue;
      }
      const filterValue = filterFormValues[filterKey as keyof typeof filterFormValues];
      if (!filter.itemMatchesValues(item, filterValue as any)) {
        idsFiltered[item.filterId] = false;
        break;
      }
    }
  });
  return idsFiltered;
}
