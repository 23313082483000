import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import styles from './Passengers.module.scss';
import layoutStyles from '../../../CurrentMission.selfcare.module.scss';

import Button from '@components/Button/Button';

import { Oval } from 'react-loader-spinner'
import usePassengers from '../Components/usePassenger';
import PassengerInput from '../Components/PassengerInput/PassengerInput';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import { IconAdd } from '@assets/icons/icons';
import { useState } from 'react';
import Recap from './Recap';
import { useTranslation } from 'react-i18next';

export default function PassengersPage({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const { t } = useTranslation();

  const [isEmailPhoneVisible, setIsEmailPhoneVisible] = useState<{
    [key: string]: boolean;
  }>({});

  const {
    missionContext,
    control,
    handleSubmit,
    errors,
    loading,
    onSubmit,
    travelerForm,
    watch,
  } = usePassengers({
    forceReadOnly: false,
    copyFirstPassengerToPassenger: (passengerId) => {
      const passengerIndex = travelerForm?.passengerInformation.findIndex((x) => x.id === passengerId);
      if (!passengerIndex) {
        return false;
      }
      return !isEmailPhoneVisible[passengerId];
    }
  });

  const stepIsReadOnly = false;

  if (!missionContext?.steps?.travelerForm) {
    return null;
  }

  return (
    <PageContainer
      containerProps={{
        ...containerProps,
        className: [layoutStyles.container, styles.container, className].join(' '),
      }}
    >
      {loading && (
        <div className={styles.loadingFullScreen}>
          <Oval
            height={40}
            width={40}
            color="white"
            secondaryColor="white"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      <HeaderSelfCare
        title={travelerForm?.selfcareTitle || t('passenger_information')}
        displayBackButton
        displayMissionProgressBar
      />

      {travelerForm?.recap &&
        ['taxi', 'train'].includes(
          travelerForm?.recap.sections.find((s) => s.isMainTransport)?.type || ''
        ) && (
          <div className={styles.recapContainer}>
            <Recap
              recap={travelerForm.recap}
              containerProps={{
                className: styles.travelPath,
              }}
            />
          </div>
        )}

      <form
        data-testid="step-travelerForm"
        className={styles.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={[layoutStyles.content, styles.content].join(' ')}>
          {!travelerForm ? null : (
            <div className={styles.formContainer}>
              {travelerForm.passengerInformation.map((passenger, passengerIndex) => {
                const isFirstPassenger = passengerIndex === 0;
                const layout: { [key: string]: number } = {
                  passengerId: 1,
                  gender: 2,
                  lastName: 3,
                  firstName: 4,
                  birthdate: 5,
                  email: 6,
                  phone: 7,
                };

                const fieldGroups = (passenger.fields || [])
                  .flatMap((x) => x)
                  .sort((a, b) => {
                    return (layout[a.name] || Infinity) - (layout[b.name] || Infinity);
                  });

                return (
                  <div data-testid="passenger-form" key={passenger.id} className={styles.passengerFormContainer}>
                    <div className={styles.passengerFormTitle}>
                      {passenger.title}
                    </div>

                    <div className={styles.passengerForm}>

                      {fieldGroups.map((fieldGroup) => {

                        let isHidden = fieldGroup.name === "passengerId";
                        if (!isHidden && !isFirstPassenger && (
                          fieldGroup.name === "email" || fieldGroup.name === "phone"
                        )) {
                          isHidden = !isEmailPhoneVisible[passenger.id];
                        }
                        return (
                          <div key={fieldGroup.name} className={`${styles.passengerFormRow} ${isHidden ? styles.hidden : ""}`}>
                            <PassengerInput
                              isHidden={isHidden}
                              className={styles.passengerFormCol}
                              control={control}
                              errors={errors}
                              formField={fieldGroup}
                              passenger={passenger}
                              readOnly={stepIsReadOnly}
                              loading={loading}
                              watch={watch}
                            />
                          </div>
                        );
                      })}

                      {!isFirstPassenger && !isEmailPhoneVisible[passenger.id] && (
                        <div
                          className={styles.addEmailAndPhoneContainer}
                          onClick={() => {
                            setIsEmailPhoneVisible((isEmailPhoneVisible) => ({
                              ...isEmailPhoneVisible,
                              [passenger.id]: true,
                            }));
                          }}
                        >
                          <IconAdd /> {t('add_email_phone')}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}

              <div className={styles.footer}>
                <Button
                  label={t('validate')}
                  testid="button-validate-travelerform"
                  containerProps={{
                    className: styles.buttonValidate,
                    disabled: loading || stepIsReadOnly,
                    type: 'submit',
                  }}
                />
              </div>

            </div>
          )}

        </div>

      </form>

    </PageContainer>
  )
}
