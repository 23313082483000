import React from 'react';
import styles from './VrentalAgencyDetail.module.scss';
import ExpandableText from '@components/ExpandableText/ExpandableText';

export default function VrentalAgencyDetail({
  title,
  address,
  phone,
  hours,
  sameReturnAgency,
  imgUrl,

  containerProps: { className, ...containerProps } = {},
}: {

  title: string;
  address?: string;
  phone?: string;
  hours?: string[];
  sameReturnAgency?: boolean;
  imgUrl?: string;

  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>
      <div className={styles.infosContainer}>
        <div className={styles.title}>{title}</div>

        {address && (
          <div className={styles.address}>
            {address}
          </div>
        )}

        {hours && (
          <ExpandableText
            title='Horaires d’ouverture'
            allClickable
            separator={false}
            containerProps={{
              style: {
                marginTop: '10px',
                marginBottom: '10px',
              }
            }}
          >
            {hours && hours.map((hour, i) => (
                <div key={i} className={styles.openingHourLine}>
                    {hour}
                </div>
            ))}
          </ExpandableText>
        )}

        {phone && (
          <div className={styles.phone}>
            {phone}
          </div>
        )}

        {sameReturnAgency && (
          <div className={styles.sameReturnAgency}>
            Même agence de retrait et de dépot
          </div>
        )}

      </div>

      {imgUrl && (
      <div className={styles.imgContainer}>
        <img src={imgUrl} alt={title} />
      </div>
      )}

    </div>
  )
}