import Button from '@components/Button/Button';
import Modal from '@components/Modal/Modal';
import styles from './ResendConfirmationEmailModal.module.scss';
import { useState } from 'react';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { Oval } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import InputText from '@components/Inputs/InputText/InputText';
import Checkbox from '@components/Checkbox/Checkbox';

export default function ModalResendConfirmationEmail({
  onRequestClose,
}: {
  onRequestClose: () => void;
}) {
  const { t } = useTranslation();

  const { callAction, missionContext, refetchMission } = useCurrentMission();
  const [currentState, setCurrentState] = useState<'send' | 'success' | 'error'>('send');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [clientEmail, setClientEmail] = useState<string>(missionContext?.steps?.confirmation?.mainEmail || '');
  const [changeEmail, setChangeEmail] = useState<boolean>(false);

  const sendConfirmationEmail = async () => {
    setConfirmLoading(true);

    let newCurrentState: 'success' | 'send' | 'error' = 'send';
    const action = missionContext?.steps?.confirmation?.resendConfirmationEmailAction;

    try {
      if (!action) {
        throw new Error('missing action');
      }

      await callAction(action, {
        email: clientEmail,
        setAsMainEmail: changeEmail,
      }, {
        returnData: true,
      });
      if (changeEmail) {
        await refetchMission();
      }

      newCurrentState = 'success';
      onRequestClose();
    } catch (e) {
      console.log(e);
      newCurrentState = 'error';
    }

    setCurrentState(newCurrentState);
    setConfirmLoading(false);
  };

  const onCancel = () => {
    onRequestClose();
  };

  return (
    <Modal
      title={t('resend_confirmation_email')}
      containerProps={{
        style: {
          maxWidth: 600,
          minWidth: 500,
        },
        className: `${styles.modalConfirmContainer}`,
      }}
      onRequestClose={onRequestClose}
      layout={'default'}
    >

      {currentState === 'send' && (
        <div className={styles.inputContainer}>

          <InputText
            label={t('email_address')}
            inputProps={{
              value: clientEmail,
              onChange: (e) => {
                setClientEmail(e.target.value);
              }
            }}
            containerProps={{
              className: 'w-full'
            }}
          />

          <Checkbox
            label={t('set_as_main_email')}
            checked={changeEmail}
            onChange={(checked) => {
              setChangeEmail(checked);
            }}
            containerProps={{
              className: 'w-full mt-4'
            }}
          />
        </div>
      )}

      <div className={styles.buttonActions}>
        <Button
          onClick={async () => {
            if (currentState !== 'send') {
              setCurrentState('send');
              return;
            }
            await sendConfirmationEmail();
          }}
          containerProps={{
            style: {
              height: 50,
            },
          }}
        >
          {confirmLoading ? (
            <Oval
              height={20}
              width={20}
              color='white'
              secondaryColor='white'
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            t('send_the_email')
          )}
        </Button>

        <Button
          label={t('cancel')}
          onClick={() => {
            onCancel();
          }}
          type='secondary'
        />

      </div>
    </Modal>
  );
}