import TravelPath from "@components/TravelPath/TravelPath";
import styles from './RecapTravelPath.module.scss';
import { ConfirmSectionType, RecapSectionType } from "src/Stores/CurrentMissionTypes/MissionContextType";
import { Arrival, Car, Taxi, Train, Walk } from "@assets/icons/icons";
import { format } from "date-fns";
import { useTranslation } from 'react-i18next'; // Import useTranslation

export default function RecapTravelPathSection({
  section,
  right,
  containerProps,
}: {
  section: RecapSectionType | ConfirmSectionType;
  right?: React.ReactNode;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const { t } = useTranslation(); // Initialize useTranslation

  return (
    <TravelPath
      containerProps={{
        ...containerProps,
        className: `${styles.travelPath} ${containerProps?.className || ""}`,
      }}
      travelInfo={{
        left: (
          <>
            <div className={styles.transportationTypeIconContainer}>
              {(() => {
                switch (section.type) {
                  case 'train':
                    return <Train />;
                  case 'vrental':
                    return <Car />;
                  case 'taxi':
                    return <Taxi />;
                  case 'walk':
                    return <Walk />;
                  case 'arrival':
                    return <Arrival />;
                  default:
                    return null;
                }
              })()}
              <div className={styles.infoPrimaryLabel}>
                {(() => {
                  switch (section.type) {
                    case 'train':
                      return t("train");
                    case 'vrental':
                      return t("vrental");
                    case 'taxi':
                      return t("taxi");
                    case 'walk':
                      return t("walk");
                    default:
                      return null;
                  }
                })()}
              </div>
            </div>
          </>
        ),
        right: right
      }}
      from={{
        labelLeft: format(new Date(section.origin.datetime), "HH:mm"),
        labelRight: section.origin.name,
      }}
      to={{
        labelLeft: format(new Date(section.destination.datetime), "HH:mm"),
        labelRight: section.destination.name,
      }}
    />
  )
}