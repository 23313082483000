import React from 'react';
import styles from './VerticalSteps.module.scss';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { MissionStepKeys } from 'src/Stores/CurrentMissionTypes/Types';


export type Step = {
  label: string;
  stepKey: MissionStepKeys;
  isCurrent?: boolean;
  canClick?: boolean;
  isEnable?: boolean;
}

export default function VerticalSteps({
  steps = [],
  displayLabels = true,
  containerProps: { className, ...containerProps } = {},
  onClickStep,
  onClickStepIndex,
}: {
  steps: Array<Step>;
  displayLabels?: boolean;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  onClickStep?: (stepKey: MissionStepKeys) => any;
  onClickStepIndex?: (index: number) => any;
}) {

  const { missionContext } = useCurrentMission();
  const stepKey = missionContext?.currentStep;
  const hasConfirmationStep = !!missionContext?.steps['confirmation'];

  return (
    <div className={`${styles.container} ${className} ${displayLabels ? '' : styles.withoutLabels} ${(stepKey === 'confirmation' || hasConfirmationStep) && styles.confirmation }`} {...containerProps}>

      {steps.map((step, i) => (
        <React.Fragment key={i}>
          <div
            className={[
              styles.step,
              step.isEnable ? styles.stepActiv : "",
              step.canClick ? styles.stepClickable : "",
              !step.canClick ? styles.stepNotClickable : "",
              step.stepKey === stepKey || step.isCurrent ? styles.isCurrentStep : "",
            ].join(" ")}
            onClick={() => {
              if (!step.canClick) return;
              onClickStep && onClickStep(step.stepKey);
              onClickStepIndex && onClickStepIndex(i);
            }}
          >
            <div className={styles.badgeContainer}>
              <div className={styles.badge}>{i + 1}</div>
              {i !== steps.length - 1 && <div className={styles.separatorInsideBadgeContainer}>
                <div className={styles.separatorInsideBadge} />
              </div>}
            </div>
            {displayLabels && <div className={styles.label}>{step.label}</div>}
          </div>

          {i !== steps.length - 1 && <div className={styles.separatorContainer}>
            <div className={styles.separator} />
          </div>}
        </React.Fragment>
      ))}



    </div>
  )
}