import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authenticatedApi, getAuthenticatedApiInstance } from "src/Api/api";
import { isSelfCare } from "src/Stores/AppContext";
import { useCurrentMission } from "src/Stores/CurrentMission";
import { ApiAction } from "src/models/ApiAction";


export default function useConfirmationPageController() {
  const navigate = useNavigate();
  const [score, setScore] = useState<number | null>(null);

  const [loading, setLoading] = useState(false);
  const { missionContext, mission, callAction } = useCurrentMission();

  const fetchScore = useCallback(async () => {
    if (!missionContext?.steps.confirmation || !missionContext?.currentStep) {
      return;
    }
    if (isSelfCare()) {
      // call /self-care/review/cases/{%s}/missions/{%s}" to get the review value
      authenticatedApi.get(`/review/cases/${mission?.caseID}/missions/${mission?.id}`).then((response) => {
        setScore(response.data.score || null);
      }).catch((error) => {
        console.error(error);
      })
    }

  }, [mission?.caseID, mission?.id, missionContext?.currentStep, missionContext?.steps.confirmation]);

  useEffect(() => {
    fetchScore();
  }, [fetchScore]);

  if (!missionContext?.steps.confirmation || !missionContext?.currentStep) {
    return null;
  }

  const currentStep = missionContext.currentStep;
  const passengers = missionContext.steps?.travelerForm?.passengerInformation;
  const confirmationStep = missionContext.steps.confirmation;
  const recapDate = new Date(confirmationStep.date);
  const isTaxiOnly = confirmationStep.sections.every(section => section.type === 'taxi');
  const isRail = confirmationStep.sections.some(section => section.type === 'train');
  const passengerCount = mission?.searchData?.passengerCount;
  const today = new Date();
  const validUntilDate = new Date(confirmationStep.validUntil);
  const optionIsToday =
      today.getDate() === validUntilDate.getDate() &&
      today.getMonth() === validUntilDate.getMonth() &&
      today.getFullYear() === validUntilDate.getFullYear();

  const stepAction = async (action: 'confirm' | 'confirmation' | 'cancel') => {
    if (!missionContext?.steps.confirmation) {
      return;
    }

    const selectedAction = action === "confirmation"
          ? missionContext?.steps.confirmation?.confirmAction
          : missionContext?.steps.confirmation?.cancelAction;

    if (!selectedAction) {
      return;
    }

    setLoading(true);
    try {
      await callAction(selectedAction);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  const callApiAction = async (apiAction: ApiAction, data?: any) => {
    setLoading(true);
    try {
      await callAction(apiAction, data);
      await fetchScore();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  const setReviewScoreAction = async (apiAction: ApiAction, data?: any) => {
    try {
      await getAuthenticatedApiInstance({ prefixIfSelfCare: false }).request({
        url: apiAction.href,
        method: apiAction.method,
        data,
      });
      await fetchScore();
    } catch (e) {
      console.error(e);
    }
  }

  return {
    recap: confirmationStep,
    score,
    navigate,
    missionContext,
    currentStep,
    mission,
    passengers,
    confirmationStep,
    recapDate,
    isTaxiOnly,
    isRail,
    passengerCount,
    today,
    validUntilDate,
    optionIsToday,
    stepAction,
    callApiAction,
    setReviewScoreAction,
    loading,
  };
}