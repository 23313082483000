
export type StatusKeys = 'optionOnHold' | 'toBeBooked' |
  'booked' | 'inProgress' | 'finished' | 'confirmed' | 'error' | 'status_unavailable' | 'canceled' | 'passed'
  | 'canceled_by_driver' | 'canceled_by_assister' | 'canceled_and_billed' | 'unacknowleged' | 'option_expired';

export type MissionStatusKeys = 'created' | 'search' | 'solutions' | 'travelerform' | 'recap' | 'confirmed' | 'in_progress' | 'passed' | 'error' | 'canceled' | 'option_expired';

export type MissionStatusKeysOrStatusKeys = MissionStatusKeys | StatusKeys;

export type StatusLabelsType = {
    [key in MissionStatusKeysOrStatusKeys]: {
      label: string;
      color: string;
    }
};

export type PaymentsStatusKeys = 'pending' | 'payed' | 'processing';

export type PaymentStatusLabelsType = {
    [key in PaymentsStatusKeys]: {
      label: string;
      color: string;
    }
};

/*
	RecapSectionStatusToBeBooked           RecapSectionStatus = "toBeBooked"
	RecapSectionStatusOptionOnHold         RecapSectionStatus = "optionOnHold"
	RecapSectionStatusWaitingForValidation RecapSectionStatus = "waitingForValidation"
	RecapSectionStatusBooked               RecapSectionStatus = "booked"
	RecapSectionStatusInProgress           RecapSectionStatus = "inProgress"
	RecapSectionStatusFinished             RecapSectionStatus = "finished"
	RecapSectionStatusError                RecapSectionStatus = "error"
	RecapSectionStatusCanceled             RecapSectionStatus = "canceled"
	RecapSectionStatusCanceledByDriver     RecapSectionStatus = "canceled_by_driver"
	RecapSectionStatusCanceledByAssister   RecapSectionStatus = "canceled_by_assister"
	RecapSectionStatusCanceledAndBilled    RecapSectionStatus = "canceled_and_billed"
	RecapSectionStatusUnacknowledged       RecapSectionStatus = "unacknowleged"
*/

export const paymentStatusLabels: PaymentStatusLabelsType = {
    pending: { label: 'En attente', color: '#FFE8B0' },
    processing: { label: 'En cours', color: '#FFE8B0' },
    payed: { label: 'Payée', color: '#CFE9EE' },
}

export const statusLabels: StatusLabelsType = {
    optionOnHold: { label: 'Option posée', color: '#FFE8B0' },
    toBeBooked: { label: 'En attente de validation', color: '#FFE8B0'},
    booked: { label: 'Réservé', color: '#CFE9EE' },
    inProgress: { label: 'En cours', color: '#CFE9EE'  },
    finished: { label: 'Passée', color: '#E3EBAF'},
    confirmed: { label: 'Confirmée', color: '#E3EBAF'},
    error: { label: 'Erreur', color: '#F7C7C7' },
    status_unavailable: { label: 'Statut indisponible', color: '#F7C7C7' },
    canceled: { label: 'Annulée', color: '#F7C7C7' },
    passed: { label: 'Passée', color: '#E3EBAF' },
    canceled_by_driver: { label: 'Annulé par le conducteur', color: '#F7C7C7' },
    canceled_by_assister: { label: 'Annulé par l\'assistant', color: '#F7C7C7' },
    canceled_and_billed: { label: 'Annulé et facturée', color: '#F7C7C7' },
    unacknowleged: { label: 'Non accepté', color: '#F7C7C7' },

    created: { label: 'Créée', color: '#FFE8B0' },
    search: { label: 'Recherche', color: '#FFE8B0' },
    solutions: { label: 'Solutions', color: '#FFE8B0' },
    travelerform: { label: 'Formulaire voyageur', color: '#FFE8B0' },
    recap: { label: 'Récapitulatif', color: '#FFE8B0' },
    in_progress: { label: 'En cours', color: '#CFE9EE' },
    option_expired: { label: 'Option expirée', color: '#F7C7C7' },
  };
