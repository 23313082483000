import React, { useRef, useState } from 'react';
import Slider from "react-slick";
import styles from './Onboarding.module.scss';
import ReactModalWrapper from '@components/Modal/ReactModalWrapper';
import StepOne from './Steps/StepOne';
import Stepper from './Stepper/Stepper';
import Button from '@components/Button/Button';
import StepOffers from './Steps/StepOffers';
import StepSearch from './Steps/StepSearch';
import StepMobility from './Steps/StepMobility';
import { useTranslation } from 'react-i18next';

export default function OnboardingModal({
  onRequestClose,
  containerProps: { className, ...containerProps } = {},
}: {
  onRequestClose: () => void;
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {
  
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const sliderRef = useRef<Slider>(null);

  const goToNextStep = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
    setCurrentStep(Math.min(currentStep + 1, 4));
  }

  return (
    <ReactModalWrapper
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: "100%",
          height: "auto",
          bottom: 0,
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "center",
          alignSelf: "flex-end",
        }
      }}
    >
      <div className={[styles.modalContainer, className].join(" ")} {...containerProps}>

        <div className={styles.sliderContainer}>
          <Slider
            ref={sliderRef}
            dots={false}
            arrows={false}
            slidesToShow={1}
            slidesToScroll={1}
            infinite={false}
            className={styles.slider}
            afterChange={(index) => setCurrentStep(index)}
          >
            <StepOne
              containerProps={{
                className: styles.stepContainer,
              }}
            />
            <StepOffers
              containerProps={{
                className: styles.stepContainer,
              }}
            />
            <StepSearch
              containerProps={{
                className: styles.stepContainer,
              }}
            />
            <StepMobility
              containerProps={{
                className: styles.stepContainer,
              }}
            />
          </Slider>
        </div>


        <Stepper
          totalSteps={4}
          currentStep={currentStep}
          containerProps={{
            className: styles.stepper,
          }}
        />

        {currentStep < 3 && (
        <Button
          type="primary"
          label={t('next_button')}
          onClick={goToNextStep}
          containerProps={{
            className: styles.nextButton,
          }}
        />
        )}

        {currentStep === 3 && (
        <Button
          type="primary"
          label={t('understood_button')}
          onClick={onRequestClose}
          containerProps={{
            className: styles.nextButton,
          }}
        />
        )}
      </div>
    </ReactModalWrapper>
  )
}