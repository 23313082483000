import styles from './Tag.module.scss';

// type PredefinedColors =
//   'primary' | 'secondary' |
//   'success' | 'warning' | 'danger' |
//   'info' |
//   'light' | 'dark' | 'white' | 'black' | 'transparent';

export default function Tag({
  label = "Tag",
  color = "#000",
  textColor,
  icon,
  isLarge,
  onClick,
  containerProps: { className, style, ...containerProps } = {},
  textClassName,
}: {
  label?: string;
  color?: string;
  textColor?: string;
  isLarge?: boolean;
  icon?: JSX.Element,

  onClick?: () => any;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  textClassName?: string;
}) {
  return (
    <div
      className={[styles.container, onClick ? styles.clickable : "", className, isLarge && styles.large].join(" ")}
      style={Object.assign({
        style,
        ...(color ? { backgroundColor: color } : {})
      })}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick && onClick();
      }}
      {...containerProps}
    >
      <div className={`${styles.text} ${textClassName || ""}`} style={textColor ? {color: textColor} : {}}>
        {icon && <span className={styles.icon}>{icon}</span>} {label}
      </div>
    </div>
  )
}