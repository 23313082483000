import CurrentMission from "src/Pages/CurrentMission/CurrentMission.selfcare";
import WelcomeSelfCare from "src/Pages/SelfCare/Welcome/Welcome";
import WarrantiesSelfCare from "src/Pages/SelfCare/Warranties/Warranties";
import AppSelfCareContainer from "./AppContainer/AppSelfCareContainer";
import LoginWithLicensePlate from "src/Pages/SelfCare/Auth/LoginWithLicensePlate/LoginWithLicensePlate";
import LoginRegisterEmailPassword from "src/Pages/SelfCare/Auth/LoginRegisterEmailPassword/LoginRegister";
import HomeSelfCare from "src/Pages/SelfCare/Home/Home";
import NewMission from "src/Pages/SelfCare/NewMission/NewMission";
import CurrentMissionSelfCareContainer from "./CurrentMissionContainer/CurrentMissionSelfCareContainer";
import RequireAuthSelfCare from "./Auth/SelfCare/RequireAuth";
import { Outlet } from "react-router-dom";
import PaymentSelfCarePage from "src/Pages/CurrentMission/Pages/ItineraryRecap/SelfCare/PaymentSelfCare/PaymentSelfCare";
import AddReceiptFlow from "src/Pages/SelfCare/AddReceipt/AddReceiptFlow";
import ErrorPage from "src/Pages/SelfCare/Error/ErrorPage";

const routerSelfCare = {
  path: "/self-care",
  errorElement: <ErrorPage />,
  element: <AppSelfCareContainer />,
  children: [
    {
      path: "login",
      element: <LoginRegisterEmailPassword />,
    },
    {
      path: "register",
      element: <LoginRegisterEmailPassword isRegister />,
    },
    {
      path: "auth/license-plate",
      element: <LoginWithLicensePlate />,
    },
    {
      path: "auth/:token",
      element: <LoginWithLicensePlate />,
    },

    // Now require auth
    {
      path: "",
      element: <RequireAuthSelfCare><Outlet /></RequireAuthSelfCare>,
      children: [
        {
          element: <HomeSelfCare />,
          index: true,
        },

        {
          path: "error",
          element: <ErrorPage />,
        },

        {
          path: "new-mission",
          element: <NewMission />,
        },
        {
          path: "cases/:caseId/missions/:missionId",
          element: (
            <CurrentMissionSelfCareContainer />
          ),
          children: [
            {
              index: true,
              element: (<WelcomeSelfCare />),
            },
            {
              path: "warranties",
              element: (<WarrantiesSelfCare />),
            },
            {
              path: "payment",
              element: (<PaymentSelfCarePage />),
            },
            {
              path: "refund",
              children: [
                {
                  index: true,
                  element: (<AddReceiptFlow />),
                },
              ]
            },
            {
              path: "mission",
              element: (<CurrentMission />),
            },
          ]
        },
      ],
    },


  ],
};

export default routerSelfCare;