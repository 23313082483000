import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LoginFlow, UpdateLoginFlowBody } from '@ory/client';
import { UserAuthCard } from '@ory/elements';
import { sdk, sdkError } from 'src/Providers/ory/sdk';

export default function LoginPage() {

  const [flow, setFlow] = useState<LoginFlow | null>(null)
  const [searchParams, setSearchParams] = useSearchParams();
  const aal2 = searchParams.get("aal2")
  const loginChallenge = searchParams.get("login_challenge")
  const returnTo = searchParams.get("return_to")
  const navigate = useNavigate();

  // Get the flow based on the flowId in the URL (.e.g redirect to this page after flow initialized)
  const getFlow = useCallback(
    (flowId: string): any =>
      sdk
        // the flow data contains the form fields, error messages and csrf token
        .getLoginFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch(sdkErrorHandler),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const sdkErrorHandler = useMemo(() => sdkError(getFlow, setFlow, "/login", true, navigate), [getFlow, navigate]);

  // Create a new login flow
  const createFlow = useCallback(() => {
    sdk
      .createBrowserLoginFlow({
        refresh: true,
        aal: aal2 ? "aal2" : "aal1",
        ...(loginChallenge && { loginChallenge: loginChallenge }),
        ...(returnTo && { returnTo: returnTo }),
      })
      // flow contains the form fields and csrf token
      .then(({ data: flow }) => {
        // Update URI query params to include flow id
        setSearchParams({ ["flow"]: flow.id })
        // Set the flow data
        setFlow(flow)
      })
      .catch(sdkErrorHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aal2, loginChallenge, returnTo, setSearchParams]);

  const submitFlow = (body: UpdateLoginFlowBody) => {
    // something unexpected went wrong and the flow was not set
    if (!flow) return navigate("/login", { replace: true })

    // we submit the flow to Ory with the form data
    sdk
      .updateLoginFlow({ flow: flow.id, updateLoginFlowBody: body })
      .then(() => {
        // we successfully submitted the login flow, so lets redirect to the dashboard

        navigate(flow.return_to || "/", { replace: true })
      })
      .catch(sdkErrorHandler)
  }

  useEffect(() => {
    // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
    const flowId = searchParams.get("flow")
    // the flow already exists
    if (flowId) {
      getFlow(flowId).catch(createFlow) // if for some reason the flow has expired, we need to get a new one
      return;
    }

    // we assume there was no flow, so we create a new one
    createFlow()
  }, [createFlow, getFlow, searchParams])

  if (!flow) {
    return null;
  }

  return (
    <UserAuthCard
      flowType={"login"}
      // we always need the flow data which populates the form fields and error messages dynamically
      flow={flow}
      // the login card should allow the user to go to the registration page and the recovery page
      additionalProps={{
        forgotPasswordURL: {
          handler: () => {
            const search = new URLSearchParams()
            flow.return_to && search.set("return_to", flow.return_to)
            navigate(
              {
                pathname: "/recovery",
                search: search.toString(),
              },
              { replace: true },
            )
          },
        },
        signupURL: {
          handler: () => {
            const search = new URLSearchParams()
            flow.return_to && search.set("return_to", flow.return_to)
            flow.oauth2_login_challenge &&
              search.set("login_challenge", flow.oauth2_login_challenge)
            navigate(
              {
                pathname: "/registration",
                search: search.toString(),
              },
              { replace: true },
            )
          },
        },
      }}
      // we might need webauthn support which requires additional js
      includeScripts={true}
      // we submit the form data to Ory
      onSubmit={({ body }) => submitFlow(body as UpdateLoginFlowBody)}
    />
  )
}