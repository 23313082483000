import styles from './Switch.module.scss';
import React from 'react';

export default function Switch({
  testid,
  disabled,
  checked = false,
  onChange,
  label,
  errorMsg,
  containerProps: { className, ...containerProps } = {},
}: {
  testid?: string;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (checked: boolean) => any;
  label?: string;
  errorMsg?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {


  return (
    <div
      data-testid={testid}
      className={[styles.container, disabled ? styles.disabled : "", className].join(" ")}
      {...containerProps}
      onClick={() => {
        if (disabled) return;
        onChange && onChange(!checked);
      }}
    >
      <div
        className={[styles.switch, checked ? styles.checked : ""].join(" ")}
      >
        <div className={styles.innerCircle} />
      </div>
      {(label || errorMsg) && (
        <div className={styles.labelContainer}>
          {label && <div className={styles.label}>{label}</div>}
          {errorMsg && <div className={styles.errorMsg}>{errorMsg}</div>}
        </div>
      )}
    </div>
  )
}