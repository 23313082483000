import { FilterTypeMultiCheckboxes, FilterTypeMultiValues, FilterValueMapping, FiltersType, TagsType } from "./types";

function getTagsFromMultiCheckboxesOrMultiSelect({
  filter,
  filterValue,
}: {
  filter: FilterTypeMultiCheckboxes | FilterTypeMultiValues;
  filterValue: {
    [key: string]: boolean;
  };
}): TagsType {
  const tags: TagsType = {};
  for (const filterValueKey of Object.keys(filterValue)) {
    if (filterValue[filterValueKey as keyof typeof filterValue]) {
      tags[filterValueKey] = {
        label: filter.options.find((option) => option.value === filterValueKey)?.label || '',
        onClickRemove: () => {
          filter.onChange && filter.onChange({
            ...(filterValue || {}),
            [filterValueKey]: false,
          });
        }
      };
    }
  }
  return tags;
}

export default function getTags({
  filterValues,
  filters
}: {
  filterValues: FilterValueMapping;
  filters: FiltersType;
}): TagsType {

  let tags: TagsType = {};

  for (const filterKey of Object.keys(filters)) {
    const filter = filters[filterKey as keyof typeof filters];
    if (filter.displayTags === false) {
      continue;
    }
    const filterValue = filterValues[filterKey as keyof typeof filterValues];
    if (filterValue) {
      switch (filter.type) {
        case 'switch':
          tags[filterKey] = {
            label: filter.labelTag || filter.label,
            onClickRemove: () => {
              filter.onChange && filter.onChange(false);
            }
          };
          break;
        case 'multicheckboxes':
        case 'multiselect':
          tags = {
            ...tags,
            ...getTagsFromMultiCheckboxesOrMultiSelect({
              filter,
              filterValue: filterValue as {
                [key: string]: boolean;
              }
            })
          }
          break;
        default:
          break;
      }
    }
  }

  return tags;

}