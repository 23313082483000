import styles from './Stepper.module.scss';

export default function Stepper({

  currentStep,
  totalSteps,

  containerProps: { className, ...containerProps } = {},
}: {
  currentStep: number;
  totalSteps: number;
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {


  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>
      {Array.from({ length: totalSteps }).map((_, i) => (
        <div
          key={i}
          className={[
            styles.step,
            i === currentStep ? styles.active : "",
          ].join(" ")}
        />
      ))}
    </div>
  )
}