import { Status, Wrapper } from '@googlemaps/react-wrapper';
import React, { useRef, useState, useEffect } from 'react';

interface MapProps extends google.maps.MapOptions {
  style?: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;

  bounds?: google.maps.LatLngBounds | null;
  center?: google.maps.LatLng|null|google.maps.LatLngLiteral;
}

export function MapWrapper({
  children,
}: {
  children: React.ReactNode;
}) {

  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Wrapper apiKey={"AIzaSyC32iPUK2HQUgZYVKb-fwDcdEgf4hyjSoA"}
      callback={(status) => {
        if (status === Status.SUCCESS) {
          setIsLoaded(true);
        }
      }}
      libraries={["geometry"]}
    >
      {isLoaded ? children : null}
    </Wrapper>
  );
}

export default function Map(props: MapProps) {

  return (
    <Wrapper apiKey={"AIzaSyC32iPUK2HQUgZYVKb-fwDcdEgf4hyjSoA"}
      render={(status: Status) => {
        return <h1>{status}</h1>;
      }}
    >
      <MapUnderWrapper
        {...props}
      />
    </Wrapper>
  );
}

export function MapUnderWrapper({
  children,
  style,
  bounds,
  center,
  zoom,
}: MapProps) {

  const [_hack, setHack] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current) {
      if (map) {
        if (bounds) {
          map.fitBounds(bounds, 80);
        }
        if (center) {
          map.setCenter(center);
          map.setZoom(zoom || 15);
        }
      } else {
        const map = new window.google.maps.Map(ref.current, {
          disableDefaultUI: true,
          gestureHandling: 'greedy',
          zoomControl: true,
          clickableIcons: false,
        });
        setMap(
          map
        );
      }
    }
  }, [ref, map, bounds, center, zoom]);

  const childrenCount = React.Children.count(children);
  useEffect(() => {
    setHack(hack => hack + 1);
  }, [childrenCount, map]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (!map) {
          return null;
        }


        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
}
