import { IconAutocompleteMarker } from '@assets/icons/icons';
import styles from './AutocompleteModal.module.scss';
import PositionMarker from '@components/PositionMarker/PositionMarker';

export default function AutocompleteModalSuggestions<SuggestionType>({
  suggestionsContainerRef,
  suggestionsIsOpen,
  isLoadingSuggestions,
  suggestions,
  selectedSuggestionIndex,
  setSelectedSuggestionIndex,
  selectSuggestion,
  getTextFromSuggestion,
  onClickSuggestion,
  hasCustomFirstSuggestion,
  containerProps: { className, ...containerProps } = {},
}: {
  suggestionsContainerRef: React.RefObject<HTMLDivElement>;
  suggestionsIsOpen: boolean;
  isLoadingSuggestions: boolean;
  suggestions: any[];
  selectedSuggestionIndex: number | null;
  setSelectedSuggestionIndex: (index: number | null) => void;
  selectSuggestion: (suggestion: SuggestionType | null) => void;
  getTextFromSuggestion: (suggestion: SuggestionType) => string;
  onClickSuggestion?: (suggestion: SuggestionType) => void;
  hasCustomFirstSuggestion?: boolean;
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {

  const onClickSuggestionIndex = (i: number, suggestion: SuggestionType) => {
    setSelectedSuggestionIndex(i);
    setTimeout(() => selectSuggestion(suggestion), 0);
    onClickSuggestion && onClickSuggestion(suggestion);
  }

  return (
    <div
      ref={suggestionsContainerRef}
      className={[styles.suggestions, suggestionsIsOpen ? styles.suggestionsOpen : "", className].join(" ")}
      {...containerProps}
    >
      {hasCustomFirstSuggestion && (
      <div
        className={styles.suggestion}
        onClick={() => {
          onClickSuggestionIndex(0, suggestions[0]);
        }}
      >
        <PositionMarker
          containerProps={{
            className: styles.suggestionIconPosition,
          }}
        />
        <div className={styles.suggestionText}>Votre position</div>
      </div>
      )}

      {isLoadingSuggestions ? (
        <div className={styles.suggestion}>
          Chargement...
        </div>
      ) : suggestions.map((suggestion, i) => hasCustomFirstSuggestion && i === 0 ? null : (
        <div
          key={i}
          className={[styles.suggestion, selectedSuggestionIndex === i ? styles.selected : ""].join(" ")}
          onClick={() => {
            onClickSuggestionIndex(i, suggestion);
          }}
        >
          <IconAutocompleteMarker className={styles.suggestionIcon} />
          <div className={styles.suggestionText}>{getTextFromSuggestion(suggestion)}</div>
        </div>
      ))}
    </div>
  )
}