import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCurrentMission } from "src/Stores/CurrentMission";
import { MissionStepKeys } from "src/Stores/CurrentMissionTypes/Types";
import { ApiAction } from "src/models/ApiAction";

export default function useRecap() {

  const navigate = useNavigate();
  const [comments, setComments] = useState<{
    [key: string]: string;
  }>({});
  const [loading, setLoading] = useState(false);
  const { missionContext, setMissionContext, mission, callAction } = useCurrentMission();

  if (!missionContext?.steps.recap || !missionContext?.currentStep) {
    return null;
  }

  const currentStep = missionContext.currentStep;
  const passengers = missionContext.steps?.travelerForm?.passengerInformation;
  const recap = missionContext.steps.recap;
  const recapDate = new Date(recap.date);
  const isTaxiOnly = recap.sections.every(section => section.type === 'taxi');
  const isRail = recap.sections.some(section => section.type === 'train');
  const passengerCount = mission?.searchData?.passengerCount;
  const today = new Date();
  const validUntilDate = new Date(recap.validUntil);
  const optionIsToday =
      today.getDate() === validUntilDate.getDate() &&
      today.getMonth() === validUntilDate.getMonth() &&
      today.getFullYear() === validUntilDate.getFullYear();

  const stepAction = async (currentStep: MissionStepKeys, action: 'confirm' | 'confirmation' | 'cancel' | 'sendPaymentLinkAction') => {
    if (!missionContext?.steps.recap) {
      return;
    }

    let selectedAction;

    if (currentStep === "recap") {
      switch (action) {
        case "confirm":
        case "confirmation":
          selectedAction = missionContext?.steps.recap?.actions?.confirmAction;
          break;
        case "cancel":
          selectedAction = missionContext?.steps.recap?.actions?.cancelAction;
          break;
        case "sendPaymentLinkAction":
          selectedAction = missionContext?.steps.recap?.actions?.sendPaymentLinkAction;
          break;
      }
    }

    if (!selectedAction) {
      return;
    }

    setLoading(true);
    try {
      await callAction(selectedAction, {
        comments,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  const callApiAction = async (apiAction: ApiAction) => {
    setLoading(true);
    try {
      await callAction(apiAction);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  return {
    navigate,
    comments,
    setComments,
    loading,
    setLoading,
    missionContext,
    setMissionContext,
    currentStep,
    passengers,
    recap,
    recapDate,
    isTaxiOnly,
    isRail,
    passengerCount,
    today,
    validUntilDate,
    optionIsToday,
    stepAction,
    callApiAction,
  }
}