import React from "react"

export default function StringWithBr({
  text
}: {
  text?: string | string[] | undefined | null
}) {

  if (Array.isArray(text)) {
    return (
      text.map((textLine, index) => (
        <React.Fragment key={index}>
          {index !== 0 && <br/>}
          <StringWithBr text={textLine} />
        </React.Fragment>
      ))
    )
  }

  if (!text) {
    return null;
  }

  return text.split("\n").map((item, key) => {
    return (
      <React.Fragment key={key}>
        {key !== 0 && <br/>}
        {item}
      </React.Fragment>
    );
  })
}