import { useState } from "react";
import { ContractFormType } from "src/models/ContractFormType";


export default function useContractOptions({
  contractId,
  eventOutcomeId,
}: {
  contractId: number | null;
  eventOutcomeId: number | null;
}) {

  const [contracts, setContracts] = useState<Array<ContractFormType>>([]);

  return {
    contracts,
    eventOutcomes: contracts.find((contract) => contract.id === contractId)?.eventOutcomes || [],
    subcovers: contracts.find((contract) => contract.id === contractId)?.eventOutcomes.find((eventOutcome) => eventOutcome.id === eventOutcomeId)?.subcovers || [],
    setContracts,
  };
}