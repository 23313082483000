import { create } from 'zustand';
import { Entity } from './types';

export const useCrudEntities = create<{
  entities: Entity[];
  setEntities: (entities: Entity[]) => void;
}>((set) => ({
  entities: [],
  setEntities: (entities) => set({ entities }),
}));

