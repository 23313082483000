import ReactModal from 'react-modal';
import { useMediaQuery } from 'usehooks-ts';
import { isSelfCare } from 'src/Stores/AppContext';
import { useEffect } from 'react';

// destructuring style { content, overlay }
export default function ReactModalWrapper({
  onRequestClose,
  children,
  isOpen = true,
  shouldCloseOnOverlayClick = true,
  ariaHideApp = false,

  style: {
    content: contentStyle = {},
    overlay: overlayStyle = {},
  } = {},

  ...props
}: { isOpen?: boolean } & Omit<ReactModal.Props, 'isOpen'>) {

  // use mediaQuery to change the style of the modal
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (isOpen && isMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    }
  }, [isOpen, isMobile])

  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={ariaHideApp}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      {...props}
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 8000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...overlayStyle,
        },
        content: {
          border: "none",
          padding: 0,
          inset: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",

          ...((isMobile || isSelfCare()) ? {
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: 0,
            } : {
              borderRadius: 10,
              maxHeight: "calc(100vh - 40px)",
            }
          ),
          ...contentStyle,
        }
      }}
      onRequestClose={onRequestClose}
    >
      {children}
    </ReactModal>
  )
}