import { useMemo, useState } from "react";
import { TagsType, FilterConfigKeys, FilterConfigMultiCheckboxes, FilterConfigSelect, FilterConfigSwitch, FilterSolution, ModalProps } from "./filterTypes";
import { GetConfigArgsFilterMapping } from "./Filters/types";


export type UseFilterReturnType<FilterValueMapping> = {
  countNbFiltersApplied: number;
  appliedFilterValues: FilterValueMapping;
  idsFiltered: {[key: string]: boolean}
  onClickButtonFilter: () => void;
  modalProps: ModalProps;
  resetFilters: (newFilters?: FilterValueMapping) => void;
}

export default function useFilter<FilterValueMapping extends {[key: string]: any}, FilterValueMappingValueType>({
  itemMatchesValue,
  filterItems,
  filterConfig,
  defaultFilterValues,
  getTags,
  countValue,
}: {
  itemMatchesValue: (filterItem: FilterSolution, filterKey: string, filterValue: FilterValueMappingValueType) => boolean;
  filterItems: Array<FilterSolution>;
  filterConfig: {
    [key: string]: (args: GetConfigArgsFilterMapping<FilterValueMapping>) => FilterConfigMultiCheckboxes | FilterConfigSwitch | FilterConfigSelect;
  }
  defaultFilterValues: FilterValueMapping;
  getTags?: (args : {
    filterValues: FilterValueMapping;
    setFilterValues: React.Dispatch<React.SetStateAction<FilterValueMapping>>;
  }) => TagsType;
  countValue: (filterValue: FilterValueMappingValueType) => number;
}): UseFilterReturnType<FilterValueMapping> {

  const [filterValues, setFilterValues] = useState<FilterValueMapping>(defaultFilterValues);
  const [appliedFilterValues, setAppliedFilterValues] = useState<FilterValueMapping>(defaultFilterValues);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const filterConfigKeys = Object.keys(filterConfig) as Array<FilterConfigKeys>;
  const modalPropsFilterConfig: {
    [key: string]: FilterConfigMultiCheckboxes | FilterConfigSwitch | FilterConfigSelect;
  } = {};

  for (const filterConfigKey of filterConfigKeys) {
    modalPropsFilterConfig[filterConfigKey] = filterConfig[filterConfigKey]({
      filterItems,
      filterValues,
      setFilterValues,
    });
  }

  const resetFilters = (newFilterValues?: FilterValueMapping) => {
    setFilterValues({
      ...defaultFilterValues,
      ...newFilterValues,
    });
    setAppliedFilterValues({
      ...defaultFilterValues,
      ...newFilterValues,
    });
  }

  return {

    resetFilters,
    appliedFilterValues,
    idsFiltered: useMemo(() => {
      const idsFiltered: {[key: string]: boolean} = {};
      filterItems.forEach((item) => {
        idsFiltered[item.filterId] = true;
      });

      filterItems.forEach((filterItem) => {
        Object.keys(appliedFilterValues).forEach((filterKey) => {
          const filterValue = appliedFilterValues[filterKey];
          if (!itemMatchesValue(filterItem, filterKey, filterValue)) {
            idsFiltered[filterItem.filterId] = false;
            return true;
          }
        });
      });

      return idsFiltered;
    }, [itemMatchesValue, filterItems, appliedFilterValues]),

    onClickButtonFilter: () => {
      setFilterValues(appliedFilterValues);
      setIsModalOpen(true);
    },

    countNbFiltersApplied: useMemo(() => {
      let count = 0;

      Object.keys(appliedFilterValues).forEach((key) => {
        if (appliedFilterValues[key]) {
          count += countValue(appliedFilterValues[key]);
        }
      });

      return count;
    }, [appliedFilterValues, countValue]),

    modalProps: {
      isOpen: isModalOpen,
      tags: getTags ? getTags({
        filterValues,
        setFilterValues,
      }) : {},
      filterConfig: modalPropsFilterConfig,
      onClickApply: () => {
        setAppliedFilterValues(filterValues);
        setIsModalOpen(false);
      },
      onRequestClose: () => setIsModalOpen(false),
    }
  }
}