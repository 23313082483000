import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

export const useAuthStoreLicensePlate = create<{

  isAuthenticatedSelfCareLicencePlace: boolean;
  setAuthenticatedSelfCareLicencePlace: (value: boolean) => void;
  cleanup: () => void;

}>()(
  devtools(
    persist(
      (set) => ({

        isAuthenticatedSelfCareLicencePlace: false,
        setAuthenticatedSelfCareLicencePlace: (value: boolean) => set({ isAuthenticatedSelfCareLicencePlace: value }),
        cleanup: () => set({ isAuthenticatedSelfCareLicencePlace: false }),
      }),
      {
        name: 'auth-store-license-plate',
      }
    )
  )
);