import { Close } from '@assets/icons/icons';
import styles from './Tag.module.scss';

export default function Tag({
  label = "Tag",
  onClick,
  containerProps: { className, ...containerProps } = {},
}: {
  label?: string;
  onClick?: () => any;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  return (
    <div
      className={[styles.container, className].join(" ")}
      {...containerProps}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <div className={styles.text}>{label}</div>
      <div className={styles.icon}><Close width={14} height={14} /></div>
    </div>
  )
}