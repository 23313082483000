import { useTranslation } from 'react-i18next';
import styles from "./FilterModal.module.scss";
import stylesForm from "@components/Form/Form.module.scss";
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import { Filter as FilterIcon } from "@assets/icons/icons";
import Tag from "../../Tag/Tag";
import { ModalProps } from "../types";
import Filter from "./Filter";
import { isSelfCare } from "src/Stores/AppContext";
import Select from "@components/Select/Select";

export default function FilterModal({
  isOpen,
  applyFilterButtonDisabled,
  tags,
  filters,
  displayTags = true,
  sortTypeSelected,
  sortTypes,
  onChangeSort,
  layout,
  onRequestClose,
  onClickApply,
  containerProps: { className, ...containerProps } = {},
}: ModalProps & {
  sortTypeSelected?: string | null;
  sortTypes?: {
    key: string;
    label: string;
  }[];
  onChangeSort?: (sortKey: string | null) => any;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { t } = useTranslation();

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      onRequestClose={onRequestClose}

      title={isSelfCare() ? t('modalTitleFilters') : undefined}
      leftTitle={isSelfCare() ? undefined : (
        <div className={styles.filterTitleContainer}>
          <FilterIcon />
          <div className={styles.filterTitleLabel}>{t('modalTitleFilters')}</div>
        </div>
      )}
      containerProps={{
        className: styles.modal,
      }}
    >
      <div
        className={[
          stylesForm.form,
          styles.modalContainer,
          className,
        ].join(" ")}
        {...containerProps}
      >

        <div
          className={[
            styles.formContainer,
            className,
          ].join(" ")}
        >

          {displayTags && (
          <div className={styles.tagsContainer}>
            {Object.keys(tags || {}).map((tagKey) => (
              <Tag
                key={tagKey}
                label={(tags || {})[tagKey].label}
                onClick={() => {
                  (tags || {})[tagKey].onClickRemove();
                }}
                containerProps={{
                  className: styles.tag,
                }}
              />
            ))}
          </div>
          )}

          {sortTypes && (
            <Select
              placeholder={t('select_placeholder')}
              optionsV2={sortTypes.map((sortType) => ({
                label: sortType.label,
                value: sortType.key,
              }))}
              selectedIndex={sortTypes.findIndex((sortType) => sortType.key === sortTypeSelected)}
              onChangeIndex={(index) => {
                onChangeSort && onChangeSort(index === null ? index : sortTypes[index].key);
              }}
              containerProps={{
                className: "w-full mb-4",
              }}
            />
          )}

          {(!layout && filters) ? (Object.keys(filters)).map((filterKey) => (
            <Filter filter={filters[filterKey]} key={filterKey} />
          )) : null}

          {(layout && filters) ? (
            layout.map((row, i) => {
              if (row.type === 'filter') {
                return <Filter filter={filters[row.filterKey]} key={row.filterKey} />;
              } else if (row.type === 'title') {
                return (
                  <div className={stylesForm.formRow} key={i}>
                    <div className={styles.filterRowTitle}>
                      {row.title}
                    </div>
                  </div>
                );
              }
            })
          ) : null}

        </div>

        <div className={styles.bottomBtnContainer}>
          <Button
            label={t('apply_filters_button')}
            containerProps={{
              className: [stylesForm.buttonSubmit, styles.buttonValidate].join(" "),
              disabled: applyFilterButtonDisabled
            }}
            onClick={onClickApply}
          />
        </div>
      </div>
    </Modal>
  );
}