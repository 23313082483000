import styles from './CarChoiceItem.module.scss';
import { TagKey } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import { PriceType } from 'src/models/Price';
import Tag from '@components/Tag/Tag';
import PriceIncludedOrRemaining from '@components/PriceIncludedOrRemaining/PriceIncludedOrRemaining';

export type CarChoiceItemProps = {
  readOnly?: boolean;
  distance?: string;
  carName?: string;
  carConditions?: string;
  carImg?: string;
  agencyLabel?: string;
  agencyImg?: string;
  carOptions?: string[];
  remainingCosts?: PriceType;

  tags?: {
    [key in TagKey]?: boolean;
  };
  selected?: boolean;
  onSelect?: () => any;

  co2Emission?: {
    value: string;
    level: 1 | 2 | 3;
    color?: 'default' | 'warning';
  };

  containerProps?: React.HTMLAttributes<HTMLDivElement>;
};

export default function CarChoiceItemSelfCare({

  carName,
  carConditions,
  carImg,
  agencyImg,
  carOptions = [],
  remainingCosts,

  onSelect = () => void 0,
  containerProps: { className, ...containerProps } = {},
}: CarChoiceItemProps) {

  const carConditionTags = (carConditions?.split(",") || []).map((condition) => condition.trim());

  return (
    <div {...containerProps} className={`${styles.infosContainer} ${className}`} onClick={onSelect}>

      {(agencyImg || carImg) && (
      <div className={styles.carImageContainer}>
        {agencyImg && (
          <img src={agencyImg} className={styles.agencyImage} alt="" />
        )}
        {carImg && (
          <img src={carImg} className={styles.carImage} alt="" width={50} />
        )}
      </div>
      )}

      <div className={styles.carDetails}>

        <div className={styles.carName}>{carName}</div>
        {carConditionTags.length > 0 && (
          <div className={styles.carConditions}>
            {carConditionTags.map((condition, i) => (
              <Tag
                key={i}
                label={condition}
                color={"var(--color-accent-warn-light)"}
                textClassName={styles.carConditionTag}
              />
            ))}
          </div>
        )}
        {carOptions && carOptions.length > 0 && (
        <div className={styles.carOptions}>
          {carOptions.map((option, i) => i > 2 ? null : (
            <div key={i} className={styles.carOption}>{option}</div>
          ))}
        </div>
        )}

      </div>

      <div className={styles.priceContainer}>
        <PriceIncludedOrRemaining
          remainingCosts={remainingCosts}
        />
      </div>

    </div>

  )
}

