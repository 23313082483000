import { useLayoutEffect, useRef } from 'react';
import styles from './Switcher.module.scss';

export default function Switcher({
  selectedIndex = 0,
  disabled,
  fullWidth,
  label,
  errorMsg,
  onChangeIndex = () => void 0,
  options,
  buttonsMode = false,
  containerProps: { className, children: _children, ...containerProps } = {},
  testid,
}: {
  options: Array<{ value: string; label?: string, icon?: React.ReactNode }>;
  selectedIndex?: number;
  disabled?: boolean;
  label?: string;
  errorMsg?: string;
  fullWidth?: boolean;
  onChangeIndex?: (index: number) => any;
  buttonsMode?: boolean;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  testid?: string;
}) {

  const optionsContainerRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (fullWidth) return;
    // get the width of the widest option
    let maxWidth = 0;
    optionsContainerRef.current?.querySelectorAll(`.${styles.option}`).forEach((option) => {
      maxWidth = Math.max(maxWidth, option.clientWidth + 16);
    });
    // set all options width to the widest option width
    optionsContainerRef.current?.querySelectorAll(`.${styles.option}`).forEach((option) => {
      const optionElement = option as HTMLElement;
      optionElement.style.width = `${maxWidth}px`;
    });
  }, [fullWidth]);


  return (
    <div data-testid={testid} className={[styles.container, fullWidth ? styles.fullWidth : "", disabled ? styles.disabled : "", buttonsMode ? styles.buttonsMode : "", className].join(" ")}>

      {(label || errorMsg) && (
        <div className={styles.labelContainer}>
          {label && <div className={styles.label}>{label}</div>}
          {errorMsg && <div className={styles.errorMsg}>{errorMsg}</div>}
        </div>
      )}

      <div data-testid="options" ref={optionsContainerRef} className={[styles.optionsContainer, disabled ? styles.disabled : ""].join(" ")} {...containerProps}>

        {options.map((option, i) => (
          <div key={i} className={[
            styles.option,
            buttonsMode ? styles.optionButtonsMode : "",
            i === 0 ? styles.optionLeft : "",
            i === options.length - 1 ? styles.optionRight : "",
            i === selectedIndex ? styles.optionSelected : "",
          ].join(" ")} onClick={() => onChangeIndex(i)}>
            {option.icon}{option.label || option.value}
          </div>
        ))}
      </div>

    </div>
  )
}