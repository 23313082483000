import { VRentalAgency } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import styles from './AgencyInfos.module.scss';
import ExpandableText from '@components/ExpandableText/ExpandableText';
import { useTranslation } from 'react-i18next';

export default function AgencyInfos({
  agency
}: {
  agency: VRentalAgency;
}) {

  const { t } = useTranslation();

  return (
    <div className={styles.agencyInfosWithLogo} key={agency.label}>
      <div className={styles.agencyLogo}>
          {agency.imgURL && (
              <img src={agency.imgURL} alt="" width={100}/>
          )}
      </div>
      <div className={styles.agencyInfos}>
          <div className={styles.agencyName}>
              {agency.label}
          </div>
          <div className={styles.agencyDescriptions}>
              {agency.descriptions && agency.descriptions.map((str, i) => (
                  <div key={i} className={styles.agencyDescriptionLine}>
                      {str}
                  </div>
              ))}
              {agency.openingHours && (
                <ExpandableText
                  title={t('opening_hours_title')}
                  allClickable
                  separator={false}
                  titleContainerProps={{
                    style: {
                      fontSize: '12px',
                      cursor: 'pointer',
                    }
                  }}
                  containerProps={{
                    style: {
                      marginTop: '10px',
                      marginBottom: '10px',
                      fontSize: '14px',
                    }
                  }}
                >
                  {agency.openingHours.map((hour, i) => (
                      <div key={i} className={styles.openingHourLine}>
                          {hour}
                      </div>
                  ))}
                </ExpandableText>
              )}
          </div>
      </div>
  </div>
  )
}