import React, { useMemo } from 'react';

export default function StringRichText({
  children,
  containerProps: { className, ...containerProps } = {},
}: {
  children: string;
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {

  const textTransformed = useMemo(() => {

    const splitText: string[] = children.split(/(<.*?>.*?<\/.*?>)/g);

    return splitText.map((text, index) => {
      if (text.match(/<.*?>.*?<\/.*?>/)) {
        const tagName = text.match(/<(.*)>.*?<\/.*?>/)?.[1];
        const tagContent = text.match(/<.*?>(.*?)<\/.*?>/)?.[1];

        switch (tagName) {
          case "email":
            return (
              <strong key={index}>
                <a href={`mailto:${tagContent}`}>{tagContent}</a>
              </strong>
            );
          case "strong":
          case "b":
            return <strong key={index}>{tagContent}</strong>;
          default:
            return tagContent;
        }
      }

      return text;
    });
  }, [children]);

  return (
    <div className={[className].join(" ")} {...containerProps}>
      {textTransformed}
    </div>
  )
}