import { Oval } from 'react-loader-spinner'
import styles from './CabTravelPath.module.scss';
import stylesRecap from '../../ItineraryRecapPage.module.scss';
import { Add, Trash } from '@assets/icons/icons';
import { useState } from 'react';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { ConfirmSectionType, RecapSectionType } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import { statusLabels } from 'src/helpers/statusLabels'
import Tag from '@components/Tag/Tag';
import { ApiAction } from 'src/models/ApiAction';
import { isSectionTypeConfirm, isSectionTypeRecap } from '../../../helpers';
import { isSelfCare } from 'src/Stores/AppContext';
import Button from '@components/Button/Button';
import { useConfirmAlert } from 'src/Stores/ConfirmAlertStore';
import formatDuration from 'src/helpers/duration';
import RecapTravelPathSection from '../../../Components/RecapTravelPathSection/RecapTravelPathSection';
import { useTranslation } from 'react-i18next';

export default function CabTravelPath({
  isTaxiBeforeMain = true,
  section,
  containerProps: { ...containerProps } = {},
}: {
  isTaxiBeforeMain?: boolean;
  section: RecapSectionType | ConfirmSectionType;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { confirm } = useConfirmAlert();
  const { callAction: callMissionAction } = useCurrentMission();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const callAction = async (action: ApiAction) => {
    setLoading(true);
    try {
      await callMissionAction(action);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const deleteTaxi = async () => {
    if (!isSectionTypeRecap(section)) {
      return false;
    }
    if (!section.actions.removeConnectingTaxi) {
      return;
    }

    const confirmed = await new Promise((resolve) => {
      confirm({
        title: t('confirmDeleteTaxiTitle'),
        message: t('confirmDeleteTaxiMessage'),
        confirmLabel: t('confirmLabel'),
        cancelLabel: t('cancelLabel'),
        onConfirm: async () => {
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
        layout: 'minimal'
      });
    });

    if (!confirmed) {
      return;
    }

    await callAction(section.actions.removeConnectingTaxi);
  }

  const addTaxi = async () => {
    if (!isSectionTypeRecap(section)) {
      return false;
    }
    if (!section.actions.addConnectingTaxi) {
      return;
    }

    const confirmed = await new Promise((resolve) => {
      confirm({
        title: t('confirmAddTaxiTitle'),
        message: isTaxiBeforeMain ? t('confirmAddTaxiBeforeMainMessage')
          : t('confirmAddTaxiAfterMainMessage'),
        confirmLabel: t('confirmLabel'),
        cancelLabel: t('cancelLabel'),
        onConfirm: async () => {
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
        layout: 'minimal'
      });
    });

    if (!confirmed) {
      return;
    }

    await Promise.all([
      callAction(section.actions.addConnectingTaxi)
    ]);
  }

  let rightContent: React.ReactNode = null;
  switch (true) {

    case loading:
      rightContent = (
        <div className={styles.travelInfoContainer}>
          <div className={styles.textInfosContainer}>
            <div className={styles.addContainer}>
               <Oval
                  height={18}
                  width={18}
                  color="black"
                  secondaryColor="black"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
            </div>
          </div>
        </div>
      );
      break;

    case isSelfCare() && isSectionTypeRecap(section) && !!section.actions?.addConnectingTaxi:
      rightContent = (
        <div className={styles.travelInfoContainer}>
          <div className={styles.textInfosContainer}>
            <Button
              type="secondary"
              label={(
                <div className={styles.btnContent}>
                  <Add />
                  {t('addTaxiButtonLabel')}
                </div>
              )}
              onClick={() => {
                addTaxi();
              }}
              containerProps={{
                className: styles.addTaxiButton
              }}
            />
          </div>
        </div>
      );
      break;

    case isSectionTypeRecap(section) && !!section.actions?.addConnectingTaxi:
      rightContent = (
        <div className={styles.travelInfoContainer}>
          <div className={styles.textInfosContainer}>
            <div className={styles.addContainer} onClick={() => {
              addTaxi();
            }}>
              <Add />
              {t('addTaxiButtonLabel')}
            </div>
          </div>
        </div>
      );
      break;

    case isSectionTypeConfirm(section):
    case isSectionTypeRecap(section) && !!section.actions.removeConnectingTaxi:
      rightContent = (
        <div className={styles.travelInfoContainer}>
          <div className='flex flex-row justify-start items-center'>
            <div className={styles.textInfosContainer}>
              <div className={styles.infoSecondaryLabel}>
                {section.durationMinutes} mins
              </div>
            </div>
          </div>

          {!isSelfCare() && section.statusWithLabel && section.statusWithLabel.isDisplayed && section.statusWithLabel.id && statusLabels[section.statusWithLabel.id] && (
            <Tag
              {...(section.statusWithLabel.href ? {
                onClick: () => {
                  window.open(section.statusWithLabel.href, '_blank');
                }
              } : {})}
              label={section.statusWithLabel.label}
              color={statusLabels[section.statusWithLabel.id]?.color || 'grey'}
              containerProps={{ className: styles.tagRecapStatus}}
            />
          )}

          {!isSelfCare() && !!section.price && (
            <div className={styles.priceContainer}>
              <div className={styles.price}>
                {section.priceIsEstimate ? t('estimatedPriceLabel') : ""}{section.price.formatted} {section.price.currency.symbol}
              </div>
              <a href="" onClick={(e) => {
                e.preventDefault();
                deleteTaxi();
                return false;
              }} className={styles.delete}>{t('deleteLinkText')}</a>
            </div>
          )}

          {isSelfCare() && isSectionTypeRecap(section) && section.actions?.removeConnectingTaxi && (
            <div className={styles.deleteBtnContainer}>
              <div className={styles.deleteBtnIcon} onClick={(e) => {
                e.preventDefault();
                deleteTaxi();
                return false;
              }}>
                <Trash />
              </div>
            </div>
          )}
        </div>
      );
      break;

    default:
      break;
  }

  return (
    <RecapTravelPathSection
      section={section}
      containerProps={containerProps}
      right={rightContent || (
          <div className={stylesRecap.travelInfoContainer}>
            <div className={stylesRecap.textInfosContainer}>
              <div className={stylesRecap.infoSecondaryLabel}>
                {formatDuration({
                  unit: 'minutes',
                  value: section.durationMinutes
                })}
              </div>
            </div>
          </div>
        )}
    />
  )
}