import styles from './LoginWithLicensePlate.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import InputText from '@components/Inputs/InputText/InputText';
import Button from '@components/Button/Button';
import { useEffect, useState } from 'react';
import FooterLinks from 'src/layout/SelfCare/Footer/FooterLinks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { publicApi } from 'src/Api/api';
import { AxiosError, AxiosResponse, isAxiosError } from 'axios';
import { MissionType } from 'src/models/Mission';
import { MissionContextType } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import { useAuthStoreLicensePlate } from 'src/Stores/AuthStoreLicensePlate';
import { useTranslation } from 'react-i18next';
import { Oval } from 'react-loader-spinner';
import { useConfirmAlert } from 'src/Stores/ConfirmAlertStore';

export default function LoginWithLicensePlate() {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();
  const { setAuthenticatedSelfCareLicencePlace } = useAuthStoreLicensePlate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const licensePlate = searchParams.get('licensePlate');
  const flow = searchParams.get('flow');
  const [loading, setLoading] = useState<boolean>(true);
  const [licencePlate, setLicensePlate] = useState<string>(licensePlate || "");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const {
    alert: confirmAlert,
  } = useConfirmAlert();

  useEffect(() => {
    publicApi.request({
      method: 'POST',
      url: '/auth/self-care/checkToken',
      data: {
        token
      }
    }).catch((error) => {
      confirmAlert({
        title: t('error'),
        message: error.response?.data?.error || t('error'),
        message_type: "error",
        layout: "minimal",
        confirmLabel: t('back_to_login'),
        onClose: () => {
          navigate('/self-care/login');
        }
      });
    }).finally(() => {
      setLoading(false);
    });
  }, [token, navigate, confirmAlert, t]);

  const onSubmit = async () => {
    setLoading(true);

    let response: AxiosResponse | null | void = null;

    try {
      response = await publicApi.request({
        method: 'POST',
        url: '/auth/self-care',
        data: {
          licencePlate,
          token
        }
      }).catch((error) => {
        if (!isAxiosError(error)) {
          setErrorMessage(t('invalid_license_plate'));
          throw error;
        }
        const axiosError = error as AxiosError<{error: string}>;
        if (axiosError.response?.status === 401) {
          if (axiosError.response?.data?.error) {
            confirmAlert({
              title: t('error'),
              message: error.response?.data?.error || t('error'),
              message_type: "error",
              layout: "minimal",
              confirmLabel: t('back_to_login'),
              onClose: () => {
                navigate('/self-care/login');
              }
            });
          }
          response = axiosError.response;
          return;
        }
        setErrorMessage(t('invalid_license_plate'));
        throw error;
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
      return;
    }

    setAuthenticatedSelfCareLicencePlace(true);

    try {
      if (!response) {
        throw new Error("No response");
      }

      const { data } = response as AxiosResponse<{
        context: MissionContextType | null;
        mission: MissionType | null;
      }>;

      if (!data.mission || !data.context) {
        throw new Error("No mission or missionContext");
      }

      const { mission } = data;

      // Navigate to cases/:caseId/missions/:missionId
      let redirectUrl = `/self-care/cases/${mission.caseID}/missions/${mission.id}`;

      switch (flow) {
        case 'refund':
          redirectUrl += '/refund';
          break;
        default:
          break;
      }
      navigate(redirectUrl);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      return;
    }
  };

  return (
    <PageContainer
      containerProps={{
        className: [styles.container, 'selfcare'].join(" "),
      }}
    >
      <HeaderSelfCare
        displayWarrantiesButton={false}
      />

      <div className={styles.content}>
        <div className={styles.title}>
          {t('enter_license_plate_prompt')}
        </div>

        <div className={styles.formContainer}>
          <InputText
            errorMsg={errorMessage}
            readOnly={false}
            inputProps={{
              placeholder: t('license_plate'),
              value: licencePlate,
              onChange: (e) => setLicensePlate(e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '')),
            }}
            containerProps={{
              className: 'w-full',
            }}
          />
        </div>

        <div className={styles.actions}>
          <Button
            onClick={onSubmit}
            type="primary"
            containerProps={{
              className: styles.actionButton,
              disabled: loading,
            }}
          >
            {loading ? <Oval height={15} width={15} color="#fff" /> : t('validate')}
          </Button>
        </div>
      </div>

      <div className={styles.footerContainer}>
        <FooterLinks
          containerProps={{
            className: styles.footer
          }}
        />
      </div>
    </PageContainer>
  );
}