import { RentalType, SearchVRentalsFiltersType } from "src/Stores/CurrentMissionTypes/MissionContextType";
import { FilterConfigSelect, FilterConfigSwitch, FilterSolution } from "./filterTypes";
import useFilter, { UseFilterReturnType } from "./useFilterV2";
import { useEffect, useMemo } from "react";
import { useKeyValueStore } from "src/Stores/KeyValueStore";

type FilterValueMapping = {
  [key: string]: {
    [key: string]: boolean;
  } | boolean;
}

type GetConfigArgs = {
  filterItems: Array<FilterSolution>;
  setFilterValues: React.Dispatch<React.SetStateAction<FilterValueMapping>>;
  filterValues: FilterValueMapping
};

export function getFilterIdFromRentalType(rental: RentalType): string {
  return rental.id;
}

function rentalToFilters(rentals: Array<RentalType>): Array<FilterSolution> {

  const filters = rentals.map((rental): FilterSolution => {
    return {
      filterId: getFilterIdFromRentalType(rental),
      vrental_dynamic_filters: {
        ...rental.filterValues,
        hasRemainingCosts: rental.remainingCosts ? true : false,
      },
    }
  });

  return filters;
}

export function getConfig({
  filterKey,
  label,
  options,
}: {
  filterKey: string;
  label: string;
  options?: Array<{
    label: string;
    value: string;
  }>;
}) {
  return ({
    filterValues,
    setFilterValues,
  }: GetConfigArgs ): FilterConfigSelect | FilterConfigSwitch => {
    if (filterKey === "noRemainingCosts") {
      return ({
        type: "switch",
        label,
        value: filterValues[filterKey] as boolean,
        onChange: (value: boolean) => {
          setFilterValues((prevFilterValues) => ({
            ...prevFilterValues,
            [filterKey]: value,
          }));
        }
      });
    }
    return  ({
      type: (filterKey === "noRemainingCosts") ? "switch" : "select",
      label,
      options: options || [],
      values: filterValues[filterKey] as FilterConfigSelect['values'],
      onChange: (value: any) => {
        setFilterValues((prevFilterValues) => ({
          ...prevFilterValues,
          [filterKey]: value,
        }));
      }
    });
  }
}

export default function useFilterVRentals(
  rentals: Array<RentalType>,
  filters: SearchVRentalsFiltersType,
  options?: {
    filterStoreKey?: string;
  }
): UseFilterReturnType<FilterValueMapping> {

  const filterNoRemainingCostStoreKey = `filter_noRemainingCosts_${options?.filterStoreKey || ""}`;
  const {
    setKeyValue,
    values: keyValueValues,
  } = useKeyValueStore();
  const filterNoRemainingCostStoreValue = keyValueValues[filterNoRemainingCostStoreKey];

  const defaultFilterValues: {
    noRemainingCosts?: boolean;
  } & {
    [key: string]: FilterConfigSelect['values'];
  } = {};

  const filterConfig: {
    noRemainingCosts?: (args: GetConfigArgs) => FilterConfigSwitch;
  } & {
    [key: string]: (args: GetConfigArgs) => FilterConfigSelect | FilterConfigSwitch;
  } = {};

  Object.keys(filters).map((filterKey) => {
    const filter = filters[filterKey];
    filterConfig[filterKey] = getConfig({
      filterKey,
      label: filter.label,
      options: filter.options,
    });
    if (filterKey === "noRemainingCosts") {
      defaultFilterValues[filterKey] = filterNoRemainingCostStoreValue ?? (filters[filterKey]?.defaultValue ?? true);
    } else {
      defaultFilterValues[filterKey] = (filter.options || []).map((option) => option.value).reduce((acc: { [key: string]: boolean }, value) => {
        acc[value] = filterKey === 'energy';
        return acc;
      }, {});
    }
  });

  const filterItems = useMemo(() => rentalToFilters(rentals), [rentals]);
  const useFilterValue = useFilter<FilterValueMapping, {[key: string]: boolean}>({
    filterItems,
    filterConfig,
    defaultFilterValues,

    getTags: ({
      filterValues,
      setFilterValues,
    }) => {
      const tags = {
        ...Object.keys(filterValues).map((filterKey) => {
          return Object.keys(filterValues[filterKey]).map((filterValueKey) => {
            if (filterKey === 'noRemainingCosts') {
              return null;
            }
            const filterValuesKey = filterValues[filterKey] as { [key: string]: boolean; };
            const filterValueValue = filterValuesKey[filterValueKey]
            if (filterValueValue === false) {
              return null;
            }
            return {
              label: (filters[filterKey].options || []).find((option) => option.value === filterValueKey)?.label || '',
              onClickRemove: () => {
                setFilterValues((prevFilterValues) => ({
                  ...prevFilterValues,
                  [filterKey]: {
                    // eslint-disable-next-line
                    // @ts-ignore
                    ...prevFilterValues[filterKey],
                    [filterValueKey]: false,
                  },
                }));
              },
            }
          }).filter(p => !!p).reduce((acc, value) => {
            return {
              ...acc,
              [value?.label || '']: value,
            }
          }, {});
        }).reduce((acc, value) => {
          return {
            ...acc,
            ...value,
          }
        }, {}),
      };

      return tags;
    },

    countValue: (filterValue: {
      [key: string]: boolean;
    }) => {
      let count = 0;
      Object.keys(filterValue).forEach((key) => {
        if (filterValue[key]) {
          count++;
        }
      });
      return count;
    },
    itemMatchesValue
  });

  useEffect(() => {
    setKeyValue(filterNoRemainingCostStoreKey, useFilterValue.appliedFilterValues.noRemainingCosts);
  }, [setKeyValue, filterNoRemainingCostStoreKey, useFilterValue.appliedFilterValues.noRemainingCosts]);

  return useFilterValue;
}


function itemMatchesValue(filterItem: FilterSolution, filterKey: string, filterValues: { [key: string]: boolean; }) {

  if (filterKey === 'noRemainingCosts') {
    if (!filterValues) {
      return true;
    }

    return filterItem.vrental_dynamic_filters?.hasRemainingCosts ? false : true;
  }

  if (typeof filterValues === 'boolean') {

    if (filterValues) {
      return true;
    }

    if (!filterItem.vrental_dynamic_filters) {
      return false;
    }

    return filterItem.vrental_dynamic_filters[filterKey] === filterValues;
  }

  if (Object.keys(filterValues).filter((value) => filterValues[value]).length === 0) {
    return true;
  }

  return Object.keys(filterValues).map((filterValueKey) => {
    const filterValueValue = filterValues[filterValueKey];

    if (!filterItem.vrental_dynamic_filters || !filterItem.vrental_dynamic_filters[filterKey]) {
      return false;
    }

    if (filterValueKey === filterItem.vrental_dynamic_filters[filterKey] && filterValueValue) {
      return true;
    }

    return false;
  }).reduce((acc, value) => {
    return acc || value;
  }, false);
}