import { AgencyType, RentalType } from "src/Stores/CurrentMissionTypes/MissionContextType";

export type RentalSolutionPoint = {
  rentalId: string;
  agencyId: string;
  lat: number;
  lng: number;
  nbOccurences: number;
  solutionsIds: Array<string>;
  label: string;
  infoLayer: {
    label: string;
    content: React.ReactNode;
  };
};

export function getRentalId(rental: RentalType): string {
  return rental.id;
}

export function getPointsFromRentals(rentals: Array<RentalType>, agencies: Array<AgencyType>): Array<RentalSolutionPoint> {

  const points: { [key: string]: RentalSolutionPoint } = {};

  for (const rental of rentals) {
    const agency = agencies.find(agency => agency.id === rental.agencyID);
    if (!agency || !agency.place) {
      continue;
    }
    const key = `${agency.id}`;
    if (!points[key]) {
      points[key] = {
        rentalId: agency.id,
        agencyId: agency.id,
        lat: agency.place.latitude,
        lng: agency.place.longitude,
        nbOccurences: 0,
        label: "",
        infoLayer: {
          label: rental.agencyLabel,
          content: ""
        },
        solutionsIds: []
      };
    }

    points[key].nbOccurences += 1;
    points[key].label = `${points[key].nbOccurences}`;
    points[key].solutionsIds.push(getRentalId(rental));
  }

  return Object.values(points);
}