import styles from './ExpandableText.module.scss';
import useExpandableTextHelpers from './useExpandableTextHelpers';

export default function ExpandableText({
  title,
  separator = true,
  children,
  allClickable = false,
  containerProps: { className, ...containerProps } = {},
  titleContainerProps,
  contentContainerProps,
}: {
  title: string;
  separator?: boolean;
  allClickable?: boolean;
  children: React.ReactNode;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  titleContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  contentContainerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const {
    titleComponent,
    contentComponent

  } = useExpandableTextHelpers({
    title,
    allClickable: allClickable,
    separator: separator,
    content: children,
    titleContainerProps,
    contentContainerProps,
  });

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>

      {titleComponent}
      {contentComponent}

    </div>
  )
}