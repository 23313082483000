import ReactModal from 'react-modal';
import styles from './ModalPreviewMobile.module.scss';

export default function ModalPreviewMobile({
  modalProps,
  onRequestClose,
  containerProps: { className, ...containerProps } = {},
}: {
  modalProps: any;
  onRequestClose?: () => any;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  return (
    <ReactModal
      isOpen
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,.8)",
          zIndex: 8000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          border: "none",
          padding: 0,
          inset: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          borderRadius: 10,
          maxHeight: "calc(100vh - 40px)",
          // white
          boxShadow: "rgb(0, 0, 0) 0px 4px 15px 8px",
        }
      }}
      onRequestClose={() => {
        onRequestClose && onRequestClose();
      }}
    >
      <div className={[styles.container, className].join(" ")} {...containerProps}>
        {modalProps?.url && (
          <iframe src={modalProps.url} title="preview" className={styles.iframe} />
        )}
      </div>
    </ReactModal>
  )
}