import { create } from 'zustand'

export const useAppContext = create<{
  isSelfCare: boolean;
  setIsSelfCare: (isSelfCare: boolean) => void;
}>((set) => ({
  isSelfCare: false,
  setIsSelfCare: (isSelfCare) => set({ isSelfCare }),
}));

export function isSelfCare() {
  return useAppContext.getState().isSelfCare;
}