import { Calendar } from '@assets/icons/icons';
import styles from './DatePicker.module.scss';
import React from 'react';
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export default function DatePicker({
  disabled,
  readOnly,
  label,
  placeholder,
  errorMsg,
  date,
  minDate,
  maxDate,
  onChange,
  containerProps: { className, ...containerProps } = {},
  datePickerContainerProps: { className: datePickerContainerClassName, ...datePickerContainerProps } = {},
  datePickerProps,
  testid,
}: {
  disabled?: boolean;
  readOnly?: boolean;
  label?: string;
  placeholder?: string;
  errorMsg?: string;
  date?: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  onChange?: (date: Date | null) => any;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  datePickerContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  datePickerProps?: Omit<ReactDatePicker["props"], 'onChange'>;
  testid?: string;
}) {

  return (
    <div
      data-testid={testid}
      {...containerProps}
      className={[styles.container, disabled ? styles.disabled : "", className].join(" ")}
    >

      {(label || errorMsg) && (
        <div className={styles.labelContainer}>
          {label && <div className={styles.label}>{label}</div>}
          {errorMsg && <div className={styles.errorMsg}>{errorMsg}</div>}
        </div>
      )}

      <div {...datePickerContainerProps} className={`${datePickerContainerClassName}`}>
        <ReactDatePicker
          {...datePickerProps}
          disabled={disabled || readOnly}
          selected={date}
          minDate={minDate}
          maxDate={maxDate}
          placeholderText={placeholder || "Selectionnez une date"}
          onChange={(date) => {
            onChange && onChange(date)
          }}
          customInput={(
            <div className={styles.input}>
              <div className={styles.value}>{date?.toLocaleDateString() || placeholder || "Selectionnez une date"}</div>
              <Calendar />
            </div>
          )}
        />
      </div>

    </div>
  )
}