import styles from "./styles.module.scss";
import stylesForm from "../../Form.module.scss";
import Select from "@components/Select/Select";
import Switch from "@components/Switch/Switch";
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import { useForm, Controller } from "react-hook-form";
import { useCurrentMission } from "src/Stores/CurrentMission";
import { TransportTypes } from "src/Stores/CurrentMissionTypes/Types";
import { useTranslation } from "react-i18next";

type FormData = {
  taxiOptions: { [key: string]: any };
  vrentalOptions: { [key: string]: any };
};

export default function ModalOptions({
  readOnly,
  onRequestClose,
  onChange,
  options,
  containerProps: { className, ...containerProps } = {},
  transportTypes,
}: {
  readOnly?: boolean;
  onRequestClose?: () => any;
  onChange?: (options: FormData) => any;
  options: FormData;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  transportTypes: { [key in TransportTypes]?: boolean };
}) {
  const { control, handleSubmit } = useForm();
  const { missionContext } = useCurrentMission();
  const taxiOptions = missionContext?.steps?.search?.form?.taxiOptions;
  const vrentalOptions = missionContext?.steps?.search?.form?.vrentalOptions;
  const { t } = useTranslation();

  const onSubmit = (data: any) => {
    const formattedData: {
      taxiOptions: { [key: string]: any };
      vrentalOptions: { [key: string]: any };
    } = {
      taxiOptions: {},
      vrentalOptions: {},
    };

    Object.keys(data).forEach((key) => {
      if (key.startsWith("taxi_")) {
        const optionName = key.replace("taxi_", "");
        formattedData.taxiOptions[optionName] = data[key];
      } else if (key.startsWith("vrental_")) {
        const optionName = key.replace("vrental_", "");
        formattedData.vrentalOptions[optionName] = data[key];
      }
    });
    onChange && onChange(formattedData);
    onRequestClose && onRequestClose();
  };

  return (
    <Modal
      onRequestClose={onRequestClose}
      title={t('additional_options')}
    >
      <div
        className={[
          stylesForm.form,
          styles.optionsModalContainer,
          className,
        ].join(" ")}
        {...containerProps}
      >
        {transportTypes.vrental && (
          <>
            <div
              className={[stylesForm.formRow, stylesForm.formTitleRow].join(
                " "
              )}
            >
              <div className={stylesForm.formCol}>
                {t('vrental_filter_title')}
              </div>
            </div>

            {/* TODO: fix this vrentalOptions is not an array */}
            {vrentalOptions?.map((vrentalOption: any) => {
              switch (vrentalOption.type) {
                case "select":
                  return (
                    <div className={stylesForm.formRow}>
                      <Controller
                        name={`vrental_${vrentalOption.name}`}
                        control={control}
                        defaultValue={options.vrentalOptions[vrentalOption.name]}
                        render={({ field }) => (
                          <Select
                            containerProps={{
                              className: styles.fullWidth,
                            }}
                            disabled={readOnly}
                            placeholder={vrentalOption.placeholder}
                            optionsV2={vrentalOption.options.map((option: any) => ({
                              value: option.value,
                              label: option.label,
                              searchValue: option.label,
                            }))}
                            selectedIndex={vrentalOption.options.findIndex(
                              (option: any) => option.value === field.value
                            )}
                            onChangeIndex={(selectedIndex) => {
                              if (selectedIndex === null) {
                                field.onChange(null);
                                return;
                              }
                              field.onChange(
                                vrentalOption.options[selectedIndex].value
                              );
                            }}
                          />
                        )}
                      />
                    </div>
                  );

                case "checkbox":
                  return (
                    <div className={stylesForm.formRow}>
                      <div className={[stylesForm.formCol].join(" ")}>
                        <span className={stylesForm.formLabelNormal}>
                          {vrentalOption.label}
                        </span>
                      </div>
                      <div className={[stylesForm.formCol].join(" ")}>
                        <Controller
                          name={`vrental_${vrentalOption.name}`}
                          control={control}
                          defaultValue={options.vrentalOptions[vrentalOption.name]}
                          render={({ field }) => (
                            <Switch
                              disabled={readOnly}
                              checked={field.value}
                              onChange={(checked) => field.onChange(checked)}
                            />
                          )}
                        />
                      </div>
                    </div>
                  );

                default:
                  return null;
              }
            })}
          </>
        )}

        {transportTypes.taxi && (
          <>
            <div
              className={[stylesForm.formRow, stylesForm.formTitleRow].join(
                " "
              )}
            >
              <div className={stylesForm.formCol}>{t('taxi_filter_title')}</div>
            </div>


            {/* TODO: fix this taxiOptions is not an array */}
            {taxiOptions?.map((taxiOption: any) => {
              switch (taxiOption.type) {
                case "select":
                  return (
                    <div className={stylesForm.formRow}>
                      <Controller
                        name={`taxi_${taxiOption.name}`}
                        control={control}
                        defaultValue={options.taxiOptions[taxiOption.name]}
                        render={({ field }) => (
                          <Select
                            containerProps={{
                              className: styles.fullWidth,
                            }}
                            disabled={readOnly}
                            placeholder={taxiOption.placeholder}
                            optionsV2={taxiOption.options.map((option: any) => ({
                              value: option.value,
                              label: option.label,
                              searchValue: option.label,
                            }))}
                            selectedIndex={taxiOption.options.findIndex(
                              (option: any) => option.value === field.value
                            )}
                            onChangeIndex={(selectedIndex) => {
                              if (selectedIndex === null) {
                                field.onChange(null);
                                return;
                              }
                              field.onChange(
                                taxiOption.options[selectedIndex].value
                              );
                            }}
                          />
                        )}
                      />
                    </div>
                  );

                case "checkbox":
                  return (
                    <div className={stylesForm.formRow}>
                      <div className={[stylesForm.formCol].join(" ")}>
                        <span className={stylesForm.formLabelNormal}>
                          {taxiOption.label}
                        </span>
                      </div>
                      <div className={[stylesForm.formCol].join(" ")}>
                        <Controller
                          name={`taxi_${taxiOption.name}`}
                          control={control}
                          defaultValue={options.taxiOptions[taxiOption.name]}
                          render={({ field }) => (
                            <Switch
                              disabled={readOnly}
                              checked={field.value}
                              onChange={(checked) => field.onChange(checked)}
                            />
                          )}
                        />
                      </div>
                    </div>
                  );

                default:
                  return null;
              }
            })}
          </>
        )}

        {readOnly ? (
          <div className={styles.bottomBtnContainer}>
            <Button
              label={t('close')}
              type="secondary"
              containerProps={{
                className: stylesForm.buttonCancel,
              }}
              onClick={() => onRequestClose && onRequestClose()}
            />
          </div>
        ) : (
        <div className={styles.bottomBtnContainer}>
          <Button
            label={t('confirm')}
            containerProps={{
              className: stylesForm.buttonSubmit,
            }}
            onClick={handleSubmit(onSubmit)}
          />

          <Button
            label={t('cancel')}
            type="secondary"
            containerProps={{
              className: stylesForm.buttonCancel,
            }}
            onClick={() => onRequestClose && onRequestClose()}
          />
        </div>
        )}
      </div>
    </Modal>
  );
}