import React from 'react';

import styles from './VRentalSteps.module.scss';
import { RentalTypeInSolution } from 'src/Stores/CurrentMissionTypes/MissionContextType';

export default function VRentalSteps({
  vrentals,
  showRemainingCosts,
  containerProps: { className, ...containerProps } = {},
}: {
  vrentals: Array<RentalTypeInSolution>;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  showRemainingCosts: boolean;
}) {
  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>
      {vrentals.filter((vrental) => {
        const noRemainingCosts = !vrental.remainingCosts || vrental.remainingCosts.amount === 0
        return showRemainingCosts || noRemainingCosts
      }).map((vrental) => (
        <VRentalStep
          key={vrental.id}
          vrental={vrental}
        />
      ))}
    </div>
  )
}


function VRentalStep({
  vrental
}: {
  vrental: RentalTypeInSolution;
}) {
  return (
    <div className={styles.vrentalContainer}>

      <div className={styles.vrentalImgContainer}>
        {vrental.renterImgURL && (
          <img src={vrental.renterImgURL} className={styles.agencyImage} alt="rental agency" />
        )}
        {vrental.imgURL && (
          <img src={vrental.imgURL} alt="car" />
        )}
      </div>

      <div className={styles.vrentalInfoContainer}>
        <div className={styles.vrentalInfoTitle}>
          {vrental.label}
        </div>
        <div className={styles.vrentalOptions}>
          {vrental.descriptions?.map((description, i) => (
            <div key={i} className={styles.vrentalOption}>
              {description}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.vrentalPrice}>
        {vrental.price.formatted} {vrental.price.currency.symbol}
      </div>
    </div>
  )
}