import { useTranslation } from 'react-i18next';
import VerticalSteps, { Step } from "@components/VerticalSteps/VerticalSteps";
import styles from './CurrentMission.module.scss';
import { useMediaQuery } from "usehooks-ts";
import { useCurrentMission } from "src/Stores/CurrentMission";
import { Chevron } from "@assets/icons/icons";
import useCurrentMissionNavigation from 'src/Hooks/useCurrentMissionNavigation';

export default function CurrentMissionVerticalSteps({
  displayBackButton = false,
  containerProps: { className, ...containerProps } = {},
}: {
  displayBackButton?: boolean;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const { t } = useTranslation();

  const isLessThan1200 = useMediaQuery('(max-width: 1200px)');
  const { mission, missionContext } = useCurrentMission();

  const { currentActiveStepIndex, canGoBack, onClickVerticalStepIndex, missionContextGoBack } = useCurrentMissionNavigation();

  if (!mission || !missionContext) {
    return null;
  }

  const currentStepIndex = missionContext?.steps?.confirmation ? 4 : missionContext.progressBar.currentIndex;
  const verticalSteps: Array<Step> = [];

  missionContext.progressBar.steps.forEach((step, i) => {

    const isEnable = i <= currentStepIndex;

    verticalSteps.push({
      ...step,
      isEnable,
      canClick: isEnable,
      isCurrent: i === currentActiveStepIndex,
    })
  });

  return (
    <div className={[styles.verticalStepsContainer, displayBackButton ? styles.withBackButton : "", className].join(" ")} {...containerProps}>

      {displayBackButton && canGoBack() && (
      <div className={styles.backButtonContainer}>
        <div className={styles.btnBack} onClick={missionContextGoBack}>
          <Chevron /> <div className={styles.label}>{t('back_button')}</div>
        </div>
      </div>
      )}

      <VerticalSteps
        containerProps={{
          className: styles.verticalSteps,
        }}
        displayLabels={!isLessThan1200}
        steps={verticalSteps}
        // onClickStep={onClickStep}
        onClickStepIndex={onClickVerticalStepIndex}
      />
    </div>
  )
}