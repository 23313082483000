import React from 'react';
import styles from './RecapDateTime.module.scss';
import { format } from 'date-fns';
import formatDuration from 'src/helpers/duration';
import { useTranslation } from 'react-i18next';

export default function RecapDateTime({
  isTaxiOnly,
  validUntil,
  validUntilDate,
  optionIsToday,
  displayDuration = true,
  recapDate,
  durationMinutes,
  containerProps: { className, ...containerProps } = {},
}: {
  isTaxiOnly: boolean;
  validUntil: string;
  validUntilDate: Date;
  optionIsToday: boolean;
  displayDuration?: boolean;
  recapDate: Date;
  durationMinutes: number;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const { t } = useTranslation();

  return (
    <div className={[styles.recapDateTime, className].join(" ")} {...containerProps}>
      <div>
        <div className={styles.recapDate}>
          {format(recapDate, "PPPP à HH:mm")}
        </div>
        {displayDuration && (<div className={styles.recapTime}>
          {t('duration')}: {formatDuration({
            unit: 'minutes',
            value: durationMinutes,
          })}
        </div>)}
      </div>
      {!isTaxiOnly && validUntil && (
        <div className={styles.validUntil}>

          {optionIsToday ? (
            <p className={styles.validUntilText}>
              {t('option_valid_until_hour')}{" "}
              {validUntilDate.toLocaleTimeString(navigator.language, {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
          ) : (
            <p className={styles.validUntilText}>
              {t('option_valid_until_day')}{" "}
              {validUntilDate.toLocaleDateString(navigator.language, {
                day: "2-digit",
                month: "short",
              })}{" "}
              {validUntilDate.toLocaleTimeString(navigator.language, {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
          )}

        </div>
      )}
    </div>
  )
}
