import { CustomLanguageFormats, locales } from '@ory/elements';

export const customTranslations: CustomLanguageFormats = {
  fr: {
    ...locales.fr,
    'login.title': 'Connexion',
    'registration.title': 'Créer votre compte',
  },
  en: {
    ...locales.en,
    'login.title': 'Login',
    'registration.title': 'Register',
  },
};
