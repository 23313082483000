import stylesForm from "@components/Form/Form.module.scss";
import styles from "./ModalShortenVRental.module.scss";
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import { useCurrentMission } from "src/Stores/CurrentMission";
import DatePicker from "@components/DatePicker/DatePicker";
import HourPicker from "@components/HourPicker/HourPicker";
import { useMemo, useState } from "react";
import { Oval } from "react-loader-spinner";
import { format } from "date-fns";
import { VRentalRecapType } from "src/Stores/CurrentMissionTypes/MissionContextType";
import { useTranslation } from "react-i18next";
import { CheckNotValid, CheckValid } from "@assets/icons/icons";
import AlertBox from "@components/AlertBox/AlertBox";
import { Alert } from "src/models/Alert";
import AgencyInfos from "../AgencyInfos/AgencyInfos";

const isSameDay = (date1: Date | null, date2: Date | null) => {
  if (date1 === null || date2 === null) return false;
  return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
};

export default function ModalShortenVRental({
  onRequestClose,
  alert,
  rental,
  containerProps: { className, ...containerProps } = {},
}: {
  onRequestClose?: () => any;
  alert?: Alert;
  rental: VRentalRecapType;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();
  const { callAction, refetchMission } = useCurrentMission();

  const initialEndDate = useMemo(() => {
    const currentDatePlusOneHour = new Date();
    currentDatePlusOneHour.setHours(currentDatePlusOneHour.getHours() + 1);

    const rentalEndDate = new Date(rental.endDateTime);

    if (rentalEndDate < currentDatePlusOneHour) {
      return currentDatePlusOneHour;
    }
    return rentalEndDate;
  }, [rental.endDateTime]);

  const dateStart = new Date(rental.startDateTime);
  const dateEnd = new Date(rental.endDateTime);

  const [extendActionLoading, setExtendActionLoading] = useState(false);

  const [endDate, setEndDate] = useState<Date>(initialEndDate);
  const [endHour, setEndHour] = useState<number>(initialEndDate.getHours());
  const [endMinutes, setEndMinutes] = useState<number>(initialEndDate.getMinutes());

  const minDate = rental.shortenVrentalLimits?.start ? new Date(rental.shortenVrentalLimits.start) : null;
  const maxDate = rental.shortenVrentalLimits?.end ? new Date(rental.shortenVrentalLimits.end) : null;

  const isSameDayAndHour = isSameDay(initialEndDate, endDate) && initialEndDate.getHours() === endHour;

  const extendRental = () => {
    if (!rental.shortenVrentalAction || !callAction) return;
    if (endDate === null || endHour === null) return;

    const dateCloned = new Date(endDate);
    dateCloned.setHours(endHour);
    dateCloned.setMinutes(0);
    dateCloned.setSeconds(0);
    const newDropoffTime =
      dateCloned.getFullYear() +
      "-" +
      `${dateCloned.getMonth() + 1}`.padStart(2, "0") +
      "-" +
      `${dateCloned.getDate()}`.padStart(2, "0") +
      "T" +
      `${dateCloned.getHours()}`.padStart(2, "0") +
      ":00:00";

    setExtendActionLoading(true);
    callAction(rental.shortenVrentalAction, {
      newDropoffTime,
    }, {
      returnData: true,
    })
      .then(() => {
        setIsConfirmed(true);
      })
      .catch(() => {
        setHasError(false);
      })
      .finally(() => {
        setExtendActionLoading(false);
      });
  };

  return (
    <Modal

      onRequestClose={onRequestClose} title={(
        <>
          {hasError ? (
            <div className={styles.confirmationTitleContainer}>
              <CheckNotValid width={40} height={40} />
              <div className={styles.confirmationTitle}>{t("editRentalErrorTitle")}</div>
            </div>
          ) : (
            isConfirmed ? (
              <div className={styles.confirmationTitleContainer}>
                <CheckValid width={40} height={40} />
                <div className={styles.confirmationTitle}>{t("editRentalConfirmationTitle")}</div>
              </div>
            ) : t("editRentalModalTitle")
          )}
        </>
    )}>

      {(hasError || isConfirmed) && (
      <div className={[styles.modalContainerConfirm, className].join(" ")} {...containerProps}>
        <Button
          label={t("close")}
          type="primary"
          containerProps={{
            className: `${stylesForm.buttonCancel} w-full`,
            disabled: extendActionLoading,
          }}
          onClick={async () => {
            setExtendActionLoading(true);
            await refetchMission();
            onRequestClose && onRequestClose()
          }}
        />
      </div>
      )}


      {!(isConfirmed || hasError) && (
      <div className={[stylesForm.form, styles.modalContainer, className].join(" ")} {...containerProps}>

        {!!alert && (<AlertBox
          type={alert.severity}
          text={alert.texts}
          displayIcon
          containerProps={{
            style: {
              marginTop: 'px',
              marginBottom: '16px',
            }
          }}
        />)}


        <div className={styles.currentRentalInfos}>
          {t("currentRentalInfo", {
            numberOfDays: rental.numberOfDays,
            plural: rental.numberOfDays > 1 ? "s" : "",
            start: format(dateStart, "dd/MM HH:mm"),
            end: format(dateEnd, "dd/MM HH:mm"),
          })}
        </div>

        <div className="mb-6 flex items-start self-start">
          <AgencyInfos
            agency={rental.arrivalAgency || rental.departureAgency}
          />
        </div>


        <div className={styles.extendRentalTitle}>
          {t("editRentalTitle")}
        </div>

        <div className={stylesForm.formRow}>
          <div className={stylesForm.formCol}>
            <div className={[styles.dateContainer].join(" ")}>
              <DatePicker
                testid="date-picker-startdate"
                disabled={extendActionLoading}
                date={endDate}
                onChange={(date) => {
                  if (!date) return;

                  setEndDate(date);
                  if (
                    isSameDay(date, minDate) &&
                    endHour !== null &&
                    endHour < (minDate?.getHours() || 0)
                  ) {
                    setEndHour(minDate?.getHours() || 0);
                  }

                  if (
                    isSameDay(date, maxDate) &&
                    endHour !== null &&
                    endHour > (maxDate?.getHours() || 23)
                  ) {
                    setEndHour(maxDate?.getHours() || 23);
                  }
                }}
                maxDate={maxDate}
                minDate={minDate}
              />
              <div className={styles.dateHourSeparator}>{t('at')}</div>
              <HourPicker
                testid="hour-picker-startdate"
                disabled={extendActionLoading}
                hour={endHour}
                fixed
                minutes={endMinutes}
                minHour={
                  minDate ? (isSameDay(minDate, endDate) ? minDate.getHours() : 0) : 0
                }
                minMinutes={
                  minDate ?
                  (isSameDay(minDate, endDate) && minDate.getHours() === endDate.getHours() ? minDate.getMinutes() : 0)
                  : 0
                }
                maxHour={
                  maxDate ?
                  (isSameDay(maxDate, endDate) ? maxDate.getHours() : 23)
                  : 23
                }
                maxMinutes={
                  maxDate ?
                  (isSameDay(maxDate, endDate) && maxDate.getHours() === endDate.getHours() ? maxDate.getMinutes() : 59)
                  : 59
                }
                onChange={({ hour, minutes }) => {
                  if (hour === null || minutes === null) return;
                  setEndHour(hour);
                  setEndMinutes(minutes);
                }}
              />
            </div>
          </div>
        </div>

        <Button
          containerProps={{
            className: stylesForm.buttonSubmit,
            disabled:
              extendActionLoading ||
              isSameDayAndHour ||
              endDate === null ||
              endHour === null,
          }}
          onClick={extendRental}
        >
          {extendActionLoading ? (
            <Oval
              height={20}
              width={20}
              color="white"
              secondaryColor="white"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            t("confirm")
          )}
        </Button>

        <Button
          label={t("cancel")}
          type="secondary"
          containerProps={{
            className: stylesForm.buttonCancel,
            disabled: extendActionLoading,
          }}
          onClick={() => onRequestClose && onRequestClose()}
        />
      </div>
      )}
    </Modal>
  );
}