import Modal from '@components/Modal/Modal';
import styles from './ModalEditCreateCrud.module.scss';
import { useTranslation } from 'react-i18next';
import withCrudMetadata from '../withCrudMetadata';
import { CrudMetadata, Entity, EntityItem } from '../types';
import CrudEditCreateForm from './CrudEditCreateForm';
import { useState } from 'react';
import AlertBox from '@components/AlertBox/AlertBox';
import Button from '@components/Button/Button';


const ModalEditCreateCrud = withCrudMetadata(({
  formType,
  entityItem,
  entity,
  metadata,
  onSaved,
  onRequestClose,
}: {
  formType: 'edit' | 'create';
  entity: Entity;
  metadata: CrudMetadata;
  entityItem?: EntityItem;
  onSaved?: () => void | Promise<void>;
  onRequestClose: () => void;
}) => {

  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();
  const layout = "default";

  return (
    <Modal
      title={(formType === 'edit' ? t('edit') : t('create')) + ' ' + entity.label}
      containerProps={{
        style: {
          maxWidth: 1200,
          minWidth: 500,
        },
        className: `${styles.modalConfirmContainer} ${layout ? styles[layout] : ""}`
      }}
      contentContainerProps={{
        className: styles.modalContentContainer
      }}

      onRequestClose={onRequestClose}
      layout={layout}
      style={{
        content: {
          overflow: "visible",
        }
      }}
    >
      {success && (
        <div className={'w-full p-4'}>
          <AlertBox
            displayIcon
            type="success"
            text={entityItem ? t("update_success") : t("create_success")}
            containerProps={{
              className: "w-full mb-4",
            }}
          />

          <Button
            label={t("close")}
            type="primary"
            containerProps={{
              className: "w-full",
            }}
            onClick={() => onRequestClose && onRequestClose()}
          />
        </div>
      )}

      {!success && (
      <div className={styles.formContainer}>
        <CrudEditCreateForm
          formType={formType}
          entity={entity}
          entityItem={entityItem}
          metadata={metadata}
          onSaved={async () => {
            setSuccess(true);
            onSaved && await onSaved();
          }}
        />
      </div>
      )}
    </Modal>
  )
});

export default ModalEditCreateCrud;