import styles from './Header.module.scss';
import { ChevronSelfCare } from '@assets/icons/icons';
import { useCurrentMission } from 'src/Stores/CurrentMission';

export default function BackBtn({
  onClick,
  containerProps: { className, ...containerProps } = {},
}: {
  onClick?: () => any;
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {

  const { missionContextGoBack, canGoBack } = useCurrentMission();

  return (
    <div
      {...containerProps}
      className={[styles.back, className].join(" ")}
      onClick={() => {
        if (onClick) {
          onClick();
        } else if (canGoBack()) {
          missionContextGoBack();
        }
      }}
    >
      <ChevronSelfCare />
    </div>
  )
}