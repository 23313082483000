import { useTranslation } from 'react-i18next';
import styles from './AddReceipt.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import Button from '@components/Button/Button';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import receipt_image from './images/transport.svg';
import { ScreenType } from './ScreensTypes';

export default function AddReceiptSelfCare({
  navigate
}: {
  navigate: (screen: ScreenType) => void;
}) {

  const { mission } = useCurrentMission();
  const { t } = useTranslation();

  if (!mission) {
    throw new Error("Mission not found")
  }

  return (
    <PageContainer
      containerProps={{
        className: [styles.container, 'selfcare'].join(" "),
      }}
    >

      <HeaderSelfCare
      />

      <div className={styles.content}>

        <div className={styles.title}>
          {t('welcome_message', { clientFirstname: mission?.clientFirstname, clientLastname: mission?.clientLastname })}
        </div>

        <p>
          {t('add_receipt_info')}
        </p>

        <div className={styles.imgInfoContainer}>
          <img src={receipt_image} alt="Illustration add receipt" />
        </div>

        <div className={styles.actions}>
          <Button
            label={t('refund_request_button')}
            onClick={() => navigate("receipt")}
            type="primary"
            containerProps={{
              className: styles.actionButton,
            }}
          />
        </div>

      </div>

    </PageContainer>
  )
}