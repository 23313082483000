import styles from './NotCovered.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import Button from '@components/Button/Button';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { useNavigate } from 'react-router-dom';
import { displayPrice } from 'src/helpers/price';
import { useTranslation } from 'react-i18next';

export default function NotCoveredStepReceiptSelfCare() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { missionContext } = useCurrentMission();
  const refundFlow = missionContext?.refundFlow;

  return (
    <PageContainer
      containerProps={{
        className: [styles.container, 'selfcare'].join(" "),
      }}
    >
      <HeaderSelfCare
        title={t('refund_information')}
        displayBackButton={false}
      />

      <div className={styles.content}>
        <div className={`flex flex-col justify-center w-full`}>
          <div className={`flex flex-row justify-between align-center`} style={{ marginTop: "16px" }}>
            <div className={styles.labelTitle}>{t('service')}</div>
            <div className={styles.valueTitle}>{refundFlow?.benefits?.find(b => b.value === refundFlow?.selectedBenefit)?.name || ""}</div>
          </div>

          <div className={`flex flex-row justify-between align-center ${styles.labelValueBold}`} style={{ marginTop: "16px" }}>
            <div className={styles.labelTitle}>{t('refunded_amount')}</div>
            <div className={styles.valueTitle}>{displayPrice(refundFlow?.coveredAmount)}</div>
          </div>

          <div className={`flex flex-row justify-between align-center`} style={{ marginTop: "16px" }}>
            <div className={styles.labelTitle}>{t('balance_charge')}</div>
            <div className={styles.valueTitle}>{displayPrice(refundFlow?.remainingCosts)}</div>
          </div>

          <div className={`flex flex-row justify-between align-center ${styles.lineConditions}`} style={{ marginTop: "16px" }}>
            {t('conditional_acceptance')}
          </div>
        </div>

        <div className={styles.text} style={{ marginTop: "40px" }}>
          {t('not_covered_notice')}
        </div>

        <div className={styles.actions}>
          <Button
            label={t('return_home')}
            onClick={() => {
              navigate("/self-care");
            }}
            type="primary"
            containerProps={{
              className: styles.actionButton,
            }}
          />
        </div>
      </div>
    </PageContainer>
  );
}