import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthWithKratosSelfCare } from "src/Hooks/useAuthWithKratosSelfCare";
import { useAuthStore } from "src/Stores/AuthStore";
import { useAuthStoreLicensePlate } from "src/Stores/AuthStoreLicensePlate";

export default function RequireAuthSelfCare({ children }: { children: JSX.Element }) {

  const navigate = useNavigate();
  const { loading } = useAuthWithKratosSelfCare();
  const { isAuthenticatedSelfCareKratos } = useAuthStore();
  const { isAuthenticatedSelfCareLicencePlace } = useAuthStoreLicensePlate();

  useEffect(() => {

    if (loading) {
      return;
    }

    if (!isAuthenticatedSelfCareKratos && !isAuthenticatedSelfCareLicencePlace) {
      navigate("/self-care/login");
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (loading) {
    return "Loading...";
  }

  return children;
}