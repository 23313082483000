import HoverPopup from "@components/PopupInfos/HoverPopup";
import Tag from "@components/Tag/Tag";
import { TagKey } from "src/Stores/CurrentMissionTypes/MissionContextType";

export default function ItineraryTag({
  tagKey,
  label,
  hoverLabel,
  containerProps,
}: {
  tagKey: TagKey;
  label?: string;
  hoverLabel?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  let tag: { label: string; color?: string; textColor?: string; };

  switch (tagKey) {
    case 'hasRemainingCosts':
      tag = {
        label: 'Reste à charge',
        color: '#F7C7C7',
      }
      break;
    case 'isCheapest':
      tag = {
        label: 'Le moins cher',
        color: '#CFE9EE',
      }
      break;
    case 'isEarliestArrival':
      tag = {
        label: 'Arrivée le plus tôt',
        color: '#C2C2C2',
        // textColor: 'white',
      }
      break;
    case 'isLowCarbon':
      tag = {
        label: 'Bas carbone',
        color: '#E3EBAF',
      }
      break;
    case 'isShortestTravelTime':
      tag = {
        label: 'Le plus rapide',
        color: '#FFE8B0',
      }
      break;
    case 'isTheMorePractical':
      tag = {
        label: 'Le plus pratique',
        color: '#FFE8B0',
      }
      break;
    case 'unsuitable':
    case 'isUnsuitable':
      tag = {
        label: 'Inadapté',
        color: '#F7C7C7',
      }
      break;
    case 'isNotRecommended':
      tag = {
        label: 'Inadapté',
        color: '#F7C7C7',
      }
      break;
    default:
      if (!label) {
        return null;
      }
      tag = {
        label,
        color: '#F7C7C7',
      };
      break;
  }

  const tagElement = (
    <Tag
      label={label || tag.label}
      color={tag.color}
      textColor={tag.textColor}
      containerProps={containerProps}
    />
  );

  if (hoverLabel) {
    return (
      <HoverPopup
        hoverText={hoverLabel}
        containerProps={containerProps}
      >
        {tagElement}
      </HoverPopup>
    )
  }

  return tagElement;
}