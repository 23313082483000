import { InputHTMLAttributes, useState } from 'react';
import styles from './AutocompleteInput.module.scss';
import AutocompleteSuggestions from './AutocompleteSuggestions';
import AutocompleteModal from './AutocompleteModal/AutocompleteModal';
import useAutocomplete from './useAutocomplete';


function stopPropagation(e: React.MouseEvent | React.FocusEvent | React.KeyboardEvent) {
  e.stopPropagation();
  e.preventDefault();
  return false;
}

export default function AutocompleteInput<SuggestionType>({
  containerProps: { className, ...containerProps } = {},
  testid,
  inputProps,
  textValue,
  modalPlaceholder,
  customFirstSuggestion,
  getSuggestions,
  getTextFromSuggestion,
  onSuggestionSelected,
  mode = 'inline'
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  testid?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  textValue?: string;
  modalPlaceholder?: string;
  customFirstSuggestion?: SuggestionType;
  getSuggestions: (value: string) => Promise<SuggestionType[]>;
  getTextFromSuggestion: (suggestion: SuggestionType | null) => string;
  onSuggestionSelected?: (suggestion: SuggestionType | null) => void;
  mode?: 'inline' | 'modal';
}) {

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const autocomplete = useAutocomplete<SuggestionType>({
    textValue,
    customFirstSuggestion,
    getSuggestions,
    getTextFromSuggestion,
    onSuggestionSelected,
  });

  const {
    suggestionsContainerRef,
    value,
    suggestionsIsOpen,
    suggestions,
    selectedSuggestionIndex,
    isLoadingSuggestions,
    onKeyDown,
    onKeyUp,
    onFocus,
    onBlur,
    onChange,
    selectSuggestion,
    setSelectedSuggestionIndex,
  } = autocomplete;

  return (
    <>

      {modalIsOpen && (
        <AutocompleteModal<SuggestionType>
          onRequestClose={() => setModalIsOpen(false)}
          getTextFromSuggestion={getTextFromSuggestion}
          autocomplete={autocomplete}
          placeholder={modalPlaceholder}
        />
      )}

      <div
        data-testid={testid}
        className={[styles.container, className].join(" ")}
        {...containerProps}
        onClick={() => {
          if (mode === 'modal') {
            setModalIsOpen(true);
          }
        }}
      >
        <input className={styles.input} {...{
          ...inputProps,
          style: {
            ...inputProps?.style,
            ...(mode === 'modal' ? { cursor: "pointer", "pointerEvents": "none" } : {})
          },
          value,
          onChange,
          onKeyDown: mode === 'modal' ? stopPropagation : onKeyDown,
          onKeyUp: mode === 'modal' ? stopPropagation : onKeyUp,
          onFocus: mode === 'modal' ? stopPropagation : onFocus,
          onBlur,
        }}  />

        <AutocompleteSuggestions
          suggestionsContainerRef={suggestionsContainerRef}
          suggestionsIsOpen={suggestionsIsOpen}
          isLoadingSuggestions={isLoadingSuggestions}
          suggestions={suggestions}
          selectedSuggestionIndex={selectedSuggestionIndex}
          setSelectedSuggestionIndex={setSelectedSuggestionIndex}
          selectSuggestion={selectSuggestion}
          getTextFromSuggestion={getTextFromSuggestion}
        />

      </div>
    </>
  )
}