import { useCallback, useEffect } from "react";
import { authenticatedApi } from "src/Api/api";
import { useCurrentMission } from "src/Stores/CurrentMission";

export default function useMissionContextRefreshInterval({
  refreshInterval,
  enabled,
}: {
  refreshInterval: number;
  enabled?: boolean;
}) {
  const { mission, setMissionContext  } = useCurrentMission();

  const fetchMissionContext = useCallback(async () => {

    if (!mission?.caseID || !mission?.id) {
      return null;
    }

    const res = await authenticatedApi.get(`/cases/${mission?.caseID}/missions/${mission?.id}`);

    if (res.data?.context) {
      setMissionContext(res.data.context);
    }

    return null;
  }, [mission?.caseID, mission?.id, setMissionContext]);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    const interval = setInterval(() => {
      fetchMissionContext();
    }, refreshInterval);
    return () => clearInterval(interval);
  }, [refreshInterval, fetchMissionContext, enabled]);
}