import { useTranslation } from 'react-i18next';
import styles from './Confirmation.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import Button from '@components/Button/Button';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { CheckValid } from '@assets/icons/icons';
import { useNavigate } from 'react-router-dom';
import { displayPrice } from 'src/helpers/price';

export default function ConfirmationStepReceiptSelfCare() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { missionContext } = useCurrentMission();
  const refundFlow = missionContext?.refundFlow;

  return (
    <PageContainer
      containerProps={{
        className: [styles.container, 'selfcare'].join(" "),
      }}
    >

      <HeaderSelfCare
        title={t('confirmation_request')}
        displayBackButton={false}
      />

      <div className={styles.content}>
        <>
          <div className={styles.checkValidContainer}>
            <CheckValid width={36} height={36} />
            <div>{t('confirmation_message')}</div>
          </div>

          <div className={styles.text} style={{ marginTop: "24px" }}>
            {t('email_summary')} <b>{refundFlow?.email}</b>
          </div>

          <div className={styles.text} style={{ marginTop: "24px" }}>
            {t('processing_message')}
          </div>
        </>

        <div className={`flex flex-col justify-center w-full`} style={{ marginTop: "40px" }}>

          <div className={`flex flex-row justify-between align-center`}>
            <div className={styles.labelTitle}>{t('benefit_label')}</div>
            <div className={styles.valueTitle}>{refundFlow?.benefits?.find(b => b.value === refundFlow?.selectedBenefit)?.name || ""}</div>
          </div>

          <div className={`flex flex-row justify-between align-center`} style={{ marginTop: "16px" }}>
            <div className={styles.labelTitle}>{t('amount_label')}</div>
            <div className={styles.valueTitle}>{displayPrice(refundFlow?.receiptAmount)}</div>
          </div>

          <div className={`flex flex-row justify-between align-center ${styles.labelValueBold}`} style={{ marginTop: "16px" }}>
            <div className={styles.labelTitle}>{t('refunded_amount_label')}</div>
            <div className={styles.valueTitle}>{displayPrice(refundFlow?.coveredAmount)}</div>
          </div>

          <div className={`flex flex-row justify-between align-center`} style={{ marginTop: "8px" }}>
            <div className={styles.labelTitle}>{t('remaining_costs_label')}</div>
            <div className={styles.valueTitle}>{displayPrice(refundFlow?.remainingCosts)}</div>
          </div>

          <div className={`flex flex-row justify-between align-center ${styles.lineConditions}`} style={{ marginTop: "8px" }}>
            {t('conditions_note')}
          </div>
        </div>


        <div className={styles.actions}>
          <Button
            label={t('return_home')}
            onClick={() => {
              navigate("/self-care");
            }}
            type="primary"
            containerProps={{
              className: styles.actionButton,
            }}
          />
        </div>

      </div>

    </PageContainer>
  )
}