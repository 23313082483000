import Button from "@components/Button/Button";
import { useState } from "react";
import ModalResendConfirmationEmail from "./ResendConfirmationEmailModal";
import { useTranslation } from 'react-i18next';

export default function ResendConfirmationEmailButton({
  buttonType = 'primary',
  containerProps,
}: {
  buttonType?: 'primary' | 'secondary';
  containerProps?: Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>;
}) {

  const { t } = useTranslation();
  const [displayModal, setDisplayModal] = useState<{ open: false } | {
    open: true;
  }>({
    open: false
  });

  return (
    <>
      {displayModal && displayModal.open === true && (
        <ModalResendConfirmationEmail
          onRequestClose={() => setDisplayModal({
            open: false
          })}
        />
      )}
      <Button
        type={buttonType}
        label={t('resend_confirmation_email')}
        onClick={() => {
          setDisplayModal({
            open: true,
          });
        }}
        containerProps={containerProps}
      />

    </>
  )
}