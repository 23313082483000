import React from 'react';
import styles from './Steps.module.scss';
import { ReactComponent as Image } from './Images/mobility.svg';
import { useTranslation } from 'react-i18next';

export default function StepMobility({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {
  const { t } = useTranslation();

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>
      <div className={styles.text}>
        {t('mobility_text')}
      </div>
      <Image />
    </div>
  )
}