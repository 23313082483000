import PriceIncludedOrRemaining from '@components/PriceIncludedOrRemaining/PriceIncludedOrRemaining';
import styles from './SelectedTrain.module.scss';
import useExpandableTextHelpers from '@components/ExpandableText/useExpandableTextHelpers';
import SelectableContent from '@components/SelectableContent/SelectableContent';
import StringWithBr from '@components/StringWithBr/StringWithBr';
import { isSelfCare } from 'src/Stores/AppContext';
import { TrainProposalOffer } from 'src/Stores/CurrentMissionTypes/Types';
import { useTranslation } from 'react-i18next';

export default function Offer({
  selected,
  readOnly,
  onClickOffer,
  offer,
  envelope,
  containerProps: { className, ...containerProps } = {},
}: {
  selected: boolean;
  readOnly?: boolean;
  onClickOffer: () => void;
  offer: TrainProposalOffer;
  envelope?: {
    amount: number;
    currency: {
      symbol: string;
    };
  };

  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { t } = useTranslation();

  const {
    titleComponent,
    contentComponent

  } = useExpandableTextHelpers({
    title: t('view_exchange_refund_conditions'),
    content: <StringWithBr text={offer.conditions} />,
  });


  return (
    <div className={[styles.ticketInfoContainer, className].join(" ")} {...containerProps}>

      <SelectableContent
        selected={selected}
        readOnly={readOnly}
        onSelect={() => {
          onClickOffer();
        }}
        containerProps={{
          style: isSelfCare() ? {
            paddingBottom: "0px",
            paddingLeft: "0px",
            paddingRight: "0px",
            paddingTop: "0px",
            width: '100%'
          } : {
            paddingBottom: "0px",
            paddingLeft: "0px",
          }
        }}
        content={(

          <div className={styles.ticketInfo}>

            <div className={styles.ticketInfoTitlePriceContainer}>
              <div className={styles.ticketInfoTitleContainer}>
                <div className={styles.ticketInfoTitle}>{offer.label}</div>
              </div>

              {isSelfCare() ? (
                <PriceIncludedOrRemaining
                  remainingCosts={offer.remainingCosts}
                  containerProps={{
                    style: {
                      maxWidth: "80px",
                      marginLeft: "8px"
                    }
                  }}
                />
              ) : (
                <div className={styles.ticketInfoPrice}>{offer.price.formatted} {offer.price.currency.symbol}</div>
              )}
            </div>

            {!isSelfCare() && (
              <>

                {offer.remainingCosts && (
                  <div className={styles.ticketRemainingCosts}>
                    {offer.remainingCosts.formatted}{" "}
                    {offer.remainingCosts.currency.symbol} {t('remaining_costs_charged_to_customer')}
                  </div>
                )}
                {envelope && envelope.amount > 0 && (
                  <div className={styles.ticketEnvelope}>
                    {t('remaining_envelope_after_validation')} {" "}
                    {(envelope.amount - offer.price.amount )/ 100}{" "}
                    {envelope.currency.symbol}
                  </div>
                )}

              </>
            )}



            {titleComponent}

          </div>
        )}
      />

      {contentComponent}
    </div>
  )
}