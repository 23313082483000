import { Calendar } from '@assets/icons/icons';
import styles from './DateHourPicker.module.scss';
import React, { useRef } from 'react';
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import HourPicker from '@components/HourPicker/HourPicker';

export default function DateHourPicker({
  disabled,
  readOnly,
  label,
  placeholder,
  errorMsg,
  date,
  minDate,
  maxDate,
  onChangeDate,

  hour,
  minute,
  minHour = 0,
  minMinutes = 0,
  maxHour = 23,
  maxMinutes = 59,
  onChangeHour,
  onChangeMinutes,

  containerProps: { className, ...containerProps } = {},
  datePickerProps,
  testid,
}: {
  disabled?: boolean;
  readOnly?: boolean;
  label?: string;
  placeholder?: string;
  errorMsg?: string;
  date?: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  onChangeDate?: (date: Date | null) => any;

  hour: number | null;
  minute: number | null;
  minHour?: number;
  minMinutes?: number;
  maxHour?: number | null;
  maxMinutes?: number | null;
  onChangeHour?: (hour: number | null) => any;
  onChangeMinutes?: (minutes: number | null) => any;

  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  datePickerProps?: Omit<ReactDatePicker["props"], 'onChange'>;
  testid?: string;
}) {

  const datePickerRef = useRef<ReactDatePicker>(null);


  return (

    <div
      data-testid={testid}
      className={[styles.container, disabled ? styles.disabled : "", className].join(" ")}
      {...containerProps}
      onClick={(e) => {
        // target must not be the hourInput or any of parent
        if ((e.target as HTMLElement).closest(`.${styles.hourInput}`)) {
          return;
        }
        if (datePickerRef.current?.isCalendarOpen()) {
          return;
        }
        datePickerRef.current?.setOpen(true);
      }}
    >
      {(label || errorMsg) && (
        <div className={styles.labelContainer}>
          {label && <div className={styles.label}>{label}</div>}
          {errorMsg && <div data-testid='errorMsg' className={styles.errorMsg}>{errorMsg}</div>}
        </div>
      )}
      <div className={styles.inputContainer}>

        <div className={styles.input}>
          <Calendar width={18} />
          <ReactDatePicker
            ref={datePickerRef}
            {...datePickerProps}
            disabled={disabled || readOnly}
            selected={date}
            minDate={minDate}
            maxDate={maxDate}
            placeholderText={placeholder || "Selectionnez une date"}
            onChange={(date) => {
              onChangeDate && onChangeDate(date)
            }}
            customInput={(
              <div className={styles.dateInput}>
                {date?.toLocaleDateString() || placeholder || "Selectionnez une date"}
              </div>
            )}
          />

        </div>

        <div className={styles.input}>
          <HourPicker
            layout='minimal'
            hour={hour}
            minutes={minute}
            minHour={minHour}
            minMinutes={minMinutes}
            maxHour={maxHour}
            maxMinutes={maxMinutes}
            onChange={({hour, minutes}) => {
              onChangeHour && onChangeHour(hour);
              onChangeMinutes && onChangeMinutes(minutes);
            }}
            containerProps={{
              className: styles.hourInput,
            }}
          />
        </div>
      </div>
    </div>
  )
}