import { useMemo } from 'react';
import CustomMarker, { MarkerProps } from '../CustomMarker';
import styles from './ClusterPointMarker.module.scss';
import { MapMarkerCluster } from '@assets/icons/icons';

export default function ClusterPointMarker({
  point_count,
  containerProps: { className, ...containerProps } = {},
  ...markerProps
}: {
  point_count: number;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
} & MarkerProps) {

  const position = useMemo(() => {
    return {
      lat: markerProps.position.lat,
      lng: markerProps.position.lng,
    }
  }, [markerProps.position.lat, markerProps.position.lng]);

  if (!markerProps.map) {
    return null;
  }

  return (
    <CustomMarker
      map={markerProps.map}
      position={position}
      anchorPoint='center'
    >
      <div className={[styles.container, className].join(" ")} {...containerProps} onClick={() => {
        const map = markerProps.map;
        if (!map) {
          return;
        }

        map.setZoom((map.getZoom() || 0) + 2);
        map.setCenter(position)
      }}>
        <div className={[styles.label].join(" ")}>{point_count}</div>
        <MapMarkerCluster
          height={40}
          width={40}
        />
      </div>
    </CustomMarker>
  )
}