import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import styles from './SearchFilePage.module.scss';
import InputText from '@components/Inputs/InputText/InputText';
import Button from '@components/Button/Button';
import Header from 'src/layout/Header/Header';
import { useEffect, useState } from 'react';
import { authenticatedApi } from 'src/Api/api';
import { useNavigate } from 'react-router-dom';
import SearchFileResults from './SearchFileResults/SearchFileResults';
import { useDebounce } from 'usehooks-ts';
import { FileType } from 'src/models/File';
import PhoneNumberInput from '@components/PhoneNumberInput/PhoneNumberInput';
import { useTranslation } from 'react-i18next';

export default function SearchFilePageV2({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const [cases, setCases] = useState<FileType[]>([]);
  const [caseId, setCaseId] = useState<string>("");
  const [licensePlate, setLicensePlate] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("fr +33 ");

  const loadingDebounced = useDebounce(loading, 150);
  const caseIdDebounced = useDebounce(caseId, 500);
  const licensePlateDebounced = useDebounce(licensePlate, 500);
  const phoneNumberDebounced = useDebounce(phoneNumber, 500);

  useEffect(() => {

    setLoading(true);
    authenticatedApi.get("/cases", {
      params: {
        caseID: caseIdDebounced,
        licensePlate: licensePlateDebounced,
        phoneNumber: phoneNumberDebounced,
      },
    }).then((res) => {
      if (!res.data?.cases || !Array.isArray(res.data.cases)) {
        setCases([]);
        setLoading(false);
        return;
      }
      setCases(res.data.cases as FileType[]);
      setLoading(false);
    }).catch((err) => {
      console.log("CASES ERROR ", err);
      setLoading(false);
    })

  }, [caseIdDebounced, licensePlateDebounced, phoneNumberDebounced]);

  return (
    <PageContainer
      containerProps={{
        ...containerProps,
        className: [styles.container, className].join(" "),
      }}
    >

      <Header
        type='user'
      />

      <div className={styles.content}>

        <div className={styles.formTitleLine}>
          <div className={styles.formTitle}>{t('searchFilePage.title')}</div>
          <Button
            label={t('button.createNewCase')}
            type="primary"
            testid='button-create-case'
            onClick={() => {
              navigate("/create-case", {
                state: cases.length === 0 ? {
                  caseId,
                  licensePlate,
                  phoneNumber,
                } : {},
              });
            }}
            containerProps={{
              className: styles.buttonCreateCase,
            }}
          />
        </div>

        <div className={styles.form}>
          <InputText
            label={t('input.caseNumber.label')}
            containerProps={{
              className: styles.input,
            }}
            inputProps={{
              type: "text",
              placeholder: t('input.caseNumber.placeholder'),
              value: caseId,
              onChange: (e) => {
                setCaseId(e.target.value.slice(0, 11));
              },
            }}
          />

          <InputText
            label={t('license_plate')}
            containerProps={{
              className: `${styles.input} ${styles.inputPassword}`,
            }}
            inputProps={{
              type: "text",
              value: licensePlate,
              placeholder: t('input.licensePlate.placeholder'),
              onChange: (e) => setLicensePlate(e.target.value.toUpperCase()),
            }}
          />

          <PhoneNumberInput
            label={t('input.phoneNumber.label')}
            value={phoneNumber}
            onChange={(value) => {
              setPhoneNumber(value);
            }}
          />
        </div>

        <SearchFileResults
          containerProps={{
            className: styles.results,
          }}
          isLoading={loadingDebounced}
          results={cases}
          onClickResult={(result) => {
            navigate(`/cases/${result.id}`)
          }}
        />

      </div>

    </PageContainer>
  )
}