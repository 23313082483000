import styles from './ExpandableText.module.scss';

export default function ExpandableContent({
  expanded,
  expandedContentRef,
  separator = true,
  children,
  containerProps: { className } = {},
}: {
  expanded: boolean;
  expandedContentRef: React.RefObject<HTMLDivElement>;
  separator?: boolean;
  children: React.ReactNode;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  return (
    <div
      ref={expandedContentRef}
      className={[styles.expandedContainer, expanded ? styles.expandedContainerExpanded : "", className].join(" ")}
      style={{
        maxHeight: expanded ? expandedContentRef.current?.scrollHeight + "px" : "0px",
      }}
    >
      {separator && (<div className={styles.expandedSeparator} />)}
      <div className={styles.expandedContent}>
        {children}
      </div>
    </div>
  )
}