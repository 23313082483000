import styles from './WarrantiesList.module.scss';
import stylesForm from '../Form.module.scss';
import WarrantiesListTable from '@components/WarrantiesList/WarrantiesList';
import { useState } from 'react';
import ModalDetailsWarranties from './ModalDetailsWarranties';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { useTranslation } from 'react-i18next';

export default function WarrantiesList({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { mission } = useCurrentMission();
  const [modalDetailsIsOpen, setModalDetailsIsOpen] = useState(false);
  const { t } = useTranslation();

  const enveloppeInitialAmount = mission?.guaranteeDetails?.envelope?.initialAmount

  return (
    <div className={[styles.container, stylesForm.form, className].join(" ")} {...containerProps}>

      {modalDetailsIsOpen && mission?.guaranteeDetails && (
        <ModalDetailsWarranties
          onRequestClose={() => {
            setModalDetailsIsOpen(false);
          }}
          warrantiesListTableProps={{
            warranties: mission?.guaranteeDetails?.services || [],
            layout: "wide",
            displayHeader: true
          }}
          guaranteeDetails={mission?.guaranteeDetails}
        />
      )}

      <div className={stylesForm.formRow}>
        <div className={[stylesForm.formCol].join(" ")}>
          <span className={styles.title}>{t('available_services')}</span>
        </div>
        <div className={stylesForm.formCol}>
          <a onClick={(e) => {
            e.preventDefault();
            setModalDetailsIsOpen(true);
            return false;
          }} className={stylesForm.formLink}>{t('view_details')}</a>
        </div>
      </div>

      {mission?.guaranteeDetails?.isEnvelope && (
        <>
          {enveloppeInitialAmount?.amount && (
          <div className={stylesForm.formRow}>
            <div className={[stylesForm.formCol].join(" ")}>
              <span className={styles.envelopeTitle}>{t('initial_envelope')}</span>
            </div>
            <div className={stylesForm.formCol}>
              {enveloppeInitialAmount.formatted} {enveloppeInitialAmount.currency.symbol}
            </div>
          </div>
          )}
          <div className={stylesForm.formRow}>
            <div className={[stylesForm.formCol].join(" ")}>
              <span className={styles.envelopeTitle}>{t('remaining_envelope')}</span>
            </div>
            <div className={stylesForm.formCol}>
              {mission?.guaranteeDetails.envelope.availableAmount.formatted} {mission?.guaranteeDetails.envelope.availableAmount.currency.symbol}
            </div>
          </div>
        </>
      )}

      <div className={stylesForm.formRow}>

        <WarrantiesListTable
          layout={mission?.guaranteeDetails?.isEnvelope ? "envelope" : "default"}
          valueType="remaining"
          containerProps={{
            className: styles.warrantiesListContainer,
          }}
          warranties={mission?.guaranteeDetails?.services || []}
        />

      </div>
    </div>
  )
}