import { DynamicFilters, FilterItem, FiltersType } from "./types";


export default function itemsToFilterItems(items: Array<any>, filters: FiltersType): Array<FilterItem> {

  return items.map((solution): FilterItem => {

    const dynamicFilters: DynamicFilters = {};

    Object.keys(filters).forEach((filterKey) => {
      const filter = filters[filterKey];
      dynamicFilters[filterKey] = filter.getItemValues(solution);
    });

    return {
      filterId: solution.id,
      dynamicFilters
    }
  });
}