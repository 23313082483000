import { useEffect, useRef } from "react";

export function DashLine({
  map,
  origin,
  destination,
  curvature = 0.1
}: {
  map?: google.maps.Map | undefined;
  origin: google.maps.LatLngLiteral;
  destination: google.maps.LatLngLiteral;
  curvature?: number;
}) {
  const lineRef = useRef<google.maps.Polyline>();

  useEffect(() => {
    if (!map) return;

    const line = new google.maps.Polyline({
      path: [
        origin,
        destination,
      ],
      strokeOpacity: 0,
      icons: [{
        icon: {
          path: 'M 0,-1 0,1',
          strokeOpacity: 1,
          scale: 3,
          strokeColor: '#003781',
        },
        offset: '0',
        repeat: '15px',
      }],
    });


    line.setMap(map);
    lineRef.current = line;

    return () => {
      line.setMap(null);
    }
  }, [map, origin, destination, curvature]);

  return null;

}