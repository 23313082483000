import React from 'react';
import styles from './AlertBox.module.scss';
import { CheckValid, Warning } from '@assets/icons/icons';

export default function AlertBox({
  type,
  displayIcon,
  text,
  containerProps: { className, children, ...containerProps } = {},
}: {
  type: 'info' | 'warning' | 'error' | 'success';
  displayIcon?: boolean;
  text: string | string[];
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  return (
    <div className={[styles.container, styles[`type_${type}`], className].join(" ")} {...containerProps}>

      <div className={styles.contentContainer}>
        {displayIcon &&  (<>
          {type === 'warning' && <div className={styles.iconContainer}><Warning /></div>}
          {type === 'error' && <div className={styles.iconContainer}><Warning /></div>}
          {type === 'info' && <div className={styles.iconContainer}><Warning /></div>}
          {type === 'success' && <div className={styles.iconContainer}><CheckValid /></div>}
        </>)}
        <div className={styles.text}>
          {text instanceof Array ? text.map((t, i) => <div key={i}>{t}</div>) : text}
        </div>
      </div>

      {children && <div className={styles.childrenContainer}>
        {children}
      </div>}

    </div>
  )
}