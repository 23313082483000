import React from 'react';
import Select, { SelectProps } from '@components/Select/Select';

export default function HourPicker({
  disabled,
  hour,
  minutes,
  minHour = 0,
  minMinutes = 0,
  maxHour = 23,
  maxMinutes = 45,
  onChange,
  containerProps: { className, ...containerProps } = {},
  layout,
  testid,
  fixed = false,
}: {
  disabled?: boolean;
  hour: number | null;
  minutes: number | null;
  minHour?: number;
  minMinutes?: number;
  maxHour?: number | null;
  maxMinutes?: number | null;
  onChange?: (hourMinutes: { hour: number | null; minutes: number | null }) => any;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  layout?: SelectProps["layout"];
  testid?: string;
  fixed?: boolean;
}) {

  const minTimeInSecond = minHour !== null && minMinutes !== null ? minHour * 60 * 60 + minMinutes * 60 : null;
  const maxTimeInSecond = maxHour !== null && maxMinutes !== null ? maxHour * 60 * 60 + maxMinutes * 60 : null;

  const options = Array.from({ length: 24 }, (_, i) => i).map((hour) => {
    return [0, 15, 30, 45]
      .filter((minute) => {
        const timeInSecond = hour * 60 * 60 + minute * 60;
        if (maxTimeInSecond !== null && timeInSecond > maxTimeInSecond) {
          return false;
        }
        if (minTimeInSecond !== null && timeInSecond < minTimeInSecond) {
          return false;
        }
        return true;
      })
      .map((m) => `${hour < 10 ? '0'+hour : hour}:${m < 10 ? '0'+m : m}`);
  }).flat();

  return (
    <Select
      testid={testid}
      layout={layout}
      disabled={disabled}
      options={options}
      fixed={fixed}
      selectedIndex={(hour !== null && minutes !== null) ? options.findIndex((option) => option === `${hour < 10 ? '0'+hour : hour}:${minutes < 10 ? '0'+minutes : minutes}`) : null}
      onChangeIndex={(index) => {
        onChange && onChange({
          hour: index !== null ? parseInt(options[index].split(':')[0]) : null,
          minutes: index !== null ? parseInt(options[index].split(':')[1]) : null,
        });
      }}
      containerProps={{
        className,
        ...containerProps,
      }}
    />
  )
}