import { useCurrentMission } from "src/Stores/CurrentMission";

export default function useSendLinkButtons() {
  const {
    mission
  } = useCurrentMission();

  return {
    canSendSelfCareLink: !!mission?.actions?.sendSelfCareLink,
    canSendRefundLink: !!mission?.actions?.sendRefundFlowLink
  }

}