import {Arrival, Car, Taxi, Train, Walk} from '@assets/icons/icons';
import { transportationTypes } from "./types";


export function getTransportationIcon(transportation: transportationTypes) {
  switch (transportation) {
    case "cab":
    case "taxi":
      return <Taxi width={24} height={24} />;
    case "car":
    case "vrental":
      return <Car width={24} height={24} />;
    case "train":
      return <Train width={24} height={24} />;
    case "walk":
      return <Walk width={24} height={24} />;
    case "arrival":
      return <Arrival width={24} height={24} />;
    default:
      return null;
  }
}

export function getTimeFormatted(date: Date) {

  // hours with 2 digits
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
}

export function formatDistance(distanceInMeters?: number): string {
  if (!distanceInMeters) {
    return ""
  }
  if (distanceInMeters < 1000) {
    return numberFormat().format(distanceInMeters)+" m"
  }
  return (numberFormat({maximumFractionDigits: 2}).format(distanceInMeters/1000))+" km"
}

function numberFormat(options?: Intl.NumberFormatOptions) {
  return new Intl.NumberFormat(navigator.language, options);
}
