import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import styles from './Passengers.module.scss';
import layoutStyles from '../../CurrentMission.module.scss';
import Button from '@components/Button/Button';
import { Oval } from 'react-loader-spinner'
import CurrentMissionVerticalSteps from '../../CurrentMissionVerticalSteps';
import usePassengers from './Components/usePassenger';
import SendLinkButton from 'src/Pages/_Components/SendSefCareLinks/SendLinkButton';
import { isAppEnvProductionLike } from 'src/appEnv';
import useSendLinkButtons from 'src/Pages/_Components/SendSefCareLinks/useSendLinkButtons';
import { useTranslation } from 'react-i18next';
import HeaderV2 from 'src/layout/Header/V2/HeaderV2';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import PassengerForm from './Components/PassengerForm/PassengerForm';
import { useState } from 'react';
import { Add } from '@assets/icons/icons';

export default function PassengersPage({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const [hasSecondaryDriver, setHasSecondaryDriver] = useState(false);

  const {
    missionContext,
    control,
    handleSubmit,
    errors,
    loading,
    onSubmit,
    comments,
    setComments,
    travelerForm,
    watch,
  } = usePassengers({
    hasSecondaryDriver,
  });

  const mainIsNotDriver = watch('main_notADriver');
  const { isCurrentStepReadOnly } = useCurrentMission();
  const stepIsReadOnly = isCurrentStepReadOnly();
  const { canSendSelfCareLink } = useSendLinkButtons();
  const { t } = useTranslation();
  const canHaveSecondDriver = travelerForm?.passengerInformation.some(pi => pi.id === 'secondDriver')

  if (!missionContext?.steps?.travelerForm) {
    return null;
  }

  return (
    <PageContainer
      containerProps={{
        ...containerProps,
        className: [layoutStyles.container, styles.container, className].join(" "),
      }}
    >

      {loading && (
        <div className={styles.loadingFullScreen}>
          <Oval
            height={40}
            width={40}
            color="white"
            secondaryColor="white"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      <HeaderV2 />

      <form data-testid="step-travelerForm" className={styles.form} onSubmit={handleSubmit(onSubmit)}>

        <div className={[layoutStyles.content, styles.content].join(" ")}>

          <CurrentMissionVerticalSteps
            displayBackButton
          />

          <div className={styles.mainContentContainer}>

            {!travelerForm ? null : (
              <div className={styles.formContainer}>
                <div className={styles.sectionHeader}>
                  <div>
                    {(() => {
                      switch(true) {
                        case !!missionContext.steps.searchTrain:
                          return t('passengers');
                        case !!missionContext.steps.searchVrental:
                          return t('reservation_contact_vloc');
                        default:
                          return t('reservation_contact');
                      }
                    })()}
                  </div>

                 {!stepIsReadOnly && canSendSelfCareLink && (
                  <SendLinkButton
                    type='search'
                    label={t('send_selfcare_link')}
                    containerProps={{
                      className: styles.sendLinkButton,
                      disabled: isAppEnvProductionLike(),
                    }}
                  />
                 )}
                </div>

                <div className={styles.passengerFormContainer}>

                  {travelerForm.passengerInformation.map((passenger, indexPassenger) => {
                    if (passenger.id === "secondDriver" && !hasSecondaryDriver) {
                        return null;
                    }
                    if (passenger.id === "replacementDriver" && mainIsNotDriver === 'false') {
                      return null
                    }

                    return (
                      <>
                        <PassengerForm
                          loading={loading}
                          stepIsReadOnly={stepIsReadOnly}
                          watch={watch}
                          control={control}
                          errors={errors}
                          indexPassenger={indexPassenger}
                          passenger={passenger}
                        />

                        {passenger.id === "secondDriver" && hasSecondaryDriver && (
                          <div className={"mt-4 mb-4"}>
                            <Button
                              containerProps={{
                                disabled: loading || stepIsReadOnly,
                              }}
                              label={(
                                <div className={styles.buttonLabelContainer}>
                                  <div className={styles.buttonLabel}>{t('remove_second_driver')}</div>
                                </div>
                              )}
                              type="secondary"
                              onClick={() => {
                                setHasSecondaryDriver(false);
                              }}
                            />
                          </div>
                        )}

                        {canHaveSecondDriver && (
                          mainIsNotDriver === 'false' && passenger.id === "main" && !hasSecondaryDriver
                          || passenger.id === "replacementDriver" && mainIsNotDriver === 'true' && !hasSecondaryDriver
                        ) && (
                          <div className={"mt-4 mb-4"}>
                            <Button
                              containerProps={{
                                disabled: loading || stepIsReadOnly,
                              }}
                              label={(
                                <div className={styles.buttonLabelContainer}>
                                  <Add />
                                  <div className={styles.buttonLabel}>{t('add_second_driver')}</div>
                                </div>
                              )}
                              type="secondary"
                              onClick={() => {
                                setHasSecondaryDriver(true);
                              }}
                            />
                          </div>
                        )}
                      </>
                    )
                  })}
                </div>

                {travelerForm.commentForms && (
                  <div className={styles.sectionComments}>
                    {travelerForm.commentForms.map((comment) => (
                      <div className={styles.comment} key={comment.id}>
                        <p>{comment.label}</p>
                        <textarea
                          placeholder={''}
                          readOnly={stepIsReadOnly}
                          className={`${styles.commentInput} ${comment.readOnly ? styles.commentInputReadOnly : ""}`}
                          value={comments[`${comment.id}`] || ""}
                          onChange={(e) => {

                            if (comment.readOnly) {
                              return;
                            }
                            if (comment.maxLength && e.target.value.length > comment.maxLength) {
                              return;
                            }
                            setComments({
                              ...comments,
                              [`${comment.id}`]: e.target.value,
                            });
                          }}
                        />
                      </div>
                    ))}
                  </div>
                )}

                <div className={styles.footer}>
                <Button
                  label={t('validate')}
                  testid='button-validate-travelerform'

                  containerProps={{
                    className: styles.buttonValidate,
                    disabled: loading || stepIsReadOnly,
                    type: "submit",
                  }}
                />
              </div>

              </div>
            )}

          </div>

          <div className={styles.centerPlaceHolder} />
        </div>

      </form>

    </PageContainer>
  )
}
