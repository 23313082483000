import { CheckboxChecked } from '@assets/icons/icons';
import styles from './Checkbox.module.scss';
import React, { useState } from 'react';
import { Oval } from 'react-loader-spinner';

export default function Checkbox({
  checked = false,
  disabled,
  onChange,
  label,
  testid,
  containerProps: { className, ...containerProps } = {},
  labelClassName,
}: {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => any;
  label?: string | React.ReactNode;
  testid?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  labelClassName?: string;
}) {

  const [onChangeLoading, setOnChangeLoading] = useState(false);

  return (
    <div
      data-testid={testid}
      className={[styles.container, disabled ? styles.disabled : "", className].join(" ")}
      {...containerProps}
      onClick={() => {
        if (onChangeLoading) return;
        if (disabled || !onChange) return;
        // if onChange return a promise
        const onChangeResult = onChange(!checked);
        if (onChangeResult instanceof Promise) {
          setOnChangeLoading(true);
          onChangeResult.finally(() => {
            setOnChangeLoading(false);
          });
        }
      }}
    >
      {onChangeLoading && (
        <div
          className={[styles.loaderContainer].join(" ")}
        >
          <Oval
            color="#fff"
            height={20}
            width={20}
          />
        </div>
      )}
      {!onChangeLoading && (
        <div
          className={[styles.checkbox, checked ? styles.checked : ""].join(" ")}
        >
          {checked && (
            <CheckboxChecked data-testid="checkbox" />
          )}
        </div>
      )}
      {label && (
        <div className={`${styles.label} ${labelClassName}`}>
          {label}
        </div>
      )}
    </div>
  )
}