import { Configuration, FrontendApi, Session, SuccessfulNativeLogin, SuccessfulNativeRegistration } from "@ory/client";
import { useEffect, useState } from "react";
import { useAuthStore } from "src/Stores/AuthStore";
import { useAuthStoreLicensePlate } from "src/Stores/AuthStoreLicensePlate";

const KRATOS_LOGIN_URL = window.env?.PUBLIC_SELF_CARE_KRATOS_LOGIN_URL || "";
const ory = new FrontendApi(
  new Configuration({
    basePath: KRATOS_LOGIN_URL,
    baseOptions: {
      withCredentials: true,
    },
  }),
);


export function useAuthWithKratosSelfCare() {

  const {
    setAuthenticatedSelfCareKratos,
    setLogoutLink,
  } = useAuthStore();

  useAuthStoreLicensePlate();

  const [loading, setLoading] = useState(true);
  const [authData, setAuthData] = useState<{
    sessionData: any;
    logoutUrl: string | null;
  }>({
    sessionData: null,
    logoutUrl: null,
  });

  const createLogoutFlow = (sessionData: Session) => {
    return ory.createBrowserLogoutFlow({
      returnTo: window.location.origin + "/self-care/"
    }).then(({ data }) => {
      setAuthData({
        sessionData: sessionData,
        logoutUrl: data.logout_url,
      });
      setLogoutLink(data.logout_url);
    });
  }

  const onAuthData = (sessionData: Session) => {
    createLogoutFlow(sessionData).then(() => {
      setAuthenticatedSelfCareKratos(true);
      setLoading(false);
    })
  }

  const checkAuthKratos = async () => {

    ory
      .toSession()
      .then(({ data }) => {
        onAuthData(data);
      })
      .catch(() => {
        setAuthenticatedSelfCareKratos(false);
        setLoading(false);
      })
  };

  useEffect(() => {
    checkAuthKratos();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    orySdk: ory,
    loading,
    authData,
    onLogin: (data: SuccessfulNativeLogin) => {
      if (data.session) {
        onAuthData(data.session);
      }
    },
    onRegister: (data: SuccessfulNativeRegistration) => {
      if (data.session) {
        onAuthData(data.session);
      }
    },
  };
}