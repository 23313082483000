import { PlaceType } from "src/models/Place";

export function placeTypeToLatLngLiteral(place: PlaceType): google.maps.LatLngLiteral {
  return {
    lat: place.latitude,
    lng: place.longitude
  }
}

export function getBoundsFromPoints(points: google.maps.LatLngLiteral[]): google.maps.LatLngBounds {
  const bounds = new window.google.maps.LatLngBounds();
  points.forEach(point => {
    bounds.extend(point);
  });

  return bounds;
}