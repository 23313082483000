import { useEffect } from "react";
import { ItineraryComponentProps } from "./types";
import useConnectingTaxis from "./useConnectingTaxis";

export default function useItinerary(itineraryComponentProps: ItineraryComponentProps) {

  const {
    selected = false,
    mainTransportType,

    departureDateTimeIso,
    arrivalDateTimeIso,
    dataByEnabledSections,
    price,

    title = "",
    title2,
    transportations = [],
    tags = {},
    co2Emission,

    sections = [],

    onSelect = () => void 0,
    onSelectParams,
    containerProps,
    remainingCosts,

    readOnly = false,
  } = itineraryComponentProps;

  const {
    connectingTaxis,
    isTaxiSelected,
    toggleSelectTaxiIndex,
    selectedAllSectionsIndex,
  } = useConnectingTaxis({
    sections,
    mainTransportType,
    defaultSelected: true,
  });
  const departureDateTime = new Date(departureDateTimeIso);
  const arrivalDateTime = new Date(arrivalDateTimeIso);

  const onClickTaxiIndex = (indexInSections: number, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    toggleSelectTaxiIndex(indexInSections);
  }

  const kgCO2e = sections.filter((section, i) => {
    if (section.type !== "taxi") {
      return true;
    }
    if (isTaxiSelected(i)) {
      return true;
    }
    return false;
  }).map((section) => section.kgCO2e || 0).reduce((a, b) => a + b, 0);


  const priceAmount: number = price.amount + connectingTaxis.map((taxi) => isTaxiSelected(taxi.indexInSections) ? taxi.price.amount : 0).reduce((a, b) => a + b, 0);
  let formattedPrice = (priceAmount/ 100).toFixed(2) + " " + price.currency.symbol;

  if (dataByEnabledSections) {
    const keyData = selectedAllSectionsIndex.join(",");
    const data = dataByEnabledSections[keyData];
    if (data) {
      formattedPrice = data.price.formatted + " " + data.price.currency.symbol;
    }
  }

  useEffect(() => {
    if (!selected) {
      return;
    }
    onSelect({
      selected,
      selectedAllSectionsIndex,
      onSelectParams,
    });
  }, [onSelect, onSelectParams, selected, selectedAllSectionsIndex]);

  return {
    connectingTaxis,
    selectedAllSectionsIndex,
    isTaxiSelected,
    onClickTaxiIndex,
    formattedPrice,
    kgCO2e,
    departureDateTime,
    arrivalDateTime,
    priceAmount,

    title,
    title2,
    transportations,
    tags,
    co2Emission,
    sections,
    onSelect,
    onSelectParams,
    containerProps,
    remainingCosts,
    readOnly
  }
}